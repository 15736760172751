// @flow

import React from 'react';
import RudImagePlaceholder from './ImagePlaceholders/ImagePlaceholder-RUD.jpg';
import AlfaboxImagePlaceholder from './ImagePlaceholders/ImagePlaceholder-Alfabox.jpg';
import JadImagePlaceholder from './ImagePlaceholders/ImagePlaceholder-JAD.jpg';
import AximaImagePlaceholder from './ImagePlaceholders/ImagePlaceholder-Axima.jpg';
import { useGlobalContainer } from '../app/use';

export const getImagePlaceholder = () => {
  const globalContainer = useGlobalContainer();

  if (globalContainer.isSpecificProject('jadtools')) {
    return JadImagePlaceholder;
  }
  if (globalContainer.isSpecificProject('alfabox')) {
    return AlfaboxImagePlaceholder;
  }
  if (globalContainer.isSpecificProject('axima')) {
    return AximaImagePlaceholder;
  }

  return RudImagePlaceholder;
};

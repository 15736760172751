// @flow

import { css } from '@emotion/core';

//
// Just "presets" to remove browser defaults
//

export const OrderedList = css`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const UnorderedList = css`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const ListItem = css`
  margin: 0;
`;

// Note(font-size): by default its smaller (e.g. Chrome)
export const InvisibleButton = css`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  text-align: start;
  font-size: 1rem;
`;

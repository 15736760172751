// @flow

import * as React from 'react';
import { useState } from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

export function SelectField(props: {|
  label: React.Node,
  name: string,
  id: string,
  onChange: (val: string) => void,
  options: Array<{| value: string, label: string |}>,
  //
  error: boolean,
  helperText: string,
  defaultValue?: string,
  //
  required: boolean,
  disabled: boolean,
|}) {
  const { label, required, error, helperText, disabled, name, options } = props;

  const [value, setValue] = useState(props.defaultValue);

  React.useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  const onChange = (e) => {
    const value = e.target.value;
    setValue(value);
    props.onChange(value);
  };

  return (
    <FormControl required={required} error={error} disabled={disabled} margin="normal" fullWidth>
      <InputLabel htmlFor={props.id} shrink>
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        disabled={disabled}
        required={required}
        inputProps={{ name, disabled, required }}
      >
        {options.map((opt) => (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

// @flow

// import type { PageConfig } from '@whys/app';

import { createSeoPage } from '../app/pages';
import type { PageWithMeta } from '../app/pages';
import type { SystemPageConfig } from '../app/pages';
import { createPreferenceContainer } from '../app/state';

export function createProductsPage<PageProps: { ...PageWithMeta }>(
  config: SystemPageConfig<PageProps>
) {
  const getInitialProps = async (ctx) => {
    const initialProps = config.getInitialProps
      ? await config.getInitialProps(ctx)
      : // $FlowFixMe: not in PageProps
        { __render: true };
    const { appContainer } = ctx;
    const preferenceContainer = await appContainer.resolve(createPreferenceContainer);

    await preferenceContainer.getListLayoutVariant();

    return initialProps;
  };

  const resolvedConfig = {
    ...config,
    getInitialProps,
  };
  return createSeoPage(resolvedConfig);
}

export type { PageWithMeta };

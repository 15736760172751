// @flow

import { Container } from '@whys/app/lib/state';
import { getJSON } from '@whys/fetch/lib/json';
// import { create as createResourceByIdCache } from '@whys/fetch/lib/resources/ResourceByIdCache';
// import type { ResourceByIdCache } from '@whys/fetch/lib/resources/ResourceByIdCache';

import type { FetchEnvType, AppCacheType, CacheType } from '../types';
import type { PageInfo, PageInfoPayload } from '../models/page';
import { mapPageInfo } from '../models/page';

// type AsyncBinaryResult = Promise<boolean>;

const resources = {
  pageInfo: (url: string) => ({
    url: `/api/url${url}`,
  }),
};

type LocalProps = {|
  fetchEnv: FetchEnvType,
  appCache: AppCacheType,
|};

type LocalState = {||};

export class PageContainer extends Container<LocalState> {
  state: LocalState;
  props: LocalProps;

  pageInfos: CacheType<PageInfo | null>;

  constructor(props: LocalProps) {
    super();

    const { appCache } = props;
    this.pageInfos = appCache.getOrCreateCache('ProductContainer.pageInfos');

    this.props = props;
  }

  async getPageInfoFromUrl<TEmpty>(
    urlInput: string,
    defaultValue: TEmpty
  ): Promise<{|
    resource: PageInfo | TEmpty,
    redirectInfo?: {| redirectUrl: string, language: string |},
  |}> {
    const cached = this.pageInfos.getValue(urlInput, null);
    if (cached) {
      return { resource: cached };
    }

    const { fetchEnv } = this.props;
    type ErrorPayload = {|
      redirect_url?: string,
      language_code?: string,
    |};

    const result = await getJSON<PageInfoPayload, ErrorPayload>(
      resources.pageInfo(urlInput).url,
      fetchEnv
    );
    if (result.status === 'ok') {
      const resource = mapPageInfo(result.data);
      this.pageInfos.setValue(urlInput, resource);
      return { resource };
    }
    if (result.status === 'error') {
      const { response } = result;
      if (response.status === 404 || response.status === 403) {
        const { data } = result;
        if (data && data.redirect_url && data.language_code) {
          const redirectInfo = {
            redirectUrl: data.redirect_url,
            language: data.language_code,
          };
          return { resource: defaultValue, redirectInfo };
        }
      }
    }
    return { resource: defaultValue };
  }
}

// @flow

import React, { useMemo } from 'react';
import { useForm, FormContext, useFormContext } from 'react-hook-form';

import type { InputPreset } from '../forms/preset';

// import { scrollMiddleToElement } from '../jsutils/browser';

type ManualFieldError = {|
  name: string,
  type: string,
  message?: string,
|};

export type ApiFieldConfig = {|
  required: boolean,
|};

export type FieldConfig = {
  ...$Shape<InputPreset>,

  // NotePrototype(rhf): make required
  // name?: string,
};

export type RHForm = {|
  formState: {|
    dirty: boolean,
    isSubmitted: boolean, // this is probably useless, remove from types?
    // NotePrototype(touched type): probably deep
    touched: { [name: string]: boolean },
    isSubmitting: boolean,
    submitCount: number,
    isValid: boolean,
  |},
  handleSubmit: <T>(fn: T) => T,
  register: any,
  control: any,
  errors: {
    [inputName: string]: {|
      type: string, // eg: required, min, max, minLength
      types: { [string]: string | boolean }, // make sure you have set validateCriteriaMode: 'all'.
      message: string, //  Message is an empty string by default.
      ref: any,
    |},
  },
  setError: (name: string | ManualFieldError[], type: string | Object, message?: string) => void,
  clearError: any,
  watch: any,
  getValues: any,
  setValue: any,
  triggerValidation: (string) => void,
  reset: any,

  // our extra API in seperate key to avoid confusion
  extra: {| submittedOk: boolean |},
|};

export type AppSubmitResult =
  | {|
      status: 'ok',
    |}
  | {| status: 'error', submitError: string |}
  | {| status: 'failed' |}
  | {| status: 'idle' |};

type RHFormOptions = {|
  mode?: 'onChange' | 'onBlur' | 'onSubmit', // onSubmit
  reValidateMode?: 'onChange' | 'onBlur' | 'onSubmit', // onChange
  defaultValues?: Object,
  validationSchema?: Object, // {}
  validationSchemaOption?: Object, // { abortEarly: false }
  validateCriteriaMode?: 'firstErrorDetected' | 'all', // firstErrorDetected
  submitFocusError?: boolean, // true
  nativeValidation?: boolean, // false
|};

const ourDefaults: RHFormOptions = {
  mode: 'onBlur',
};

export function useAppForm(config?: $Shape<RHFormOptions>): RHForm {
  const [submittedOk, setSubmittedOk] = React.useState(false);
  const finalConfig = useMemo(() => {
    return { ...ourDefaults, ...config };
  }, [config]);
  const methods = useForm(finalConfig);

  // Note: this is overriding the handleSubmit so that is returns result of its callback
  // (onSubmit). Maybe AppForm can accept onSubmit directly without applying handleSubmit?
  const handleSubmit = (submitFn: (values: any) => Promise<AppSubmitResult>) => {
    return async (e) => {
      setSubmittedOk(false);
      const result = await new Promise((resolve) => {
        methods.handleSubmit(async (values) => {
          resolve(await submitFn(values));
        })(e);
      });
      if (result && result.status === 'ok') {
        setSubmittedOk(true);
      }
      return result;
    };
  };

  const extra = {
    submittedOk,
  };

  return { ...methods, handleSubmit, extra };
}

export { FormContext, useFormContext };

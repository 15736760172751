// @flow

import * as React from 'react';
import type { ProductItem, QuickSearchItem } from '../appState/types';
import * as baseUI from '../baseUI';
import { Block, FlexColumn, FlexRow } from '../baseUI';
import { ProductTitleLink } from './product';
import { HorizontalSpace, VerticalSpace, FormatPrice } from '../appUI';
import { AddToCartLine } from '../cart/addToCart';
import LoadingInline from '../appUI/LoadingInline';
import { useTheme } from '../app/theme';
// import paths from '../app/paths';
import { TextLink, BlockLink } from '../app/link';
import { Trans } from '@lingui/react';
import * as productModel from '../models/product';
import { useSearchUrl } from '../RudorferApp/useSearch';
import { getImagePlaceholder } from './getImagePlaceholder';

type LocalProps = {|
  results: Array<QuickSearchItem>,
  productsMap: { [string]: ProductItem },
  isSmall: boolean,
  isLogged: boolean,
  searchTerm: string,
  isLoading: boolean,
  hasLoaded: boolean,
  height: number,
  onToggleModal: (boolean) => void,
|};

function FirstRow(props) {
  return <Block {...props} />;
}

function SecondRow(props) {
  return <Block {...props} />;
}

function SearchPopup(props: LocalProps) {
  const { results, searchTerm, hasLoaded, isLoading } = props;

  const imgPlaceholder = getImagePlaceholder();

  const theme = useTheme();
  // NoteReview: handle empty?
  const searchUrl = useSearchUrl(searchTerm);

  const renderResults = () => {
    const { results, isLogged, productsMap } = props;

    return (
      <Block>
        {results.map((searchResult) => {
          const imageSrc = (() => {
            const firstImg = searchResult.images[0];
            if (firstImg) {
              return firstImg.thumbnail || imgPlaceholder;
            }
            return imgPlaceholder;
          })();

          const product = productsMap[searchResult.id];

          return (
            <FlexRow
              key={searchResult.id}
              alignItems="center"
              justifyContent="space-between"
              marginBottom={15}
            >
              <FlexRow width="100%" alignItems="center">
                <FlexRow justifyContent="center" alignItems="center" maxWidth={100} height={100}>
                  {product ? (
                    <BlockLink to={product.productUrl}>
                      <img src={imageSrc} alt={searchResult.code} style={{ maxWidth: '100%' }} />
                    </BlockLink>
                  ) : (
                    <img src={imageSrc} alt="" style={{ maxWidth: '100%' }} />
                  )}
                </FlexRow>
                <HorizontalSpace size="normal" />
                <FlexColumn width="100%">
                  <ProductTitleLink
                    title={searchResult.code}
                    productUrl={product ? product.productUrl : ''}
                  />
                  {!isLogged && (
                    <Block marginTop={5}>
                      <Trans>Price is available after login.</Trans>
                    </Block>
                  )}
                  {isLogged && product && (
                    <React.Fragment>
                      {!productModel.isAvailable(product) && <VerticalSpace />}
                      <FlexRow alignItems="center" justifyContent="space-between" marginTop={-10}>
                        <FormatPrice value={product.price} />
                        {(() => {
                          if (!isLogged || !product) {
                            return null;
                          }
                          if (!productModel.isAvailable(product)) {
                            return (
                              <FlexRow
                                color={theme.color('errorText')}
                                width="100%"
                                alignItems="center"
                                justifyContent="flex-end"
                                paddingRight={14}
                              >
                                <Trans>Not in stock</Trans>
                              </FlexRow>
                            );
                          }
                          return (
                            <FlexRow alignItems="center">
                              <AddToCartLine
                                product={product}
                                showPickerIcon={false}
                                onToggleModal={(val) =>
                                  setTimeout(() => props.onToggleModal(val), 200)
                                }
                              />
                            </FlexRow>
                          );
                        })()}
                      </FlexRow>
                    </React.Fragment>
                  )}
                  {isLogged && !product && <LoadingInline />}
                </FlexColumn>
              </FlexRow>
            </FlexRow>
          );
        })}
      </Block>
    );
  };

  const hasItems = results.length;

  const specialCase = (() => {
    if (hasItems) {
      return null;
    }
    if (hasLoaded) {
      return (
        <Trans>
          <span>
            No search results for term <strong>{searchTerm}</strong>.
          </span>
        </Trans>
      );
    }
    if (!searchTerm) {
      return <Trans>Start typing...</Trans>;
    }
    if (isLoading) {
      return <LoadingInline />;
    }
    return null;
  })();

  return (
    <FlexColumn padding={20} paddingRight={8} maxWidth={600} height={props.height}>
      <FirstRow>
        {searchTerm ? (
          <React.Fragment>
            <Block>
              {props.isSmall ? (
                <TextLink golden to={searchUrl}>
                  <Trans>
                    Show results for <strong>{searchTerm}</strong>
                  </Trans>
                </TextLink>
              ) : (
                <TextLink golden to={searchUrl}>
                  <Trans>
                    Show results for <strong>{searchTerm}</strong>
                  </Trans>
                  <HorizontalSpace size="small" />
                  <Trans>
                    (press <strong>ENTER</strong>)
                  </Trans>
                </TextLink>
              )}
            </Block>
            <VerticalSpace />
          </React.Fragment>
        ) : null}
      </FirstRow>
      <SecondRow height="100%" overflowY="auto" overflowX="hidden">
        {hasLoaded && hasItems ? renderResults() : null}
        {specialCase ? (
          <FlexRow
            color={theme.color('primary')}
            fontSize="1rem"
            height="100%"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            {specialCase}
          </FlexRow>
        ) : null}
      </SecondRow>
    </FlexColumn>
  );
}

export default SearchPopup;

// @flow

import { DateFormat, NumberFormat } from '@lingui/react';
import { withStyles } from '@material-ui/core';
import * as React from 'react';
import { Link } from 'react-router-dom';
import type { appColorEnum, fontSizesEnum } from '../appState/types';

import { Block, Box, InlineBlock } from '../baseUI';

import { useTheme } from '../app/theme';
import BadgeMui from '@material-ui/core/Badge/Badge';
import { useGlobalContainer } from '../app/state';
import { ExternalLink } from '../app/link';

// '' => normal
// 'sm' => smaller
// 'lg' => larger
// type SizeValues = '' | 'sm' | 'lg';
type SizeValues = 'normal' | 'large' | 'small';

export function VerticalSpace(props: {| size?: SizeValues |}) {
  return (
    <Block
      height={(() => {
        const { size } = props;
        if (size === 'large') {
          return '3em';
        }
        if (size === 'small') {
          return '0.5em';
        }
        return '1em';
      })()}
    />
  );
}

export function HorizontalSpace(props: {| size?: SizeValues |}) {
  return (
    <InlineBlock
      width={(() => {
        const { size } = props;
        if (size === 'large') {
          return '3em';
        }
        if (size === 'small') {
          return '0.5em';
        }
        return '1em';
      })()}
    />
  );
}

export const BlockLink = withStyles({
  root: {
    display: 'block',
    textAlign: 'center',
    textDecoration: 'none',
    maxHeight: '100%',
    '&&:hover': {
      opacity: 0.75,
    },
  },
})((props: { classes: *, to: string }) => {
  const { classes, ...pass } = props;
  return <Link {...pass} className={classes.root} />;
});

export function FormatPriceBold(props: { value: number, currency?: string }) {
  return (
    <InlineBlock fontWeight="bold">
      <FormatPrice {...props} />
    </InlineBlock>
  );
}

export function FormatPrice(props: {| value: number, currency?: string |}) {
  const globalContainer = useGlobalContainer();
  const {
    minimumFractionDigits,
    maximumFractionDigits,
    currency: fallbackCurrency,
  } = globalContainer.selectPriceInfo();
  const currency = props.currency || fallbackCurrency;

  return (
    <NumberFormat
      value={props.value}
      format={{
        style: 'currency',
        currency,
        minimumFractionDigits,
        maximumFractionDigits,
      }}
    />
  );
}

export function FormatDecimal(props: {| value: number |}) {
  return (
    <NumberFormat
      value={props.value}
      format={{
        style: 'decimal',
      }}
    />
  );
}

export function FormatDateOrNull(props: { value: ?Date }) {
  // NotePrototype(simon): handle invalid dates?

  const { value } = props;
  // null isNaN returns false
  if (!value || Number.isNaN(value)) {
    return '-';
  }
  return <DateFormat value={value} />;
}

export function FormatDateLong(props: { value: Date }) {
  return (
    <DateFormat
      value={props.value}
      format={{
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }}
    />
  );
}

export function ThemedText(props: {
  color?: appColorEnum,
  size?: fontSizesEnum,
  component?: string,
  display?: string,
  children: React.Node,
}) {
  const { color, size, ...styleProps } = props;
  const theme = useTheme();

  return (
    <Box
      component={props.component || 'div'}
      display={props.display || 'block'}
      color={color && theme.colors[color]}
      fontSize={size && theme.fontSizes[size]}
      {...styleProps}
    >
      {props.children}
    </Box>
  );
}

export function HLine(props: Object & { baseColor?: string }) {
  const baseColor = props.baseColor || 'rgba(227, 227, 227, 1)';
  return (
    <Block
      width="100%"
      border={0}
      height={1}
      backgroundImage={`linear-gradient(to right, rgba(227, 227, 227, 0.2), ${baseColor}, rgba(227, 227, 227, 0.2))`}
      component="hr"
      {...props}
    />
  );
}

export const Badge = withStyles(({ palette }) => ({
  badge: {
    border: `1px solid ${palette.primary.main}`,
    color: palette.primary.main,
  },
}))((props: { classes: *, badgeContent: string, children: * }) => <BadgeMui {...props} />);

export function FormatPhone(props: {| value: string, style?: Object |}) {
  const { style = {} } = props;
  return (
    <ExternalLink style={style} href={`tel:${props.value}`}>
      {props.value}
    </ExternalLink>
  );
}

export function FormatEmail(props: {| value: string, style?: Object |}) {
  const { style = {} } = props;
  return (
    <ExternalLink style={style} href={`mailto:${props.value}`}>
      {props.value}
    </ExternalLink>
  );
}

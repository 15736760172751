// @flow

import { useState, useEffect } from 'react';
// import queryString from 'query-string';

import { useRouter } from '../app/router';
import {useSearchContainer, useWebAnalyticsContainer} from '../app/state';
import { useGlobalContainer } from '../app/state';

import type { QuickSearchItem, ProductItem } from '../appState/types';

function encodeSearchUrl(url: string) {
  return encodeURIComponent(url.replace(/ /g, '+'));
}

function decodeSearchUrl(url: string): string {
  const decoded = decodeURIComponent(url);
  // see above (+ => ' ')
  return decoded.replace(/\+/g, ' ');
}

export function readQueryFromUrl(urlSearch: string): string {
  // This is a plain function to get query from urlSearch.

  // Its co-located in this react hooks file because the helpers are here.
  const processedUrl = decodeSearchUrl(urlSearch);
  const queryPart = processedUrl.split('?q=')[1];
  if (!queryPart) {
    return '';
  }
  return queryPart;
}

function useGetSearchUrl() {
  const globalContainer = useGlobalContainer();
  return (query: string) => {
    const pathname = globalContainer.selectSystemPageUrl('search');
    return query ? `${pathname}?q=${encodeSearchUrl(query)}` : pathname;
  };
}

export function useSearchUrl(query: string): string {
  const globalContainer = useGlobalContainer();
  const pathname = globalContainer.selectSystemPageUrl('search');
  return `${pathname}?q=${encodeSearchUrl(query)}`;
}

function useSearchPageOpen(query: string): boolean {
  const globalContainer = useGlobalContainer();
  const { location } = useRouter();
  const expectedUrl = useSearchUrl(query);
  return location.url === expectedUrl;
}

type SearchStateEnum = 'idle' | 'pending' | 'firstResult' | 'done';

export function useSearch(
  searchTerm: string
): {|
  pageOpen: boolean,
  results: Array<QuickSearchItem>,
  state: SearchStateEnum,
  productDetails: { [string]: ProductItem },
  getSearchUrl: (q: string) => string,
|} {
  const searchContainer = useSearchContainer();
  const [searchState, setSearchState] = useState<SearchStateEnum>('idle');
  const [results, setSearchResults] = useState([]);
  const pageOpen = useSearchPageOpen(searchTerm);
  const getSearchUrl = useGetSearchUrl();
  const webAnalytics = useWebAnalyticsContainer();

  async function performSearch(searchTerm) {
    setSearchState('pending');
    // const mapped = mapPayload(await searchContainer.quickSearch(searchTerm));
    const results = await searchContainer.quickSearch(searchTerm);
    setSearchResults(results);
    setSearchState('firstResult');
    await searchContainer.loadProductDetails(results.map((_) => _.id));
    webAnalytics.searchEvent(searchTerm);
    setSearchState('done');
  }

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setSearchState('idle');
      setSearchResults([]);
    } else {
      performSearch(searchTerm);
    }
  }, [searchTerm]);

  const productDetails = searchContainer.selectProductDetails();

  return {
    pageOpen,
    results,
    state: searchState,
    productDetails,
    getSearchUrl,
  };
}

export const searchStateIsIn = (valA: SearchStateEnum, ...vals: SearchStateEnum[]) =>
  vals.some((valX) => valX === valA);

// @flow

import React from 'react';

import GridIcon from '@material-ui/icons/ViewModule';
import ListIcon from '@material-ui/icons/ViewList';
import IconButton from '@material-ui/core/IconButton/IconButton';

import { Block } from '../baseUI';

import { useLingui } from '../app/lingui';

import type { LayoutVariantEnum } from '../appState/types';
import { useGlobalContainer } from '../app/use';

type LocalProps = {|
  layoutVariant: LayoutVariantEnum,
  changeLayoutVariant: (LayoutVariantEnum) => void,
|};

const isVariant = (type: LayoutVariantEnum, value: LayoutVariantEnum) => type === value;

export function ChangeListLayout(props: LocalProps) {
  const i18n = useLingui();
  const globalContainer = useGlobalContainer();

  const decideColour = (layout) => {
    if (globalContainer.isSpecificProject('alfabox')) {
      if (isVariant(layout, props.layoutVariant)) return 'secondary';
      else return 'primary';
    } else {
      if (isVariant(layout, props.layoutVariant)) return 'primary';
      else return 'secondary';
    }
  };

  return (
    <Block>
      <IconButton
        color={decideColour('grid')}
        title={i18n.t`Grid`}
        onClick={() => props.changeLayoutVariant('grid')}
      >
        <GridIcon style={{ fontSize: globalContainer.isSpecificProject('alfabox') && '2.5rem' }} />
      </IconButton>
      <IconButton
        color={decideColour('row')}
        title={i18n.t`List`}
        onClick={() => props.changeLayoutVariant('row')}
      >
        <ListIcon style={{ fontSize: globalContainer.isSpecificProject('alfabox') && '2.5rem' }} />
      </IconButton>
    </Block>
  );
}

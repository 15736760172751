// @flow

import * as React from 'react';

import type { ProductItem, TopMenuItem, MenuItem, TertiaryMenuItem } from '../appState/types';

export const params2 = [
  { id: 1, title: 'Rozměr (mm):', content: '40 x 40 x 32' },
  { id: 3, title: 'Použití:', content: 'prsten' },
  { id: 4, title: 'Možnost potisku:', content: 'vně' },
  { id: 0, title: 'Balení:', content: '48 ks' },
];

export const params = {
  packageSize: 48,
  minAmountToOrder: 12,
  saleType: 'package',
  dimensions: '40 x 40 x 32',
  usage: 'prsten',
  printOption: 'vne',
  collectionTitle: '',
  collectionDescHtml:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus libero sit amet lobortis tempor. Vivamus semper lectus eget augue semper, sit amet tristique lorem faucibus. Sed posuere ornare auctor. Sed sit amet turpis et erat laoreet porta vitae venenatis velit. Integer sollicitudin ligula libero, eu tempor nulla efficitur id. Fusce non maximus lorem. Sed sed pretium lacus.',
  insideColor: 'bíla',
  insideMaterial: 'molitan',
  insideCutImg: 'https://www.rudorfer.cz/boxes/cz/img/prurezy/R03.gif',
  measureUnit: {
    erp_id: 'KS',
    name: '',
    multiplySize: 1,
  },
};

// export const sizes = [
//   { value: '40', label: '40 x 40 x 33 mm' },
//   { value: '50', label: '50 x 50 x 43 mm' },
//   { value: '60', label: '40 x 60 x 53 mm' },
// ];

export const sizes = [
  {
    label: '45x45x32       16400.01',
    variantId: 5702,
    id: '42',
  },
  {
    label: '50x50x22       16410.01',
    variantId: 5704,
    id: '42',
  },
  {
    label: '210x45x25      16430.01',
    variantId: 5706,
    id: '42',
  },
  {
    label: '55x85x25       16450.01',
    variantId: 5708,
    id: '42',
  },
  {
    label: '85x85x33       16460.01',
    variantId: 5710,
    id: '42',
  },
  {
    label: '145x145x33     16470.01',
    variantId: 5712,
    id: '42',
  },
];

export const variants = [
  {
    label: '11001.01BRG',
    variant: {
      id: '1',
      code: '11001.01BRG',
      url: '/product',
      images: [
        'http://www.media.rudorfer.cz/media/catalog/product/cache/2/thumbnail/75x/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-12.jpg',
      ],
    },
  },
  {
    label: '11001.01BRS',
    variant: {
      id: '2',
      code: '11001.01BRS',
      url: '/product',
      images: [
        'http://www.media.rudorfer.cz/media/catalog/product/cache/2/thumbnail/75x/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-99.jpg',
      ],
    },
  },
];

export const detailParams = [
  { id: 22, title: 'Kolekce:', content: 'Baptism' },
  { id: 2, title: 'Barva:', content: 'bílá' },
  { id: 12, title: 'Barva vnitřku:', content: 'bílá' },
  { id: 4, title: 'Materiál:', content: 'papír' },
  { id: 5, title: 'Materiál vnitřku:', content: 'hrnuli pěna' },
  { id: 6, title: 'Možnost potisku:', content: 'potisk vně i uvnitř' },
  { id: 1, title: 'Rozměr (mm):', content: '40 x 40 x 32' },
  { id: 3, title: 'Použití:', content: 'prsten' },
  { id: 0, title: 'Balení:', content: '48 ks' },
  { id: 10, title: 'Minimální počet kusů k objednání:', content: '48 ks' },
  {
    id: 11,
    title: 'Průřez vnitřku:',
    content:
      'http://www.media.rudorfer.cz/media/catalog/product/cache/2/thumbnail/75x/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-15.jpg',
    isImage: true,
  },
];

const code = '11001.01';
const productUrl = '/product';
const name = 'Diana dark red ring box';

export const images = [
  {
    original:
      'https://cz.rudorfer.whys.eu/media/_versions/images/category/1242/baby_2_standard__big.jpg',
    thumbnail:
      'https://cz.rudorfer.whys.eu/media/_versions/images/category/1242/baby_2_standard__big.jpg',
  },
  {
    original:
      'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-15.jpg',
    thumbnail:
      'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-15.jpg',
  },
  {
    original:
      'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-15.jpg',
    thumbnail:
      'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-15.jpg',
  },
  {
    original:
      'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-15.jpg',
    thumbnail:
      'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-15.jpg',
  },
  {
    original:
      'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-15.jpg',
    thumbnail:
      'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-15.jpg',
  },
  {
    original:
      'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-15.jpg',
    thumbnail:
      'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-15.jpg',
  },
  // {
  //   original:
  //     'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-99.jpg',
  //   thumbnail:
  //     'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-99.jpg',
  // },
  // {
  //   original:
  //     'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-43.jpg',
  //   thumbnail:
  //     'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-43.jpg',
  // },
  // {
  //   original:
  //     'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-12.jpg',
  //   thumbnail:
  //     'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-12.jpg',
  // },
  // {
  //   original:
  //     'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-12.jpg',
  //   thumbnail:
  //     'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/8/18300-12.jpg',
  // },
];

const breadcrumbs = [
  {
    name: 'Strona Główna',
    url: '/',
  },
  {
    name: 'Opakowania papierowe',
    url: '/opakowania-papierowe/',
  },
  {
    name: 'Sofia',
    url: '/sofia/',
  },
  {
    name: 'Sofia pierścionek biały',
    url: '/sofia-pierscionek-biay-1100101/',
  },
];

export const products: ProductItem[] = [
  {
    id: '5',
    params: { ...params, saleType: 'package', thresholdDisplayStock: 200 },
    code,
    name,
    images,
    productUrl,
    price: 12,
    originalPrice: 15,
    discount: 20,
    labels: coerceLabels({ new: true }),
    availableNum: 12250,
    isFav: true,
    breadcrumbs,
  },
  {
    id: '1',
    params: { ...params, saleType: 'package', thresholdDisplayStock: 200 },
    code,
    name,
    images,
    productUrl,
    price: 250,
    originalPrice: 250,
    labels: coerceLabels({ new: true, sale: true }),
    availableNum: 150,
    isFav: false,
    breadcrumbs,
  },
  {
    id: '2',
    params: { ...params, saleType: 'amount', thresholdDisplayStock: 200 },
    code,
    name,
    images: [
      {
        original:
          'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/1/11001-01brg.jpg',
        thumbnail:
          'http://www.media.rudorfer.cz/media/catalog/product/cache/2/image/9df78eab33525d08d6e5fb8d27136e95/1/1/11001-01brg.jpg',
      },
    ],
    productUrl,
    price: 7,
    originalPrice: 7,
    labels: coerceLabels({ sale: true }),
    availableNum: 0,
    isFav: true,
    breadcrumbs,
  },
  {
    id: '3',
    params: { ...params, saleType: 'amount', thresholdDisplayStock: 200 },
    code,
    name,
    images,
    productUrl,
    price: 20,
    discount: 30,
    originalPrice: 30,
    labels: coerceLabels({ second_quality: true }),
    availableNum: 300,
    isFav: false,
    breadcrumbs,
  },
  {
    id: '4',
    params: { ...params, saleType: 'piece', thresholdDisplayStock: 30 },
    code,
    name,
    images: [],
    productUrl,
    price: 700,
    originalPrice: 700,
    availableNum: 145,

    isFav: false,
    breadcrumbs,
  },
].map<ProductItem>(coerceProduct);

function coerceLabels(labels) {
  return {
    discount: false,
    new: false,
    sale: false,
    second_quality: false,
    ...labels,
  };
}

function coerceProduct(props: $Shape<ProductItem>): ProductItem {
  return {
    ...props,
    productId: '',
    id: String(props.id),
    labels: coerceLabels(props.labels),
  };
}

export const productDetail = {
  ...products[0],

  prices: {
    // discount
    // discountGross: 0,
    discountNet: 0,
    // base
    baseNet: 0,
    // baseGross: 0,
    // meta
    discountPercentage: 0,
  },
  sizes: [],
  colors: [],
  colorId: 'variantId1',
  sizeId: 'variantId1',
  variantId: 'variantId1',
};

// function makeEmpty(obj): Object {
//   const emptyObj = { ...obj };

//   for (const key of Object.keys(obj)) {
//     const val = obj[key];

//     if (!val) {
//       continue;
//     }

//     if (val instanceof Array) {
//       emptyObj[key] = [];
//     } else if (val instanceof Object) {
//       emptyObj[key] = makeEmpty(val);
//     } else if (typeof val === 'boolean') {
//       emptyObj[key] = false;
//     } else if (typeof val === 'number') {
//       emptyObj[key] = 0;
//     } else if (typeof val === 'string') {
//       emptyObj[key] = '';
//     } else {
//       emptyObj[key] = val;
//     }
//   }

//   return emptyObj;
// }

// export function createEmptyProductDetail() {
//   return {
//     ...makeEmpty(productDetail),
//     currency: 'CZK',
//   };
// }

export const cartProductItems = [
  {
    productItem: {
      ...products[0],
    },
    prices: {
      discountGross: 8,
      discountNet: 8,
      totalDiscountNet: 10,

      baseNet: 16,
      baseGross: 20,

      discountPercentage: 2,
    },
    quantity: 192,
    status: { text: 'Odesíláme dnes', severity_type: 'OK' },
  },
  // {
  //   productItem: {
  //     ...products[1],
  //     catPrice: 10,
  //     promPrice: 8,
  //     netValue: 20,
  //     netValueDiscounts: 16,
  //     images: [
  //       {
  //         original:
  //           'http://www.media.rudorfer.cz/media/catalog/product/cache/2/small_image/175x/9df78eab33525d08d6e5fb8d27136e95/1/1/11001-01krz.jpg',
  //         thumbnail:
  //           'http://www.media.rudorfer.cz/media/catalog/product/cache/2/small_image/175x/9df78eab33525d08d6e5fb8d27136e95/1/1/11001-01krz.jpg',
  //       },
  //     ],
  //   },
  //   quantity: 48,
  //   status: { text: 'Odesíláme dnes', severity_type: 'OK' },
  // },
];

export const cart = {
  id: 42,
  total: 10000, //10000,
  prices: {
    cartDiscountGross: 10000,
    cartDiscountNet: 10000,
    baseGross: 10000,
    baseNet: 9600,
    discountGross: 200,
    discountNet: 100,
    discountPercentage: 3,
    discountPriceGross: 10,
    discountPriceNet: 22,
    discountEshop: 0,
    discountLimitOffer: 0,
    discountQuantity: 1,
  },
  itemsCount: cartProductItems.length,
  freeShippingFrom: 12000,
  freeShippingType: '',
  freeShippingPrice: 2000,
  minOrderPrice: 50,
  billingAddress: '',
  shippingAddress: '',
  paymentMethod: '',
  shippingMethod: '',
  note: '',
  token: '',
  attachments: '',
  quantityDiscount: [],
};

export const billingAddress = {
  company_name: 'AK optik, s.r.o.',
  first_name: 'Jan',
  last_name: 'Vesely',
  street_address: 'Ulica Lovecká 892/14',
  zip_code: '917 01',
  city: 'Trnava',
  country: 'SK',
  phone_number: '777888999',
  vat_number: 'CZSK2120199180',
  vat: '789456789',
  identification_number: '50089129',
  send_catalog: true,
};

export const shippingAddresses = [
  {
    can_order: true,
    id: 425,
    shipping_address: {
      id: 456,
      company_name: 'AK optik, s.r.o.',
      street_address: 'Ulica Lovecká 892/14',
      zip_code: '917 01',
      city: 'Trnava',
    },
  },
  {
    can_order: true,
    id: 426,
    shipping_address: {
      id: 457,
      company_name: 'AKK, s.r.o.',
      street_address: 'Ulice Malá 892/14',
      zip_code: '917 01',
      city: 'Brno',
    },
  },
];

export const orders = [
  {
    id: '45',
    orderNumber: '12354',
    acceptDate: new Date(),
    sendDate: new Date(),
    statusTitle: 'Vyrizeno',
    statusType: 'ok',
    shipping_address: {
      can_order: true,
      id: 425,
      shipping_address: {
        id: 456,
        company_name: 'AK optik, s.r.o.',
        street_address: 'Ulica Lovecká 892/14',
        zip_code: '917 01',
        city: 'Trnava',
      },
    },
    price: 534.6,
    invoiceId: '876573476',
    goods: [
      { product: { ...products[0] }, quantity: 2 },
      { product: { ...products[1] }, quantity: 2 },
    ],
  },
  {
    id: '46',
    orderNumber: '54689',
    acceptDate: new Date(),
    sendDate: new Date(),
    statusTitle: 'Vyrizuje se',
    statusType: 'warn',
    shipping_address: {
      can_order: true,
      id: 425,
      shipping_address: {
        id: 456,
        company_name: 'AK optik, s.r.o.',
        street_address: 'Ulica Lovecká 892/14',
        zip_code: '917 01',
        city: 'Trnava',
      },
    },
    price: 534.6,
    invoiceId: '876573476',
    goods: [{ product: { ...products[1] }, quantity: 200 }],
  },
  {
    id: '48',
    orderNumber: '54689',
    acceptDate: new Date(),
    sendDate: new Date(),
    statusTitle: 'Stornovana',
    statusType: 'unknown',
    shipping_address: {
      can_order: true,
      id: 425,
      shipping_address: {
        id: 456,
        company_name: 'AK optik, s.r.o.',
        street_address: 'Ulica Lovecká 892/14',
        zip_code: '917 01',
        city: 'Trnava',
      },
    },
    price: 534.6,
    invoiceId: '876573476',
    goods: [{ product: { ...products[1] }, quantity: 200 }],
  },
];

export const invoices = [
  {
    id: '11',
    invoiceNumber: '11',
    createdDate: new Date(),
    dueDate: new Date(),
    priceVAT: 534.6,
    paymentMethod: 'dobirka',
    statusTitle: 'zaplaceno',
    statusType: 'ok',
    leftToPay: 0,
    orderNumber: '54689',
    shippingAddress: {
      company_name: 'AK optik, s.r.o.',
      street_address: 'Ulica Lovecká 892/14',
      zip_code: '917 01',
      city: 'Trnava',
    },
  },
  {
    id: '46',
    invoiceNumber: '46',
    createdDate: new Date(),
    dueDate: new Date(),
    priceVAT: 534.6,
    paymentMethod: 'dobirka',
    statusTitle: 'nezaplaceno',
    statusType: 'warn',
    leftToPay: 534.6,
    orderNumber: '12354',
    shippingAddress: {
      company_name: 'AK optik, s.r.o.',
      street_address: 'Ulica Lovecká 892/14',
      zip_code: '917 01',
      city: 'Trnava',
    },
  },
  {
    id: '12',
    invoiceNumber: '12',
    createdDate: new Date(),
    dueDate: new Date(),
    priceVAT: 534.6,
    paymentMethod: 'prevodem',
    statusTitle: 'po splatnosti',
    statusType: 'error',
    leftToPay: 534.6,
    orderNumber: '12359',
    shippingAddress: {
      company_name: 'AK optik, s.r.o.',
      street_address: 'Ulica Lovecká 892/14',
      zip_code: '917 01',
      city: 'Trnava',
    },
  },
];

function coerceTertiaryMenuItem(props: $Shape<TertiaryMenuItem>): TertiaryMenuItem {
  return {
    ...props,
    image: coerceImage(''),
    labels: coerceLabels({ ...props.labels }),
  };
}

function coerceMenuItem(props: $Shape<MenuItem>): MenuItem {
  const subsOfSub = props.subsOfSub || [];
  return {
    ...props,
    image: coerceImage(''),
    labels: coerceLabels({ ...props.labels }),
    subsOfSub: subsOfSub.map(coerceTertiaryMenuItem),
  };
}

function coerceTopMenuItem(props: $Shape<TopMenuItem>): TopMenuItem {
  const subs = props.subs || [];
  const labels = props.labels || {};
  return {
    ...props,
    labels,
    image: coerceImage(''),
    subs: subs.map(coerceMenuItem),
  };
}

const imageSrc = 'http://www.media.rudorfer.cz/media/catalog/category_thumbnails/eco-mix.jpg';
// const image = { original: imgSrc, thumbnail: imgSrc };

function coerceImage(imgSrc) {
  return { original: imgSrc, thumbnail: imgSrc };
}

export const menuItems: TopMenuItem[] = [
  {
    id: '54',
    categoryUrl: '',
    title: 'Akce',
    iconUrl: '',
    subs: [
      {
        id: '55',
        categoryUrl: '',
        title: 'Výprodej',
        image: coerceImage(
          'http://www.media.rudorfer.cz/media/catalog/category_thumbnails/sale_1.jpg'
        ),
      },
      {
        id: '53',
        categoryUrl: '',
        title: 'Akce',
        image: coerceImage(
          'http://www.media.rudorfer.cz/media/catalog/category_thumbnails/actual-action-offer.jpg'
        ),
      },
      {
        id: '56',
        categoryUrl: '',
        title: 'Druhá jakost',
        image: coerceImage(
          'http://www.media.rudorfer.cz/media/catalog/category_thumbnails/sale_1.jpg'
        ),
      },
    ].map(coerceMenuItem),
  },
  {
    id: '9',
    categoryUrl: '',
    title: 'Novinky',
    subs: [],
    iconUrl: '',
    labels: coerceLabels({ new: true }),
  },
  {
    id: '1',
    categoryUrl: '',
    title: 'Zvláštní příležitosti',
    iconUrl: '',
    subs: [
      { id: '2', categoryUrl: '', title: 'Vánoce', image: coerceImage(imageSrc) },
      {
        id: '3',
        categoryUrl: '',
        title: 'Křest/První svaté přijímání',
        image: coerceImage(imageSrc),
        labels: coerceLabels({ new: true }),
      },
      {
        id: '4',
        categoryUrl: '',
        title: 'Svatba',
        image: coerceImage(imageSrc),
        subsOfSub: [
          { id: '422', categoryUrl: '', title: 'Pro ni', image: coerceImage(imageSrc) },
          { id: '252', categoryUrl: '', title: 'Pro neho', image: coerceImage(imageSrc) },
        ].map(coerceTertiaryMenuItem),
      },
      { id: '23', categoryUrl: '', title: 'Valentýn', image: coerceImage(imageSrc) },
      { id: '20', categoryUrl: '', title: 'Pro mince/medaile', image: coerceImage(imageSrc) },
    ].map(coerceMenuItem),
  },
  {
    id: '5',
    title: 'Papírové krabičky',
    categoryUrl: '',
    iconUrl: '',
    subs: [
      {
        id: '6',
        categoryUrl: '',
        title: 'Eco',
        image: coerceImage(imageSrc),
        labels: coerceLabels({ sale: true }),
      },
      { id: '7', categoryUrl: '', title: 'Sofia', image: coerceImage(imageSrc) },
      {
        id: '77',
        categoryUrl: '',
        title: 'Sofia II',
        image: coerceImage(imageSrc),
        labels: coerceLabels({ sale: true }),
      },
      { id: '8', categoryUrl: '', title: 'Eco Mix', image: coerceImage(imageSrc) },
      { id: '21', categoryUrl: '', title: 'Melody', image: coerceImage(imageSrc) },
      {
        id: '121',
        categoryUrl: '',
        title: 'Poem',
        image: coerceImage(imageSrc),
        labels: coerceLabels({ new: true }),
      },
      {
        id: '22',
        categoryUrl: '',
        title: 'Rose',
        image: coerceImage(imageSrc),
        labels: coerceLabels({ new: true }),
      },
      { id: '25', categoryUrl: '', title: 'Spirit Pearl', image: coerceImage(imageSrc) },
    ].map(coerceMenuItem),
  },
].map<TopMenuItem>(coerceTopMenuItem);

export const userData = {
  gender: 'female',
  email: 'sklep@jubiler.cz',
  first_name: 'Agata',
  last_name: 'Czaja',
  phone_number: '111666666',
  datetime_of_birth: '',
  marketingPermissions: {
    newsletter: true,
    phone: false,
    sms: true,
    fax: false,
  },
  userPermissions: [
    { shippingAddress: 'Prazska a345', value: '1' },
    { shippingAddress: 'Prazska a345', value: '2' },
    { shippingAddress: 'Prazska a345', value: '2' },
    { shippingAddress: 'Prazska a345', value: '2' },
  ],
};

export const users = [
  { id: 1, email: 'sklep@jubiler.pl', firstName: 'Agata', lastName: 'Czaja' },
  { id: 2, email: 'sklep@jubiler.pl', firstName: 'Agata', lastName: 'Czaja' },
  { id: 3, email: 'sklep@jubiler.pl', firstName: 'Agata', lastName: 'Czaja' },
  { id: 4, email: 'sklep@jubiler.pl', firstName: 'Agata', lastName: 'Czaja' },
];

export const roleOptions = [
  {
    id: '4',
    name: 'Faktury',
    canBeEdited: true,
    permissions: [
      { name: 'zobrazovat fakturační adresu', can: true },
      { name: 'pozvat uživatele', can: false },
      { name: 'číst zprávu o bonusech', can: false },
      { name: 'zobrazit faktury', can: true },
      { name: 'upravovat fakturační adresu', can: false },
      { name: 'zobrazovat objednávku', can: false },
      { name: 'číst zprávu o faktuře po splatnosti', can: true },
      { name: 'zobrazovat pozvánku', can: false },
      { name: 'upravovat role', can: false },
      { name: 'číst doručovací adresu', can: true },
      { name: 'upravovat doručovací adresu', can: false },
      { name: 'vytvořit objednávku', can: false },
      { name: 'číst zprávu o kategorii partnera', can: false },
    ],
  },
  {
    id: '1',
    name: 'Majitel',
    canBeEdited: false,
    permissions: [
      { name: 'zobrazovat fakturační adresu', can: true },
      { name: 'pozvat uživatele', can: true },
      { name: 'číst zprávu o bonusech', can: true },
      { name: 'zobrazit faktury', can: true },
      { name: 'upravovat fakturační adresu', can: true },
      { name: 'zobrazovat objednávku', can: true },
      { name: 'číst zprávu o faktuře po splatnosti', can: true },
      { name: 'zobrazovat pozvánku', can: true },
      { name: 'upravovat role', can: true },
      { name: 'číst doručovací adresu', can: true },
      { name: 'upravovat doručovací adresu', can: true },
      { name: 'vytvořit objednávku', can: true },
      { name: 'číst zprávu o kategorii partnera', can: true },
    ],
  },
  {
    id: '5',
    name: 'Objednávky',
    canBeEdited: true,
    permissions: [
      { name: 'zobrazovat fakturační adresu', can: true },
      { name: 'pozvat uživatele', can: false },
      { name: 'číst zprávu o bonusech', can: false },
      { name: 'zobrazit faktury', can: false },
      { name: 'upravovat fakturační adresu', can: false },
      { name: 'zobrazovat objednávku', can: true },
      { name: 'číst zprávu o faktuře po splatnosti', can: false },
      { name: 'zobrazovat pozvánku', can: false },
      { name: 'upravovat role', can: false },
      { name: 'číst doručovací adresu', can: true },
      { name: 'upravovat doručovací adresu', can: false },
      { name: 'vytvořit objednávku', can: true },
      { name: 'číst zprávu o kategorii partnera', can: false },
    ],
  },
  {
    id: '3',
    name: 'Objednávky+Faktury',
    canBeEdited: true,
    permissions: [
      { name: 'zobrazovat fakturační adresu', can: true },
      { name: 'pozvat uživatele', can: false },
      { name: 'číst zprávu o bonusech', can: false },
      { name: 'zobrazit faktury', can: true },
      { name: 'upravovat fakturační adresu', can: false },
      { name: 'zobrazovat objednávku', can: true },
      { name: 'číst zprávu o faktuře po splatnosti', can: true },
      { name: 'zobrazovat pozvánku', can: false },
      { name: 'upravovat role', can: false },
      { name: 'číst doručovací adresu', can: true },
      { name: 'upravovat doručovací adresu', can: false },
      { name: 'vytvořit objednávku', can: true },
      { name: 'číst zprávu o kategorii partnera', can: false },
    ],
  },
  {
    id: '2',
    name: 'Zástupce majitele',
    canBeEdited: true,
    permissions: [
      { name: 'zobrazovat fakturační adresu', can: true },
      { name: 'pozvat uživatele', can: true },
      { name: 'číst zprávu o bonusech', can: true },
      { name: 'zobrazit faktury', can: true },
      { name: 'upravovat fakturační adresu', can: true },
      { name: 'zobrazovat objednávku', can: true },
      { name: 'číst zprávu o faktuře po splatnosti', can: true },
      { name: 'zobrazovat pozvánku', can: true },
      { name: 'upravovat role', can: true },
      { name: 'číst doručovací adresu', can: true },
      { name: 'upravovat doručovací adresu', can: true },
      { name: 'vytvořit objednávku', can: true },
      { name: 'číst zprávu o kategorii partnera', can: true },
    ],
  },
];

export const filters = [
  {
    id: '1',
    label: 'Barva',
    options: [
      {
        value: '123',
        label: 'Černá',
      },
      {
        value: '125',
        label: 'Bílá',
      },
      {
        value: '124',
        label: 'Červená',
      },
      {
        value: '121',
        label: 'Fialová',
      },
      {
        value: '122',
        label: 'Modrá',
      },
    ],
    filterType: 'text',
  },
  {
    id: '2',
    label: 'Použití',
    options: [
      {
        value: '211',
        label: 'Náhrdelník',
      },
      {
        value: '210',
        label: 'Naušnice',
      },
      {
        value: '214',
        label: 'Prsten',
      },
      {
        value: '215',
        label: 'Snubní prsteny',
      },
      {
        value: '216',
        label: 'Malá sada',
      },
    ],
    filterType: 'text',
  },
  {
    id: '5',
    label: 'Cena',
    options: [
      {
        value: '1',
        label: '0,00 Kč - 9,99 Kč',
      },
      {
        value: '2',
        label: '10,00 Kč - 19,99 Kč',
      },
      {
        value: '3',
        label: '20,00 Kč a výše',
      },
    ],
    filterType: 'text',
  },
  {
    id: '4',
    label: 'Materiál',
    options: [
      {
        value: '410',
        label: 'Papír',
      },
    ],
    filterType: 'text',
  },
  {
    id: '3',
    label: 'Možnosti potisku',
    options: [
      {
        value: '311',
        label: 'Ano',
      },
      {
        value: '312',
        label: 'Ne',
      },
    ],
    filterType: 'bool',
  },
  {
    id: '6',
    label: 'Zboží skladem',
    options: [
      {
        value: '51',
        label: 'Ano',
      },
      {
        value: '52',
        label: 'Ne',
      },
    ],
    filterType: 'bool',
  },

  {
    id: '7',
    label: 'Zboží v akci',
    options: [
      {
        value: '51',
        label: 'Ano',
      },
      {
        value: '52',
        label: 'Ne',
      },
    ],
    filterType: 'bool',
  },
];

export const sortOptions = [
  { value: '200', label: 'Název A-Z' },
  { value: '201', label: 'Název Z-A' },
  { value: '202', label: 'Cena od nejlevnější' },
  { value: '203', label: 'Cena od nejdražší' },
];

export const articles = [
  {
    id: 1,
    created: '2019-01-02T13:14:46.996830Z',
    title: 'Užijte si novou sezónu s brýlemi značky ESPRIT',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus libero sit amet lobortis tempor. Vivamus semper lectus eget augue semper, sit amet tristique lorem faucibus. Sed posuere ornare auctor. Integer sollicitudin ligula libero, eu tempor nulla ',
    thumbnail: { image: { medium: 'https://www.rudorfer.cz/boxes/cz/obr/12360.m7.jpg' } },
    tags: [{ id: 1, name: 'Information' }],
  },
  {
    id: 2,
    created: '2019-01-02T13:14:46.996830Z',
    title: 'LAlala sdl ',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus libero sit amet lobortis tempor. Vivamus semper lectus eget augue semper, sit amet tristique lorem faucibus. Sed posuere ornare auctor. Integer sollicitudin ligula libero, eu tempor nulla ',
    thumbnail: { image: { medium: 'https://www.rudorfer.cz/boxes/cz/obr/12360.m7.jpg' } },
    tags: [{ id: 2, name: 'Tips & Inspiration' }],
  },
  {
    id: 3,
    created: '2019-01-02T13:14:46.996830Z',
    title: 'Užijte si novou sezónu s brýlemi značky ESPRIT',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus libero sit amet lobortis tempor. Vivamus semper lectus eget augue semper, sit amet tristique lorem faucibus. Sed posuere ornare auctor. Integer sollicitudin ligula libero, eu tempor nulla ',
    thumbnail: { image: { medium: 'https://www.rudorfer.cz/boxes/cz/obr/12360.m7.jpg' } },
    tags: [{ id: 3, name: 'Product overview' }],
  },
  {
    id: 4,
    created: '2019-01-02T13:14:46.996830Z',
    title: 'tututut utututu',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus libero sit amet lobortis tempor. Vivamus semper lectus eget augue semper, sit amet tristique lorem faucibus. Sed posuere ornare auctor. Integer sollicitudin ligula libero, eu tempor nulla ',
    thumbnail: { image: { medium: 'https://www.rudorfer.cz/boxes/cz/obr/12360.m7.jpg' } },
    tags: [{ id: 4, name: 'Trends' }],
  },
  {
    id: 5,
    created: '2019-01-02T13:14:46.996830Z',
    title: 'Užijte si novou sezónu s brýlemi značky ESPRIT',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus libero sit amet lobortis tempor. Vivamus semper lectus eget augue semper, sit amet tristique lorem faucibus. Sed posuere ornare auctor. Integer sollicitudin ligula libero, eu tempor nulla ',
    thumbnail: { image: { medium: 'https://www.rudorfer.cz/boxes/cz/obr/12360.m7.jpg' } },
    tags: [],
  },
  {
    id: 6,
    created: '2019-01-02T13:14:46.996830Z',
    title: 'tututut utututu',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus libero sit amet lobortis tempor. Vivamus semper lectus eget augue semper, sit amet tristique lorem faucibus. Sed posuere ornare auctor. Integer sollicitudin ligula libero, eu tempor nulla ',
    thumbnail: { image: { medium: 'https://www.rudorfer.cz/boxes/cz/obr/12360.m7.jpg' } },
    tags: [],
  },
  {
    id: 7,
    created: '2019-01-02T13:14:46.996830Z',
    title: 'Užijte si novou sezónu s brýlemi značky ESPRIT',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus libero sit amet lobortis tempor. Vivamus semper lectus eget augue semper, sit amet tristique lorem faucibus. Sed posuere ornare auctor. Integer sollicitudin ligula libero, eu tempor nulla ',
    thumbnail: { image: { medium: 'https://www.rudorfer.cz/boxes/cz/obr/12360.m7.jpg' } },
    tags: [],
  },
  {
    id: 8,
    created: '2019-01-02T13:14:46.996830Z',
    title: 'tututut utututu',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus libero sit amet lobortis tempor. Vivamus semper lectus eget augue semper, sit amet tristique lorem faucibus. Sed posuere ornare auctor. Integer sollicitudin ligula libero, eu tempor nulla ',
    thumbnail: { image: { medium: 'https://www.rudorfer.cz/boxes/cz/obr/12360.m7.jpg' } },
    tags: [],
  },
];

// @flow

import idx from 'idx';
// framework
import { Container } from '@whys/app/lib/state';
import { fetchJSON, getJSON } from '@whys/fetch/lib/json';
import { doFetch } from '@whys/fetch/lib/fetch';
// whyshop
import { coerceNum } from './mapping';
import type { FetchEnvType, ProductContainerType, LazyResource } from './types';
import type { CartDetailPayload, CartStateItemPayload, CartDetail, CartItem } from './models/cart';
import { resources, mapCartStats, mapCartDetail, mapIdToString } from './models/cart';
import type { ProductDetail } from './models/product';
import type { BackendID } from './models/cart';
import type { BillingAddressType, PaymentOption } from '../appState/types';

import { cartProductItems, products } from '../exampleHelpers/factories';

type MakeOrderStatus = 'Done' | 'UnavailableItemsInCart' | 'UnknownError';

function isNotNil(v) {
  return v != null;
}

type ResourceStateEnum = 'idle' | 'loading' | 'loaded';
// we care only about wherether OK or NOT
type AsyncBinaryResult = Promise<boolean>;
type AsyncObjectResult = Promise<Object>;
// type AsyncEmptyResult = Promise<void>;

type LocalState = {|
  detailState: ResourceStateEnum,
  statsState: ResourceStateEnum,
  items: Array<CartItem>,
  paymentOptions: Array<PaymentOption>,
  shippingOptions: Array<PaymentOption>,
  ...CartDetail,
|};

type LocalProps = {|
  fetchEnv: FetchEnvType,
  // cache: CacheType<$FlowFixMe>,

  initialState?: {|
    total?: number,
    itemsCount?: number,
  |},
  productsContainer: ProductContainerType<$FlowFixMe, $FlowFixMe>,
|};

export class CartContainer extends Container<LocalState> {
  state: LocalState;
  props: LocalProps;

  constructor(props: LocalProps) {
    super();

    this.props = props;

    // if initial state contains itemsCount/total => stats are loaded
    const statsLoaded =
      isNotNil(idx(props, (_) => _.initialState.itemsCount)) &&
      isNotNil(idx(props, (_) => _.initialState.total));

    this.state = {
      id: 0,
      total: idx(props, (_) => _.initialState.total) || 0,
      itemsCount: idx(props, (_) => _.initialState.itemsCount) || 0,
      prices: {
        cartDiscountGross: 0,
        cartDiscountNet: 0,
        baseGross: 0,
        baseNet: 0,
        discountGross: 0,
        discountNet: 0,
        discountPercentage: 0,
        discountPriceGross: 0,
        discountPriceNet: 0,
        discountEshop: 0,
        discountQuantity: 0,
        discountLimitOffer: 0,
        couponsPriceNet: 0,
        couponsPriceGross: 0,
        couponsDiscount: 0,
      },
      items: [],
      statsState: statsLoaded ? 'loaded' : 'idle',
      detailState: 'idle',
      freeShippingFrom: 0,
      freeShippingType: '',
      freeShippingPrice: 0,
      minOrderPrice: 0,
      note: '',
      token: '',
      shippingOptions: [],
      paymentOptions: [],
      billingAddress: null,
      shippingAddress: null,
      paymentMethod: null,
      shippingMethod: null,
      attachments: [],
      quantityDiscount: [],
      individualDiscountMessage: '',
    };

    // const { cache, fetchEnv } = props;
  }

  //
  // Mutations
  //

  async addItem(productItemId: string, quantity: number): AsyncBinaryResult {
    const { fetchEnv } = this.props;
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.addItem,
      data: { variant: productItemId, quantity },
    });
    // refetch (no matter if ok/err)
    await this.refetchCartDetail();
    return result.status === 'ok';
  }

  async addManyItems(addInfo: {| variantId: string, quantity: number |}[]): AsyncBinaryResult {
    const { fetchEnv } = this.props;
    // [
    //   {
    //     variant: 32426,
    //     quantity: 20,
    //   },
    //   {
    //     variant: 11546,
    //     quantity: 10,
    //   },
    // ];
    const data = addInfo.map((item) => {
      return { variant: item.variantId, quantity: item.quantity };
    });

    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.addManyItems,
      data,
    });
    // refetch (no matter if ok/err)
    await this.refetchCartDetail();
    return result.status === 'ok';
  }

  async setItemQuantity(cartItem: CartItem, quantity: number): AsyncBinaryResult {
    const { fetchEnv } = this.props;
    const productItemId = cartItem.productItem.id;
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.setItemQuantity(productItemId),
      data: { quantity },
    });
    // refetch (no matter if ok/err)
    await this.refetchCartDetail();
    return result.status === 'ok';
  }

  async removeItem(cartItem: CartItem): AsyncBinaryResult {
    const { fetchEnv } = this.props;
    const productItemId = cartItem.productItem.id;
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.removeItem(productItemId),
      data: null,
    });
    // refetch (no matter if ok/err)
    await this.refetchCartDetail();
    return result.status === 'ok';
  }

  async clearCart(): AsyncBinaryResult {
    const { fetchEnv } = this.props;
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.clearCart,
      data: null,
    });
    // refetch (no matter if ok/err)
    this.setState({ items: [], total: 0, itemsCount: 0 });
    await this.refetchCartDetail();
    return result.status === 'ok';
  }

  async updateCart(data: any): AsyncBinaryResult {
    const { fetchEnv } = this.props;
    const response = await doFetch(resources.updateCart.url, fetchEnv, {
      method: 'PATCH',
      body: data,
    });
    // refetch (no matter if ok/err)
    await this.refetchCartDetail();
    return response.ok;
  }

  async makeOrder(data: any): AsyncObjectResult {
    const { fetchEnv } = this.props;
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.createOrder,
      data,
    });

    this.setState({ items: [], total: 0, itemsCount: 0 });
    return result;
  }

  async createShippingAddress(data: any): AsyncBinaryResult {
    const { fetchEnv } = this.props;
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.createShippingAddress,
      data,
    });
    // refetch (no matter if ok/err)
    await this.refetchCartDetail();
    return result.status === 'ok';
  }

  async addCoupon(promocode: string): AsyncObjectResult {
    const { fetchEnv } = this.props;
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.addCoupon,
      data: { code: promocode },
    });
    await this.refetchCartDetail();
    return result;
  }

  async removeCoupon(promocode: string): AsyncObjectResult {
    const { fetchEnv } = this.props;
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.removeCoupon,
      data: { code: promocode },
    });
    await this.refetchCartDetail();
    return result;
  }

  async getCoupons(): AsyncObjectResult {
    const { fetchEnv } = this.props;
    const result = await fetchJSON({
      env: fetchEnv,
      ...resources.getCoupons,
      data: null,
    });
    return result;
  }

  //
  // Queries
  //

  async fetchCartStats(): AsyncBinaryResult {
    const { statsState, detailState } = this.state;
    if (statsState === 'loaded' || detailState === 'loaded') {
      return true;
    }
    return this.refetchCartStats();
  }

  async loadCartStats(): AsyncBinaryResult {
    return this.fetchCartStats();
  }

  async refetchCartStats(): AsyncBinaryResult {
    const { fetchEnv } = this.props;
    const result = await getJSON(resources.cartStats.url, fetchEnv);
    if (result.status === 'ok') {
      const cartStats = mapCartStats(result.data);
      this.setState({ total: cartStats.total, itemsCount: cartStats.itemsCount });
      return true;
    }
    return false;
  }

  async fetchCartDetail(): AsyncBinaryResult {
    if (this.state.statsState === 'loaded') {
      return true;
    }
    return this.refetchCartDetail();
  }

  async refetchCartDetail(): AsyncBinaryResult {
    if (this.state.detailState === 'loading') {
      return true;
    }
    this.setState({ detailState: 'loading' });
    const result = await getJSON(resources.cartDetail.url, this.props.fetchEnv);
    if (result.status === 'ok') {
      const data: CartDetailPayload = result.data;
      await this._setNewDetail(data);
      return true;
    }
    this.setState({ detailState: 'loaded' });
    return false;
  }

  async fetchPaymentOptions(): AsyncBinaryResult {
    const result = await getJSON(resources.paymentOptions.url, this.props.fetchEnv);
    if (result.status === 'ok') {
      const paymentOptions: Array<PaymentOption> = result.data.map(mapIdToString);
      this.setState({ paymentOptions });
      return true;
    }
    return false;
  }

  async fetchPaymentOptionsViaShipping(shippingMethodId: string): AsyncBinaryResult {
    const result = await getJSON(
      `${resources.paymentOptions.url}?shipping_method=${shippingMethodId}`,
      this.props.fetchEnv
    );
    if (result.status === 'ok') {
      const paymentOptions: Array<PaymentOption> = result.data.map(mapIdToString);
      this.setState({ paymentOptions });
      return true;
    }
    return false;
  }

  async fetchShippingOptions(country: string): AsyncBinaryResult {
    if (!country) return false;
    const result = await getJSON(resources.shippingOptions.url(country), this.props.fetchEnv);
    if (result.status === 'ok') {
      const shippingOptions: Array<PaymentOption> = result.data.map(mapIdToString);
      this.setState({ shippingOptions });
      return true;
    }
    return false;
  }

  //
  // Selectors
  //

  selectCartDetail(): CartDetail {
    return this._getCartDetail();
  }

  selectTotal(): number {
    return this.state.total;
  }

  selectPriceStats(): Object {
    return { total: 0, totalWithTax: 0 };
  }

  selectItemsCount(): number {
    return this.state.itemsCount;
  }

  selectTotalLoaded(): boolean {
    return this._hasTotalLoaded();
  }

  selectCartItems(): Array<CartItem> {
    return this.state.items;
  }

  lazyCartDetail(): LazyResource<CartDetail> {
    return {
      resource: this._getCartDetail(),
      load: () => this.fetchCartDetail(),
      reload: () => {
        return this.refetchCartDetail();
      },
      isLoaded: this.state.detailState === 'loaded',
    };
  }

  selectPaymentOptions(): Array<PaymentOption> {
    return this.state.paymentOptions;
  }

  selectShippingOptions(): Array<PaymentOption> {
    return this.state.shippingOptions;
  }

  selectNote(): string {
    return this.state.note;
  }

  selectShippingMethod(): ?PaymentOption {
    return this.state.shippingMethod;
  }

  selectPaymentMethod(): ?PaymentOption {
    return this.state.paymentMethod;
  }

  selectShippingAddress(): ?BillingAddressType {
    return this.state.shippingAddress;
  }

  //
  // Private helpers (move ugly but useful code here)
  //

  _getCartDetail(): CartDetail {
    const {
      id,
      prices,
      itemsCount,
      total,
      freeShippingFrom,
      freeShippingPrice,
      freeShippingType,
      minOrderPrice,
      shippingAddress,
      billingAddress,
      paymentMethod,
      shippingMethod,
      note,
      token,
      attachments,
      quantityDiscount,
      individualDiscountMessage,
    } = this.state;
    return {
      id,
      total,
      prices,
      itemsCount,
      freeShippingFrom,
      freeShippingPrice,
      freeShippingType,
      minOrderPrice,
      shippingAddress,
      billingAddress,
      paymentMethod,
      shippingMethod,
      note,
      token,
      attachments,
      quantityDiscount,
      individualDiscountMessage,
    };
  }

  _hasTotalLoaded(): boolean {
    const { detailState, statsState } = this.state;

    return detailState === 'loaded' || statsState === 'loaded';
  }

  async _setNewDetail(data: CartDetailPayload): Promise<void> {
    //
    // 1) resolve cart items
    //

    const productItemIDs = data.items.map((_) => String(_.variant));
    const products: ProductDetail[] = await this._resolveProducts(productItemIDs);
    let productsByIDs = {};
    for (const product of products) {
      productsByIDs[product.id] = product;
    }

    const cartItems: Array<CartItem> = (() => {
      let result = [];
      for (const itemPayload: CartStateItemPayload of data.items) {
        const variantId = String(itemPayload.variant);
        const productItem = productsByIDs[variantId];
        if (!productItem) {
          continue;
        }
        const item = {
          productItem,
          status: {
            text: idx(itemPayload, (_) => _.status.text) || '',
            // Note: INFO is neutral IMO
            type: idx(itemPayload, (_) => _.status.severity_type) || 'INFO',
          },
          quantity: coerceNum(itemPayload.quantity, 0),
          prices: {
            discountNet: coerceNum(itemPayload.prices.discount_price_net, 0),
            baseNet: coerceNum(itemPayload.prices.base_price_net, 0),
            totalNet: coerceNum(itemPayload.prices.total_base_price_net, 0),
            totalDiscountNet: coerceNum(itemPayload.prices.total_discount_price_net, 0),
            couponsPriceNet: coerceNum(itemPayload.prices.total_cart_coupons_price_net, 0),
            couponsPriceGross: coerceNum(itemPayload.prices.total_cart_coupons_price_gross, 0),
            couponsDiscount: coerceNum(itemPayload.prices.total_coupons_discount, 0),
          },
        };
        result.push(item);
      }
      return result;
    })();

    //
    // 2) resolve detail
    //

    const detail = mapCartDetail(data);
    //
    // 3) update
    //

    await this.setState({ items: cartItems, ...detail, detailState: 'loaded' });
  }

  async _resolveProducts(ids: string[]): Promise<ProductDetail[]> {
    const { productsContainer } = this.props;
    const productItems = await productsContainer.resolveProductItemsByIds(ids);
    return productItems;
  }
}

// @flow

import * as React from 'react';
import { useRef, useState } from 'react';
import styled from '@emotion/styled';

import { useTheme, styledTheme } from '../app/theme';

import Grid from '@material-ui/core/Grid';

import { FlexRow, FlexColumn, Block } from '../baseUI';
import type { LayoutVariantEnum, ProductItem } from '../appState/types';
import { ProductCardParams } from './ProductCardParams';
import { VerticalSpace, ThemedText } from '../appUI';
import BasicPopover from '../appUI/BasicPopover';
import ProductPopover from './ProductPopover';
import { ImageLink, ProductTitleLink, ProductPrice, ProductSaleInfo } from './product';
import { themeMUI, useLoginContainer } from '../app/state';
import { ProductLabels, DiscountCircle } from './ProductLabels';
import { AddToCartLine } from '../cart/addToCart';
import ProductAvailability from './ProductAvailability';
import FavoriteButton from './FavoriteButton';
import WatchButton from './WatchButton';
import { SystemPageLink, TextLink } from '../app/link';
import { useMediaQuery } from '../hooks/responsive';
import { Trans } from '@lingui/react';

import { useGlobalContainer } from '../app/state';
import { useHistory } from 'react-router-dom';
import { getImagePlaceholder } from './getImagePlaceholder';

const isVariant = (type: LayoutVariantEnum, value: LayoutVariantEnum) => type === value;
const Card = styled.div`
  position: relative;
  border: 1px solid #eee;
  padding: 13px 10px;
`;

const GridCard = styled(Card)`
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const AlfaboxCard = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
`;

const FormatForName = styled.div`
  width: 50%;
  text-align: left;
  padding-left: 30px;
`;

const MarginLeft = styled.div`
  padding-left: 20px;
`;

const LoginButton = (props) => {
  const theme = useTheme();
  const globalContainer = useGlobalContainer();
  const history = useHistory();

  const { pathname, search } = history.location;

  return (
    <ThemedText
      backgroundColor={theme.colors.primaryBg}
      padding="4px 10px"
      width="fit-content"
      size="bigger"
    >
      <Trans>
        The price is available after{' '}
        <TextLink
          golden
          to={`${globalContainer.selectSystemPageUrl('login')}?next=${pathname}${search}`}
        >
          {' '}
          login
        </TextLink>
      </Trans>
    </ThemedText>
  );
};

type LocalProps = {|
  variant: LayoutVariantEnum,
  product: ProductItem,
  onFavoriteChange: (ProductItem, favorite: boolean) => Promise<boolean>,
  isFavorite: boolean,
|};

function ProductCard(props: LocalProps) {
  const [hasPopover, setPopover] = useState(false);
  let imageRef = useRef(null);
  const isSmall = useMediaQuery(themeMUI.breakpoints.down('sm'));
  const isMedium = useMediaQuery(themeMUI.breakpoints.down('md'));

  const { variant, product } = props;
  const { code, productUrl, images, price, originalPrice, labels_test } = product;
  const userLogin = useLoginContainer();
  const isLogged = userLogin.isLogged();
  const initiallyFavorite = props.isFavorite;

  const globalContainer = useGlobalContainer();
  const imgPlaceholder = getImagePlaceholder();

  const onFavoriteChange = (val) => {
    return props.onFavoriteChange(props.product, val);
  };

  const renderImage = () => {
    if (images.length > 0) {
      return <ImageLink height={200} title={code} to={productUrl} src={images[0].thumbnail} />;
    } else {
      return <ImageLink height={200} title={code} to={productUrl} src={imgPlaceholder} />;
    }
  };

  if (globalContainer.isSpecificProject('alfabox')) {
    if (isVariant(variant, 'grid')) {
      return (
        <AlfaboxCard>
          <ProductLabels isSmall={isSmall} labels_test={labels_test} />
          <FlexColumn position="relative">
            <Block width="100%" padding="10px 5px">
              {renderImage()}
            </Block>
            <FormatForName>
              <ProductTitleLink productUrl={productUrl} title={code} />
            </FormatForName>
            <FlexRow justifyContent={!isLogged ? 'flex-start' : 'space-between'}>
              {!isLogged ? (
                <MarginLeft>
                  <LoginButton />
                </MarginLeft>
              ) : (
                <FlexRow width="100%" alignItems="center" justifyContent="space-between">
                  <FlexColumn
                    width="100%"
                    alignItems="flex-start"
                    justifyContent="center"
                    paddingLeft={30}
                    minHeight={50}
                  >
                    <ProductPrice
                      params={product.params}
                      isAlfabox={true}
                      price={price}
                      originalPrice={originalPrice}
                    />
                  </FlexColumn>

                  <FlexColumn width="100%">
                    <AddToCartLine product={product} useIconButton={true} />
                  </FlexColumn>
                </FlexRow>
              )}
            </FlexRow>
            <VerticalSpace />
            <FlexColumn justifyContent="space-between">
              {product.params.dimensions && !product.shortDescriptionHtml && (
                <FlexRow width="100%" alignItems="center" justifyContent="space-between">
                  <FlexColumn width="100%" alignItems="flex-start" paddingLeft={30}>
                    <Trans>Dimensions (mm):</Trans>
                  </FlexColumn>
                  <FlexColumn width="100%">{product.params.dimensions}</FlexColumn>
                </FlexRow>
              )}
              {product.params.usage && (
                <FlexRow width="100%" alignItems="center" justifyContent="space-between">
                  <FlexColumn width="100%" alignItems="flex-start" paddingLeft={30}>
                    <Trans>Usage:</Trans>
                  </FlexColumn>
                  <FlexColumn width="100%">{product.params.usage}</FlexColumn>
                </FlexRow>
              )}
              <FlexRow width="100%">
                <FlexColumn width="100%" alignItems="flex-start" paddingLeft={30}>
                  <Trans>Print option:</Trans>
                </FlexColumn>
                <FlexColumn width="100%">{product.params.printOption}</FlexColumn>
              </FlexRow>
              <FlexRow width="100%">
                <FlexColumn width="100%" alignItems="flex-start" paddingLeft={30}>
                  <Trans>Package:</Trans>
                </FlexColumn>
                <FlexColumn width="100%">
                  {product.params.packageSize} {product.params.measureUnit.name}
                </FlexColumn>
              </FlexRow>
            </FlexColumn>
            <VerticalSpace />
          </FlexColumn>
        </AlfaboxCard>
      );
    } else {
      return (
        <Card variant={variant}>
          <ProductLabels isSmall={isSmall} labels_test={labels_test} />
          <Grid container spacing={3}>
            <FlexRow
              justifyContent="center"
              width={isSmall && '50%'}
              paddingRight={20}
              boxSizing="border-box"
            >
              <Block width={200} padding="10px 5px">
                {renderImage()}
              </Block>
            </FlexRow>
            <Grid item sm={6} md={4}>
              <FlexRow position="relative" alignItems="center">
                <ProductTitleLink productUrl={productUrl} title={code} />
              </FlexRow>
              <VerticalSpace />
              <ThemedText size="bigger">{product.name}</ThemedText>
              <VerticalSpace />
              <ProductCardParams
                params={product.params}
                showDimensions={!product.shortDescriptionHtml}
              />
              <VerticalSpace />
              {!isLogged && isSmall && <LoginButton />}
            </Grid>
            {!isLogged && !isSmall && (
              <Grid item md={4}>
                <LoginButton />
              </Grid>
            )}
            {isLogged && (
              <Grid item sm={12} md={isMedium ? 3 : 5}>
                <FlexRow
                  minHeight={185}
                  flexDirection={isMedium ? 'column' : 'row'}
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <FlexColumn
                    alignItems={isSmall ? 'flex-start' : 'center'}
                    width={isSmall && '50%'}
                    paddingLeft={10}
                    paddingTop={10}
                  >
                    <ProductPrice
                      params={product.params}
                      isAlfabox={true}
                      price={price}
                      originalPrice={originalPrice}
                    />
                  </FlexColumn>

                  <FlexColumn
                    alignItems="flex-end"
                    paddingRight={20}
                    paddingLeft={isMedium ? 10 : 0}
                    boxSizing="border-box"
                    width={isSmall && '50%'}
                  >
                    <AddToCartLine product={product} useIconButton={true} />
                  </FlexColumn>
                </FlexRow>
              </Grid>
            )}
          </Grid>
        </Card>
      );
    }
  }

  if (isVariant(variant, 'row')) {
    return (
      <Card variant={variant}>
        {globalContainer.isSpecificSite('rudorfer.dealer') || (
          <ProductLabels isSmall={isSmall} labels_test={labels_test} />
        )}
        <Grid container spacing={3}>
          <FlexRow
            justifyContent="center"
            width={isSmall && '50%'}
            paddingRight={20}
            boxSizing="border-box"
          >
            <div
              ref={imageRef}
              onMouseEnter={() => setPopover(true)}
              onMouseLeave={() => setPopover(false)}
              style={{ position: 'relative' }}
            >
              {product.discount && isLogged ? <DiscountCircle discount={product.discount} /> : null}
              <Block width={222}>{renderImage()}</Block>
              <BasicPopover
                isVisible={hasPopover}
                anchorEl={imageRef.current}
                popoverId={product.id}
                placement="right"
              >
                <ProductPopover
                  isLogged={isLogged}
                  product={product}
                  isFavorite={initiallyFavorite}
                  onFavoriteChange={onFavoriteChange}
                />
              </BasicPopover>
            </div>
          </FlexRow>
          <Grid item sm={6} md={4}>
            <FlexRow position="relative" alignItems="center">
              <ProductTitleLink productUrl={productUrl} title={code} />
              {isLogged && (
                <FavoriteButton
                  isFavorite={initiallyFavorite}
                  onFavoriteChange={onFavoriteChange}
                />
              )}
            </FlexRow>
            <VerticalSpace />
            <ThemedText size="bigger">{product.name}</ThemedText>
            <VerticalSpace />
            <ProductCardParams
              params={product.params}
              showDimensions={!product.shortDescriptionHtml}
            />
            <VerticalSpace />
            {!isLogged && isSmall && <LoginButton />}
          </Grid>
          {!isLogged && !isSmall && (
            <Grid item md={4}>
              <LoginButton />
            </Grid>
          )}
          {isLogged && (
            <Grid item sm={12} md={isMedium ? 3 : 5}>
              <FlexRow
                minHeight={185}
                flexDirection={isMedium ? 'column' : 'row'}
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <FlexColumn
                  alignItems={isSmall ? 'flex-start' : 'center'}
                  width={isSmall && '50%'}
                  paddingLeft={10}
                  paddingTop={10}
                  whiteSpace="nowrap"
                >
                  <ProductPrice
                    params={product.params}
                    price={price}
                    originalPrice={originalPrice}
                  />
                </FlexColumn>

                <FlexColumn
                  alignItems="flex-end"
                  paddingRight={20}
                  boxSizing="border-box"
                  width={isSmall && '50%'}
                >
                  <AddToCartLine product={product} />
                  <VerticalSpace />
                  <ProductSaleInfo
                    type={product.params.saleType}
                    availableNum={product.availableNum}
                    textAlign="right"
                  />
                  <VerticalSpace />
                  <ProductAvailability
                    amount={product.availableNum}
                    splitBy={product.params.thresholdDisplayStock}
                  />
                  <WatchButton productId={product.id} allowed={product.allowWatchdog} />
                </FlexColumn>
              </FlexRow>
            </Grid>
          )}
        </Grid>
      </Card>
    );
  }
  return (
    <GridCard>
      <ProductLabels isSmall={isSmall} labels_test={labels_test} />
      {product.discount && isLogged ? <DiscountCircle discount={product.discount} /> : null}
      <FlexRow justifyContent="center">{renderImage()}</FlexRow>
      <FlexRow position="relative" justifyContent="center" alignItems="center">
        <ProductTitleLink productUrl={productUrl} title={code} />
        {isLogged && (
          <FavoriteButton isFavorite={initiallyFavorite} onFavoriteChange={onFavoriteChange} />
        )}
      </FlexRow>
      <VerticalSpace />
      <ThemedText size="bigger" textAlign="center">
        {product.name}
      </ThemedText>
      <VerticalSpace />
      <ProductCardParams
        showDimensions={!product.shortDescriptionHtml}
        params={product.params}
        textAlign="right"
      />
      <VerticalSpace />
      {isLogged ? (
        <FlexColumn marginTop="auto" alignItems="center">
          <FlexRow justifyContent="center" alignItems="center">
            <ProductPrice params={product.params} price={price} originalPrice={originalPrice} />
          </FlexRow>
          <VerticalSpace />
          <Block height={45}>
            <AddToCartLine product={product} />
          </Block>
          <VerticalSpace />
          <FlexRow alignItems="center" justifyContent={'space-around'} width="100%">
            <ProductSaleInfo type={product.params.saleType} availableNum={product.availableNum} />
            <ProductAvailability
              amount={product.availableNum}
              splitBy={product.params.thresholdDisplayStock}
            />
          </FlexRow>
          <WatchButton productId={product.id} allowed={product.allowWatchdog} />
        </FlexColumn>
      ) : (
        <FlexRow justifyContent="center">
          <LoginButton />
        </FlexRow>
      )}
    </GridCard>
  );
}

export default ProductCard;

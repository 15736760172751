// @flow

import idx from 'idx';
// framework
import { Container } from '@whys/app/lib/state';
import { getJSON } from '@whys/fetch/lib/json';

import { defaultSessionState } from '../app/config';
// app
import type { FetchEnvType, LanguageCodeEnum } from '../types/app';
import type { InitialState } from '../appState/types';
import type { InitialStatePayload } from '../types/api';
import { coerceInt } from '../whyshop/mapping';

const resources = {
  initialState: {
    url: '/api/utils/initial-data/',
  },
};

type StaticPageNames = 'print-options' | 'contact' | 'terms' | 'how-to-shop' | 'about-us' | 'news';

const mapPermissions = (data?: InitialStatePayload) => {
  return data && data.permissions
    ? {
        ...data.permissions.read_permissions,
        ...data.permissions.update_permissions,
        ...data.permissions.delete_permissions,
        ...data.permissions.create_permissions,
      }
    : {};
};

function mapPayload(data?: InitialStatePayload): InitialState {
  function validCode(val: string): LanguageCodeEnum {
    if (['en', 'cs', 'de', 'fr', 'hu', 'pl', 'ru', 'sk'].includes(val)) {
      return (val: any);
    }
    return 'en';
  }
  const availableLanguages = (idx(data, (_) => _.available_languages) || []).map((_) => ({
    code: validCode(_.language_code),
    title: _.title,
  }));

  return {
    cart: {
      total: Number(idx(data, (_) => _.header_data.cart.prices.total_discount_price_net) || 0),
      itemsCount: idx(data, (_) => _.header_data.cart.items_count) || 0,
    },
    watched: idx(data, (_) => _.header_data.watchdog_count) || 0,
    favorites: idx(data, (_) => _.header_data.favorites_count) || 0,
    availableLanguages,
    defaultLanguage: validCode(idx(data, (_) => _.language_code_default) || 'en'),
    operator: idx(data, (_) => _.operator) || defaultState.operator,
    rules: { ...defaultState.rules, ...idx(data, (_) => _.rules) },
    site: {
      name: idx(data, (_) => _.site.name) || defaultState.site.name,
      domain: idx(data, (_) => _.site.domain) || defaultState.site.domain,
      defaultPageTitle: idx(data, (_) => _.site.page_title) || defaultState.site.defaultPageTitle,
      projectId: idx(data, (_) => _.site.project_id) || defaultState.site.projectId,
    },
    staticPages: idx(data, (_) => _.static_pages) || defaultState.staticPages,
    systemPages: idx(data, (_) => _.system_pages) || defaultState.systemPages,
    session: {
      listLayout: idx(data, (_) => _.session.listLayout) || defaultState.session.listLayout,
      menuStatus: idx(data, (_) => _.session.menuStatus) || defaultState.session.menuStatus,
    },
    permissions: mapPermissions(data),
    profile: idx(data, (_) => _.profile) || {},
    price: {
      currency: idx(data, (_) => _.currency) || 'CZK',
      minimumFractionDigits: coerceInt(
        idx(data, (_) => _.config.price.minimum_fraction_digits),
        defaultState.price.minimumFractionDigits
      ),
      maximumFractionDigits: coerceInt(
        idx(data, (_) => _.config.price.maximum_fraction_digits),
        defaultState.price.maximumFractionDigits
      ),
    },
    footerData: idx(data, (_) => _.footer_data) || [],
    order: {
      freeShippingFrom: idx(data, (_) => _.order.free_shipping_from) || 0,
      minOrderPrice: idx(data, (_) => _.order.min_order_price) || 0,
    },
    gtmId: idx(data, (_) => _.marketing.gtm_id) || '',
    fbDomainVerification: idx(data, (_) => _.marketing.fb_domain_verification) || '',
    addressProvider: idx(data, (_) => _.config.address_provider) || '',
    companyAddress: `${idx(data, (_) => _.operator.address_street) || ''} ${idx(
      data,
      (_) => _.operator.address_city
    ) || ''}`,
    softwaremodules: idx(data, (_) => _.softwaremodules) || [],
  };
}

const defaultState: InitialState = {
  cart: { total: 0, itemsCount: 0 },
  watched: 0,
  favorites: 0,
  availableLanguages: [],
  defaultLanguage: 'en',
  site: {
    name: '',
    domain: '',
    defaultPageTitle: '',
    projectId: 'rudorfer',
  },
  rules: {
    show_newsletter_form: true,
  },
  operator: {
    name: '',
    identification_number: '',
    vat_number: '',
    international_vat_id: '',
    address_street: '',
    address_city: '',
    address_zipcode: '',
    bank_account_number: '',
    bank_iban: '',
    bank_swift: '',
    bank_name: '',
    opening_hours: '',
    email: '',
    primary_phone_number: '',
    alternative_phone_numbers: [],
    mobile_number: '',
    fax_number: '',
    country: '',
  },
  staticPages: {},
  systemPages: {},
  session: defaultSessionState,
  permissions: {},
  profile: {},
  footerData: [],
  order: { minOrderPrice: 0, freeShippingFrom: 0 },
  gtmId: '',
  fbDomainVerification: '',
  addressProvider: '',
  companyAddress: '',
  price: {
    currency: 'CZK',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  },
  softwaremodules: []
};

type LocalProps = {|
  fetchEnv: FetchEnvType,
  // cache: CacheType<InitialState | null>,
  initialData: ?InitialStatePayload,
|};

type LocalState = {|
  initial: InitialState,
  didFetch: boolean,
  staticPageInfos: { [StaticPageNames]: {| url: string, name: string |} },
|};

/**

This container is responsible to load initial data, that can be used in
the application or other containers.

*/

export class InitialContainer extends Container<LocalState> {
  state: LocalState;
  props: LocalProps;

  constructor(props: LocalProps) {
    super();

    const { initialData } = props;

    const didFetch = !!initialData;

    this.state = {
      initial: initialData ? mapPayload(initialData) : defaultState,
      didFetch,
      staticPageInfos: {},
    };
    this.props = props;
  }

  //
  // Fetchers
  //

  async getInitialState(): Promise<InitialState> {
    const { didFetch } = this.state;
    if (didFetch) {
      return Promise.resolve(this.state.initial);
    }

    const result = await getJSON(resources.initialState.url, this.props.fetchEnv);
    if (result.status === 'ok') {
      const { data } = result;
      const resource = mapPayload(data);
      this.setState({ initial: resource, didFetch: true });
      return resource;
    }

    // failed :/
    return defaultState;
  }

  async loadStaticPageUrls(): Promise<void> {
    const result = await getJSON('/api/content/staticpage/urls/', this.props.fetchEnv);
    if (result.status === 'ok') {
      const { data } = result;
      this.setState({ staticPageInfos: data });
    }
  }

  async loadInitialState(): Promise<void> {
    await this.getInitialState();
  }

  //
  // Selectors
  //

  selectInitialState() {
    return this.state.initial;
  }

  selectStaticPageInfo(pageName: StaticPageNames): {| name: string, url: string |} {
    const pageInfo = this.state.staticPageInfos[pageName];
    if (pageInfo) {
      return pageInfo;
    }
    return { name: '-', url: '' };
  }
}

// @flow

import ReactImageGallery from 'react-image-gallery';
import * as React from 'react';
import { useState, useRef } from 'react';

import type { ImageModel } from '../appState/types';
import { useLingui } from '../app/state';

type LocalProps = {|
  items: Array<ImageModel>,
  showThumbnails?: boolean,
  showNav?: boolean,
  disableArrowKeys?: boolean,
  onClick?: Function,
  thumbnailPosition?: string,
  additionalClass?: string,
  enableFullscreen?: boolean,
|};

export default function ImageGallery(props: LocalProps) {
  const [showNav, setShowNav] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);

  const { enableFullscreen = true, thumbnailPosition = 'bottom', ...passProps } = props;
  const galleryRef: any = useRef(null);
  const i18n = useLingui();

  const renderItem = (item) => {
    if (galleryRef.current) {
      return (
        <div
          title={
            isFullScreen ? i18n.t`Click to close full screen` : i18n.t`Click to open full screen`
          }
          onClick={() => galleryRef.current._toggleFullScreen()}
        >
          {galleryRef.current._renderItem(item)}
        </div>
      );
    }
  };

  return (
    <div onMouseEnter={() => setShowNav(true)} onMouseLeave={() => setShowNav(false)}>
      <ReactImageGallery
        ref={galleryRef}
        showPlayButton={false}
        thumbnailPosition={isFullScreen ? 'bottom' : thumbnailPosition}
        onScreenChange={(fullScreenElement) => setFullScreen(!!fullScreenElement)}
        showFullscreenButton={false}
        showNav={showNav}
        renderItem={enableFullscreen && renderItem}
        {...passProps}
      />
    </div>
  );
}

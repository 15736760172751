// @flow

import * as React from 'react';
import { Trans } from '@lingui/react';

import * as baseUI from '../baseUI';

import LoadingDots from '../commonUI/LoadingDots';
import LoadingInline from '../commonUI/LoadingInline';

function renderLoader(isPast) {
  if (isPast('short')) {
    return <LoadingDots />;
  }
  if (isPast('long')) {
    return (
      <baseUI.FlexRow>
        <Trans>Loading</Trans> <LoadingDots />
      </baseUI.FlexRow>
    );
  }
  if (isPast('initial')) {
    return 'Past initial';
  }
  return null;
}

export function Loader() {
  return <LoadingInline renderLoader={renderLoader} />;
}

export default Loader;

// @flow

import * as React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react';
import * as baseUI from '../baseUI';
import { FlexRow, FlexColumn, Block } from '../baseUI';
import {
  HorizontalSpace,
  Badge,
  ThemedText,
  FormatPrice,
  VerticalSpace,
  FormatPhone,
  FormatEmail,
} from '../appUI';
import styled from '@emotion/styled';
import { useMediaQuery } from '../hooks/responsive';
import {
  themeMUI,
  useLoginContainer,
  useMenuContainer,
  useCartContainer,
  useFavoritesContainer,
  useWatchdogContainer,
  useProfileContainer,
  useWebAnalyticsContainer,
} from '../app/state';
import { useGlobalContainer, useEnvironment } from '../app/use';
import CartIcon from '@material-ui/icons/ShoppingCart';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton/';
import { withRouter } from 'react-router-dom';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/';
import { useLingui } from '../app/lingui';
import MyProfileIcon from '@material-ui/icons/Dashboard';
import OrdersIcon from '@material-ui/icons/Description';
import InvoicesIcon from '@material-ui/icons/Receipt';
import AlarmIcon from '@material-ui/icons/Alarm';
import MenuIcon from '@material-ui/icons/Menu';
import UsersIcon from '@material-ui/icons/SupervisorAccount';
// import paths from '../app/paths';
import { TextLink } from '../app/link';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import AppLogo from '../rudorfer/AppLogo.png';
import JadtoolsLogo from '../rudorfer/JadtoolsLogo.svg';
import AlfaboxLogo from '../rudorfer/alfabox/assets/AlfaboxLogo.svg';
import AximaLogo from '../rudorfer/AximaLogo';
import type { ReactRouterProps } from '../appState/types';
import { styledTheme, useTheme } from '../app/theme';
import { cartElementClassName, zIndexes } from '../app/config';
import { AnimateCartChange } from '../cart/AnimateCartChange';
import { LanguageSelect } from './LanguageSelect';
import Tooltip from '@material-ui/core/Tooltip';
import { useDidChange } from '../hooks/useDidChange';
import { useIsSystemPage } from '../app/router';
import { Button } from '../materialUI';
import { Trans } from '@lingui/react';
import { NotificationView } from '../RudorferApp/NotificationView';
import BasicPopover from '../appUI/BasicPopover';
import CartPopup from './CartPopup';

import { makeStyles } from '@material-ui/core/styles';
import { DotLoader } from '../UI/loaders';
import {useIncomakerEvents} from '../pkg.incomaker/hooks';

const HeaderLink = styled(TextLink)`
  text-transform: uppercase;
  line-height: 18px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.1px;
`;

const useStyles = makeStyles((theme) => ({
  button: {
    paddingTop: '0px',
    paddingBottom: '0px',
    '&:hover': {
      background: 'none',
    },
  },
  badge: {
    visibility: 'hidden',
  },
  tooltip: {
    visibility: 'visible',
  },
  cartPrice: {
    fontSize: '1.8rem',
  },
  cartIcon: {
    fontSize: '2.4rem',
  },
  highlight: {
    color: theme.palette.secondary.main,
    '&:hover': {
      filter: 'brightness(90%)',
    },
  },
}));

function CartIconButton(props: { isVisible: boolean, popupHeight: number }) {
  const { isVisible } = props;
  const i18n = useLingui();
  const cart = useCartContainer();
  const cartItems = cart.selectCartItems();
  const globalContainer = useGlobalContainer();
  const webAnalytics = useWebAnalyticsContainer();
  const { incomakerCartRemoveEvent } = useIncomakerEvents();
  const isAlfabox = globalContainer.isSpecificProject('alfabox');

  const itemsCount = cart.selectItemsCount();
  const totalValue = cart.selectTotal();
  const totalLoaded = cart.selectTotalLoaded();
  const { prices } = cart.selectCartDetail();
  const cartChanged = useDidChange(totalValue, { expireIn: 1500, active: totalLoaded });
  const couponChanged = useDidChange(prices.couponsPriceNet, {
    expireIn: 1500,
    active: totalLoaded,
  });
  const isCartPage = useIsSystemPage('cart');
  const isTooltipActive = couponChanged && cartChanged && !isCartPage && isVisible;
  const [showPopover, setShowPopover] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const popoverRef = useRef(null);

  const classes = isAlfabox ? useStyles() : {};

  const onRemove = (cartItem) => {
    if (cartItem && cartItem.productItem) {
      webAnalytics.removeFromCartEvent([cartItem])
      incomakerCartRemoveEvent({itemId: cartItem.productItem.id})
    }
    return cart.removeItem(cartItem);
  };

  const onQuantityChange = (cartItem, val) => {
    return cart.setItemQuantity(cartItem, val);
  };

  const hidePopover = () => {
    if (!openModal) {
      setShowPopover(false);
    }
  };

  return (
    <div ref={popoverRef} onMouseEnter={() => setShowPopover(true)} onMouseLeave={hidePopover}>
      <TextLink to={globalContainer.selectSystemPageUrl('cart')}>
        <Button
          className={`${isVisible ? cartElementClassName : ''} ${classes.button} ${
            classes.highlight
          }`}
          color="primary"
          title={i18n.t`Shopping cart`}
          data-cy="cart-button"
        >
          <AnimateCartChange value={prices.couponsPriceNet}>
            <Badge badgeContent={itemsCount} className={classes.badge}>
              <Tooltip
                title={i18n.t`Cart updated`}
                open={isTooltipActive}
                className={classes.tooltip}
                data-cy="cart-update"
              >
                <CartIcon className={classes.cartIcon} />
              </Tooltip>
            </Badge>
          </AnimateCartChange>
          {!isAlfabox && <HorizontalSpace />}
          <ThemedText textDecoration="none" size="smaller" className={classes.cartPrice}>
            <FormatPrice value={prices.couponsPriceNet} />
          </ThemedText>
        </Button>
      </TextLink>
      {!isAlfabox && (
        <BasicPopover
          isVisible={cartItems.length && showPopover}
          anchorEl={popoverRef.current}
          placement="top-end"
          onClose={hidePopover}
        >
          <Block
            padding={20}
            maxHeight={props.popupHeight}
            overflow="auto"
            maxWidth="95vw"
            width={650}
            boxSizing="border-box"
          >
            <CartPopup
              onRemove={onRemove}
              onQuantityChange={onQuantityChange}
              cartItems={cartItems}
              onToggleModal={setOpenModal}
            />
          </Block>
        </BasicPopover>
      )}
    </div>
  );
}

const UserMenu = withRouter((props: {| withDescriptions?: boolean, ...ReactRouterProps |}) => {
  const { withDescriptions = false, location } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const globalContainer = useGlobalContainer();
  const loginContainer = useLoginContainer();
  const isLogged = loginContainer.isLogged();

  const { first_name, last_name } = useProfileContainer().selectProfile();
  const profileName = [first_name, last_name].join(' ');
  const isProfileNameValid = !!profileName.trim();

  const isSm = useMediaQuery(themeMUI.breakpoints.down('sm'));

  const i18n = useLingui();
  const onSignInPage = () => {
    return (
      location.pathname === globalContainer.selectSystemPageUrl('login') ||
      location.pathname === globalContainer.selectSystemPageUrl('register') ||
      location.pathname === globalContainer.selectSystemPageUrl('reset-password-form-email') ||
      location.pathname === globalContainer.selectSystemPageUrl('reset-password-form-password') ||
      location.pathname === globalContainer.selectSystemPageUrl('set-password-form') ||
      location.pathname === globalContainer.selectSystemPageUrl('register-lite')
    );
  };

  const handleOpenMenu = (event) => {
    if (isLogged) {
      setAnchorEl(event.currentTarget);
    } else {
      if (!location.search.includes('?next=') && !onSignInPage()) {
        const redirectUrl = `${globalContainer.selectSystemPageUrl('login')}?next=${
          location.pathname
        }${location.search}`;
        props.history.push(redirectUrl);
      } else props.history.push(globalContainer.selectSystemPageUrl('login'));
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const renderMenuButton = (props: { onClick: Function, children: *, icon: React.Node }) => {
    const { icon, onClick, ...passProps } = props;

    return (
      <MenuItem
        onClick={() => {
          handleCloseMenu();
          onClick();
        }}
      >
        <FlexRow alignItems="center">
          {icon}
          <HorizontalSpace />
          <ThemedText color="primary" {...passProps} />
        </FlexRow>
      </MenuItem>
    );
  };

  const renderMenuLink = (props: { to: string, children: *, icon: React.Node }) => {
    const onClick = () => {
      handleCloseMenu();
    };

    const { icon, to, children } = props;
    return (
      <MenuItem onClick={onClick}>
        <TextLink to={to}>
          <FlexRow alignItems="center">
            {icon}
            <HorizontalSpace />
            {children}
          </FlexRow>
        </TextLink>
      </MenuItem>
    );
  };

  const renderMenu = () => {
    const loginState = useLoginContainer();
    const globalContainer = useGlobalContainer();

    return (
      <Menu
        id="appbar-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        data-cy="user-menu"
      >
        {renderMenuLink({
          to: globalContainer.selectSystemPageUrl('account'),
          children: i18n.t`My profile`,
          icon: <MyProfileIcon color="primary" />,
        })}
        {globalContainer.allowedTo('read_order') &&
          renderMenuLink({
            to: globalContainer.selectSystemPageUrl('orders'),
            children: i18n.t`Orders`,
            icon: <OrdersIcon color="primary" />,
          })}
        {globalContainer.allowedTo('read_invoice') &&
          renderMenuLink({
            to: globalContainer.selectSystemPageUrl('invoice'),
            children: i18n.t`Invoices`,
            icon: <InvoicesIcon color="primary" />,
          })}
        {globalContainer.allowedTo('read_user') &&
          renderMenuLink({
            to: globalContainer.selectSystemPageUrl('users'),
            children: i18n.t`Users`,
            icon: <UsersIcon color="primary" />,
          })}
        {renderMenuButton({
          onClick: () => {
            loginState.logout();
          },
          children: i18n.t`Log out`,
          icon: <LogoutIcon color="primary" data-cy="logout-icon" />,
        })}
      </Menu>
    );
  };

  return (
    <React.Fragment>
      <Button
        color="primary"
        onClick={handleOpenMenu}
        title={isLogged ? i18n.t`User menu` : i18n.t`Log in`}
      >
        <React.Fragment>
          <AccountCircleIcon data-cy="account-icon" />
          {isLogged && withDescriptions && (
            <React.Fragment>
              <HorizontalSpace />
              {isProfileNameValid ? profileName : <Trans>My account</Trans>}
            </React.Fragment>
          )}
          {isLogged || (
            <span style={{ fontSize: isSm && '0.7rem' }} data-cy="login/registration-button">
              <HorizontalSpace />
              {globalContainer.isSpecificSite('rudorfer.dealer') ? (
                <Trans>Log in</Trans>
              ) : (
                <Trans>Log in / Registration</Trans>
              )}
            </span>
          )}
        </React.Fragment>
      </Button>
      {renderMenu()}
    </React.Fragment>
  );
});

function Icons(props: {|
  withDescriptions?: boolean,
  watchedCount: number,
  favoritesCount: number,
  isVisible: boolean,
  popupHeight: number,
|}) {
  const { withDescriptions = false, watchedCount, favoritesCount } = props;
  const isSm = useMediaQuery(themeMUI.breakpoints.down('sm'));
  const isLogged = useLoginContainer().isLogged();
  const i18n = useLingui();
  const globalContainer = useGlobalContainer();
  return (
    <FlexRow alignItems="center">
      {!globalContainer.isSpecificProject('alfabox') && (
        <UserMenu withDescriptions={withDescriptions} />
      )}
      {isLogged && (
        <React.Fragment>
          {!globalContainer.isSpecificProject('alfabox') &&
            (isSm || (
              <React.Fragment>
                <TextLink to={globalContainer.selectSystemPageUrl('watchdog')}>
                  <Button color="primary" title={i18n.t`Watchdog`}>
                    <Badge badgeContent={watchedCount}>
                      <AlarmIcon />
                    </Badge>
                    {withDescriptions && (
                      <React.Fragment>
                        <HorizontalSpace />
                        <Trans>Watchdog</Trans>
                      </React.Fragment>
                    )}
                  </Button>
                </TextLink>
                <TextLink to={globalContainer.selectSystemPageUrl('favorite')}>
                  <Button color="primary" title={i18n.t`Favorite products`}>
                    <Badge badgeContent={favoritesCount}>
                      <FavoriteIcon />
                    </Badge>
                    {withDescriptions && (
                      <React.Fragment>
                        <HorizontalSpace />
                        <Trans>Favorite</Trans>
                      </React.Fragment>
                    )}
                  </Button>
                </TextLink>
              </React.Fragment>
            ))}
          {globalContainer.allowedTo('create_order') && (
            <CartIconButton popupHeight={props.popupHeight} isVisible={props.isVisible} />
          )}
        </React.Fragment>
      )}
    </FlexRow>
  );
}

const CartHeader = styled.span`
  padding-left: 10px;
  padding-bottom: 0px;
  font-size: 1.45rem;
  text-transform: capitalize;
`;

function AlfaboxCart(props: {| isVisible: boolean, popupHeight: number |}) {
  // const isLogged = useLoginContainer().isLogged();
  //const globalContainer = useGlobalContainer();

  return (
    <React.Fragment>
      {/*{isLogged && (*/}
      <FlexRow alignItems="center">
        {/*{globalContainer.allowedTo('create_order') && (*/}
        <FlexColumn>
          <CartHeader>
            <Trans>Cart</Trans>
          </CartHeader>
          <CartIconButton popupHeight={props.popupHeight} isVisible={props.isVisible} />
        </FlexColumn>
        {/*)}*/}
      </FlexRow>
      {/*)}*/}
    </React.Fragment>
  );
}

const Logo = (props: {| height?: number |}) => {
  const { height = 35 } = props;
  const globalContainer = useGlobalContainer();
  const isXs = useMediaQuery('(max-width: 720px)');

  // NoteTemporary: code to be removed later
  let src;
  if (globalContainer.isSpecificProject('jadtools')) src = JadtoolsLogo;
  else if (globalContainer.isSpecificProject('alfabox')) src = AlfaboxLogo;
  else src = AppLogo;

  const getPad = () => {
    return isXs && globalContainer.isSpecificProject('alfabox')
      ? '10px 0 4px 0'
      : '10px 0 4px 20px';
  };

  return (
    <TextLink to={globalContainer.selectSystemPageUrl('homepage')}>
      <baseUI.InlineBlock padding={getPad()}>
        {globalContainer.isSpecificProject('axima') ? (
          <AximaLogo />
        ) : (
          <img height={height} width="auto" src={src} alt="" data-cy="app-logo" />
        )}
      </baseUI.InlineBlock>
    </TextLink>
  );
};

const AppBar = styled.div`
  box-shadow: ${(props) => (props.isAlfabox ? 'none' : '0 1px 5px 0 rgba(0, 0, 0, 0.3)')};
  position: ${(props) => props.position || 'relative'};
  box-sizing: content-box;
  min-height: 55px;
  background-color: ${styledTheme.color('primaryBg')};
  top: ${(props) => props.top}px;
  width: 100%;
  z-index: ${zIndexes.AppBar};
  transition: 0.3s;
  display: ${(props) => (props.visible ? '' : 'none')};
`;

function DevelopmentBar() {
  const env = useEnvironment();
  return useMemo(() => {
    if (env === 'production') {
      return null;
    }
    return (
      <TextLink to="/dev">
        <Block
          height={8}
          background={
            env === 'test'
              ? 'linear-gradient(90deg, rgb(208, 114, 18) 18%, rgb(255, 236, 9) 100%)'
              : 'linear-gradient(90deg, rgba(9,121,92,1) 18%, rgba(24,222,77,1) 100%)'
          }
        />
      </TextLink>
    );
  }, []);
}

function Notifications(props) {
  const globalContainer = useGlobalContainer();
  const notifications = globalContainer.selectNotifications();
  return notifications.map((notification) => (
    <NotificationView key={notification.type} {...notification} />
  ));
}

function StaticPageLink(props: {| name: * |}) {
  const globalContainer = useGlobalContainer();
  const staticPage = globalContainer.selectStaticPageInfo(props.name);
  return <HeaderLink to={staticPage.url}>{staticPage.name}</HeaderLink>;
}

function HeaderSystemPageLink(props: {| name: *, children: React.Node |}) {
  const globalContainer = useGlobalContainer();
  return (
    <HeaderLink to={globalContainer.selectSystemPageUrl(props.name)}>{props.children}</HeaderLink>
  );
}

type StaticHeaderProps = {| visible: boolean, searchEl: React.Node, popupHeight: number |};

function RudorferNav(props: StaticHeaderProps) {
  const { searchEl, visible, popupHeight } = props;
  const i18n = useLingui();
  const globalContainer = useGlobalContainer();
  const isLg = useMediaQuery(themeMUI.breakpoints.up('lg'));
  const { phoneNumber, email } = globalContainer.selectHeaderInfo();
  const favorites = useFavoritesContainer();
  const watchdog = useWatchdogContainer();
  const watchedCount = watchdog.selectWatchdogsCount();
  const favoritesCount = favorites.selectFavoritesCount();
  const isLogged = useLoginContainer().isLogged();
  const hideSearch = globalContainer.isSpecificSite('rudorfer.dealer') && !isLogged;
  return (
    <React.Fragment>
      <FlexRow alignItems="center" justifyContent="space-between">
        <Logo height={45} />
        <Block marginLeft="auto" />
        <HeaderLink
          to={globalContainer.selectSystemPageUrl('quick-order')}
        >{i18n.t`Quick order`}</HeaderLink>
        <HorizontalSpace size="large" />

        <StaticPageLink name="print-options" />
        <HorizontalSpace size="large" />
        <HeaderLink to={globalContainer.selectSystemPageUrl('articles')}>{i18n.t`News`}</HeaderLink>
        <HorizontalSpace size="large" />
        {!globalContainer.isSpecificSite('rudorfer.dealer') && (
          <>
            <StaticPageLink name="how-to-shop" />
            <HorizontalSpace size="large" />
          </>
        )}
        <StaticPageLink name="contact" />
        <HorizontalSpace size="large" />
        <Block marginLeft="auto">
          <LanguageSelect />
        </Block>
      </FlexRow>
      <VerticalSpace />
      <FlexRow alignItems="center" justifyContent="space-between">
        {globalContainer.isSpecificSite('rudorfer.dealer') ||
        globalContainer.isSpecificSite('rudorfer.eu') ? (
          <FlexRow alignItems="center">
            <EmailIcon color="primary" />
            <HorizontalSpace />
            <FormatEmail style={{ fontSize: '1.3rem' }} value={email} />
          </FlexRow>
        ) : (
          <FlexRow alignItems="center">
            <PhoneIcon color="primary" fontSize="large" />
            <HorizontalSpace />
            <FormatPhone style={{ fontSize: '1.3rem' }} value={phoneNumber} />
          </FlexRow>
        )}
        {hideSearch ? null : searchEl}
        <Icons
          isVisible={visible}
          withDescriptions={isLg}
          favoritesCount={favoritesCount}
          watchedCount={watchedCount}
          popupHeight={popupHeight}
        />
      </FlexRow>
    </React.Fragment>
  );
}

const FlexAllFlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #e6e6e6;
  text-align: center;
  & > a {
    font-weight: 400;
    flex: 1;
    font-size: 1.3rem;
  }
`;

const MarginBottom = styled.div`
  margin-bottom: 2rem;
`;

function AlfaboxNav(props: StaticHeaderProps) {
  const { searchEl, visible, popupHeight } = props;
  const i18n = useLingui();
  const globalContainer = useGlobalContainer();
  const isSm = useMediaQuery(themeMUI.breakpoints.down('sm'));
  const menuContainer = useMenuContainer();
  const topItems = menuContainer.selectMenuItems().filter((el) => el.isSpecial);
  const menuItemsLoaded = menuContainer.selectCategoriesLoaded();

  const getColor = (labels) => {
    if (labels.new) return 'new';
    else if (labels.discount) return 'secondary';
    else return 'primary';
  };

  return (
    <React.Fragment>
      <FlexRow alignItems="flex-end" justifyContent="space-between" paddingRight={isSm ? 0 : '1%'}>
        <Logo height={150} />
        <MarginBottom>{searchEl}</MarginBottom>
        <FlexRow marginBottom="1.8rem">
          <AlfaboxCart isVisible={visible} popupHeight={popupHeight} />
        </FlexRow>
      </FlexRow>
      <VerticalSpace />
      <FlexAllFlexRow>
        {!menuItemsLoaded ? (
          <DotLoader />
        ) : (
          <>
            <HeaderLink
              to={globalContainer.selectSystemPageUrl('homepage')}
            >{i18n.t`Main Page`}</HeaderLink>
            {topItems.map((el, index) => {
              return (
                <React.Fragment key={`top-category-${index}`}>
                  <HeaderLink to={el.categoryUrl}>
                    <ThemedText color={getColor(el.labels)}>{el.title}</ThemedText>
                  </HeaderLink>
                  <HorizontalSpace size="large" />
                </React.Fragment>
              );
            })}
            <HorizontalSpace size="large" />
            <StaticPageLink name="print-options" />
            <HorizontalSpace size="large" />
            <StaticPageLink name="contact" />

            <Block>
              <LanguageSelect />
            </Block>
          </>
        )}
      </FlexAllFlexRow>
    </React.Fragment>
  );
}

const LoginBarContainer = styled.div`
  width: 100%;
  background-color: #bbb;
  color: #000;
`;

const LoginBarAnchors = styled.span`
  display: flex;
  cursor: pointer;
  color: #000;

  &:hover {
    color: #ffe9e9;
  }
`;

const LoginBar = () => {
  const isLogged = useLoginContainer().isLogged();
  const globalContainer = useGlobalContainer();
  const loginState = useLoginContainer();
  const theme = useTheme();

  const onLogout = () => {
    loginState.logout();
  };

  return (
    <LoginBarContainer>
      <FlexRow
        padding={10}
        boxSizing="border-box"
        width={theme.size('maxPageWidth')}
        maxWidth="100%"
        margin="0 auto"
        justifyContent="flex-end"
      >
        {isLogged ? (
          <>
            <TextLink
              to={globalContainer.selectSystemPageUrl('account')}
              style={{ marginRight: '20px' }}
            >
              <LoginBarAnchors>
                <AccountCircleIcon style={{ marginRight: '5px' }} />
                <Trans>My account</Trans>
              </LoginBarAnchors>
            </TextLink>
            <LoginBarAnchors onClick={onLogout}>
              <Trans>Log out</Trans>
            </LoginBarAnchors>
          </>
        ) : (
          <>
            <TextLink
              to={globalContainer.selectSystemPageUrl('register')}
              style={{ marginRight: '20px' }}
            >
              <LoginBarAnchors>
                <Trans>Register</Trans>
              </LoginBarAnchors>
            </TextLink>

            <TextLink to={globalContainer.selectSystemPageUrl('login')}>
              <LoginBarAnchors>
                <Trans>Log in</Trans>
              </LoginBarAnchors>
            </TextLink>
          </>
        )}
      </FlexRow>
    </LoginBarContainer>
  );
};

export const StaticHeader = React.forwardRef<StaticHeaderProps, *>(
  (props: StaticHeaderProps, ref) => {
    const theme = useTheme();
    const globalContainer = useGlobalContainer();
    const { visible } = props;

    return (
      <AppBar
        visible={visible}
        id="static"
        top={0}
        ref={ref}
        isAlfabox={globalContainer.isSpecificProject('alfabox')}
      >
        <React.Fragment>
          <DevelopmentBar />
          <Notifications />
          {globalContainer.isSpecificProject('alfabox') && (
            <LoginBar globalContainer={globalContainer} />
          )}

          <FlexColumn
            padding={10}
            boxSizing="border-box"
            width={theme.size('maxPageWidth')}
            maxWidth="100%"
            margin="0 auto"
          >
            {globalContainer.isSpecificProject('alfabox') ? (
              <AlfaboxNav
                searchEl={props.searchEl}
                visible={visible}
                popupHeight={props.popupHeight}
              />
            ) : (
              <RudorferNav
                searchEl={props.searchEl}
                visible={visible}
                popupHeight={props.popupHeight}
              />
            )}
          </FlexColumn>
        </React.Fragment>
      </AppBar>
    );
  }
);

type StickyHeaderProps = {|
  searchEl: React.Node,
  isVisible: boolean,
  setVisible: (boolean) => void,
  popupHeight: number,
|};

export const StickyHeader = React.forwardRef<StickyHeaderProps, *>(
  (props: StickyHeaderProps, ref) => {
    const isSm = useMediaQuery(themeMUI.breakpoints.down('sm'));
    const isXs = useMediaQuery('(max-width: 720px)');

    const { isVisible, setVisible, popupHeight } = props;

    const isLg = useMediaQuery(themeMUI.breakpoints.up('lg'));
    const i18n = useLingui();
    const MenuContainer = useMenuContainer();
    const theme = useTheme();
    const favorites = useFavoritesContainer();
    const watchdog = useWatchdogContainer();
    const watchedCount = watchdog.selectWatchdogsCount();
    const favoritesCount = favorites.selectFavoritesCount();

    const globalContainer = useGlobalContainer();
    const isAlfabox = globalContainer.isSpecificProject('alfabox');
    const isLogged = useLoginContainer().isLogged();
    const hideSearch = globalContainer.isSpecificSite('rudorfer.dealer') && !isLogged;

    const handleScroll = () => {
      setVisible(window.pageYOffset > 60);
    };

    useEffect(() => {
      if (!isSm) {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }
    }, [isSm]);

    const renderIcons = () => {
      return (
        <Icons
          isVisible={isVisible}
          watchedCount={watchedCount}
          favoritesCount={favoritesCount}
          popupHeight={props.popupHeight}
        />
      );
    };

    // Determines whether appbar is positioned outside of viewport or not
    const topPos = isSm || isVisible ? 0 : -popupHeight;

    // Note: sticky header is always visible for transition effects
    const appBarVisible = true;

    const renderRudorferStickyBar = () => (
      <React.Fragment>
        <DevelopmentBar />
        <Notifications />

        <FlexRow
          padding={10}
          boxSizing="border-box"
          width={theme.size('maxPageWidth')}
          maxWidth="100%"
          margin="0 auto"
          justifyContent="space-between"
          alignItems={isXs || 'center'}
          flexDirection={isXs && 'column'}
        >
          <FlexRow justifyContent="space-between">
            {isSm && (
              <IconButton onClick={() => MenuContainer.toggleExpanded()}>
                <MenuIcon color="primary" />
              </IconButton>
            )}
            <Logo height={30} />
            {isXs && renderIcons()}
          </FlexRow>
          {isLg && (
            <FlexRow alignItems="center">
              <HeaderSystemPageLink name="quick-order">{i18n.t`Quick order`}</HeaderSystemPageLink>
              <HorizontalSpace size="large" />
              <StaticPageLink name="print-options" />
              <HorizontalSpace size="large" />
            </FlexRow>
          )}
          {isVisible && !hideSearch ? props.searchEl : null}
          {isXs || renderIcons()}
        </FlexRow>
      </React.Fragment>
    );

    // const LoginToViewBtn = styled.div`
    //   text-align: center;
    //   padding-top: 15px;
    // `;

    const renderAlfaboxStickyBar = () => (
      <React.Fragment>
        <DevelopmentBar />
        <Notifications />
        <LoginBar globalContainer={globalContainer} />

        {/*<AlfaboxNav*/}
        {/*  searchEl={props.searchEl}*/}
        {/*  visible={props.isVisible}*/}
        {/*  popupHeight={props.popupHeight}*/}
        {/*/>*/}
        <FlexRow
          padding={10}
          boxSizing="border-box"
          width={theme.size('maxPageWidth')}
          maxWidth="100%"
          margin="0 auto"
          justifyContent="space-between"
          alignItems={isXs || 'center'}
          flexDirection={isXs && 'column'}
        >
          <FlexRow justifyContent="space-between">
            {isSm && (
              <IconButton onClick={() => MenuContainer.toggleExpanded()}>
                <MenuIcon color="primary" />
              </IconButton>
            )}
            <Logo height={65} />
            {!isLogged && <HorizontalSpace />}
            {isLogged && isXs && renderIcons()}
          </FlexRow>
          {isVisible ? props.searchEl : null}
          {/*{isLogged && (isXs || renderIcons())}*/}
          {/*Probably temporary*/}
          {!isXs && <AlfaboxCart isVisible={props.isVisible} popupHeight={popupHeight} />}
          {/*{isLogged || (*/}
          {/*  <LoginToViewBtn>*/}
          {/*    <TextLink to={globalContainer.selectSystemPageUrl('login')}>*/}
          {/*      <Trans>Log in / Registration</Trans>*/}
          {/*    </TextLink>*/}
          {/*  </LoginToViewBtn>*/}
          {/*)}*/}
        </FlexRow>
      </React.Fragment>
    );

    return (
      <AppBar
        id="sticky"
        ref={ref}
        visible={appBarVisible}
        position={isSm ? 'sticky' : 'fixed'}
        top={topPos}
      >
        {isAlfabox ? renderAlfaboxStickyBar() : renderRudorferStickyBar()}
      </AppBar>
    );
  }
);

// @flow

import CartIcon from '@material-ui/icons/ShoppingCart';
import IconButton from '@material-ui/core/IconButton/IconButton';
import * as React from 'react';
import { useState } from 'react';
import { FlexRow } from '../baseUI/index';
import AmountInput from '../appUI/AmountInput';
import { useLingui } from '../app/lingui';
import type { ProductItem, ProductDetail, ProductCartItem } from '../appState/types';
import AmountPicker from '../rudorfer/AmountPicker';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { useCartContainer, useWebAnalyticsContainer } from '../app/state';
import { useDebounceQuantity } from '../cart/useDebounceQuantity';
import AddToCartAnimation from '../cart/AddToCartAnimation';
import { cartElementClassName } from '../app/config';
import { PackageIcon } from '../cart/icons';
import { useGlobalContainer } from '../app/use';

import LargeAmountInput from './LargeAmountInput';
import { CartIconButton, AddToCartBtn, CartButton, CartBtn } from './CartBtnComponents';
import {useIncomakerEvents} from '../pkg.incomaker/hooks';
import { useFacebookPixelEvents } from '../pkg.facebookPixel/hooks';


function getCartEl() {
  const list = document.getElementsByClassName(cartElementClassName);
  return list[list.length - 1];
}

export const AddToCartLine = (props: {|
  product: ProductItem | ProductDetail | ProductCartItem,
  showCartButton?: boolean,
  showPickerIcon?: boolean,
  disabled?: boolean,
  quantity?: number,
  onQuantityChange?: Function,
  onToggleModal?: Function,
  isAlfabox?: boolean,
  useIconButton?: boolean,
  variant?: string,
|}) => {
  const {
    product,
    disabled = false,
    showCartButton = true,
    showPickerIcon = true,
    useIconButton = false,
  } = props;
  const { availableNum } = product;
  const { saleType } = product.params;
  const [quantity, setQuantity] = useDebounceQuantity({
    initialValue: props.quantity,
    onChange: props.onQuantityChange,
  });
  const [openAmountModal, setOpenAmountModal] = useState<boolean>(false);
  const { max, isAvailable } = getAvailabilityInfo(availableNum);
  const i18n = useLingui();
  const cart = useCartContainer();
  const globalContainer = useGlobalContainer();
  const webAnalytics = useWebAnalyticsContainer();
  const { incomakerCartAddEvent } = useIncomakerEvents();
  const facebookPixelEvents = useFacebookPixelEvents();

  const isAlfabox = globalContainer.isSpecificProject('alfabox');

  const [animateAdd, setAnimateAdd] = useState(false);

  let step = 1;
  if (saleType === 'package') {
    step = product.params.packageSize;
  } else if (saleType === 'amount') {
    step = product.params.minAmountToOrder;
  }

  const toggleModal = (value: boolean) => {
    if (props.onToggleModal) {
      props.onToggleModal(value);
    }
    setOpenAmountModal(value);
  };

  const handleClick = () => {
    setAnimateAdd(true);
    webAnalytics.addToCartEvent([{ ...product, quantity }]);
    incomakerCartAddEvent({itemId: product.id});
    facebookPixelEvents.addToCart(
      {
          id: product.id,
          name: product.name,
          price: product?.price,
      },
      quantity
    )

    return cart.addItem(product.id, quantity);
  };

  const handleDone = () => {
    setAnimateAdd(false);
  };

  const { packageSize, measureUnit } = product.params;
  const soldByPackages = measureUnit.erp_id === 'BAL';
  const orderAmount = measureUnit.erp_id === 'KS' ? product.params.minAmountToOrder : packageSize;
  const enoughInStock = product.availableNum > orderAmount;
  const enoughInPackage = product.availableNum > packageSize;
  const showPackagePicker = showPickerIcon && enoughInStock && enoughInPackage;

  return globalContainer.allowedTo('create_order') && isAvailable ? (
    <FlexRow width="100%" justifyContent="flex-end" alignItems="center">
      <LargeAmountInput
        onChoose={(quantity) => setQuantity(quantity)}
        max={max}
        product={product}
        value={quantity}
        pieceSale={saleType === 'piece'}
        variant={props.variant}
      />
      <React.Fragment>
        {showPackagePicker && (
          <IconButton
            title={i18n.t`Choose number of packages`}
            onClick={() => toggleModal(!openAmountModal)}
          >
            <PackageIcon />
          </IconButton>
        )}
        <Dialog
          open={openAmountModal}
          onClose={() => toggleModal(false)}
          style={{ overflowY: 'auto' }}
        >
          <AmountPicker
            showCartButton={showCartButton}
            useIconButton={useIconButton}
            handleClick={() => {
              handleClick();
              toggleModal(false);
            }}
            animateAdd={animateAdd}
            getCartEl={getCartEl}
            handleDone={handleDone}
            product={product}
            quantity={quantity}
            onChoose={(quantity) => {
              setQuantity(quantity);
              toggleModal(false);
            }}
            max={max}
            pieceSale={saleType === 'piece'}
          />
        </Dialog>
      </React.Fragment>

      <CartBtn
        showCartButton={showCartButton}
        useIconButton={useIconButton}
        handleClick={handleClick}
        animateAdd={animateAdd}
        getCartEl={getCartEl}
        handleDone={handleDone}
        quantity={quantity}
      />
    </FlexRow>
  ) : null;
};

export function getAvailabilityInfo(
  availableNum: number
): {| min: number, max: number, isAvailable: boolean |} {
  return {
    min: 1,
    max: availableNum,
    isAvailable: (() => {
      return availableNum !== 0;
    })(),
  };
}

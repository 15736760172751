// @flow

import * as React from 'react';
// import { Trans } from '@lingui/react';
import styled from '@emotion/styled';
import { Block, Box } from '../baseUI';
// import { useMediaQuery } from '../hooks/responsive';
// import { themeMUI } from '../app/state';
import { styledTheme } from '../app/theme';
import { getProductLabelColor } from '../app/config';

import { useGlobalContainer } from '../app/use';

const Label = styled.div`
  box-sizing: border-box;
  color: white;
  min-width: 30%;
  border-radius: 0px 8px 8px 0px;
  margin: 0px 0px 3px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: bold;
  width: fit-content;
  background: ${(props) => props.bgColor};
`;

const Corner = styled.div`
  width: 10em;
  height: 10em;
  background-image: url(${(props) => props.bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: -0.8rem;
  left: -1.5rem;
  & > div {
    color: #fff;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    -ms-transform: rotate(-45deg); /* IE 9 */
    transform: rotate(-45deg);
    position: relative;
    top: 1.4rem;
    left: 1.2rem;
  }
`;

export const convertToEnumValue = (code: string) => {
  switch (code) {
    case 'DISCOUNT':
    case 'AKCE':
      return 'discount';
    case 'SALE':
    case 'VYPRODEJ':
      return 'sale';
    case 'NEW':
    case 'NOVINKA':
      return 'new';
    case '2JKST':
      return 'second_quality';
    default:
      return 'sale';
  }
};

function ProductLabel(props: {| labels_test: Array<LabelTest> |}) {
  const globalContainer = useGlobalContainer();
  const { labels_test } = props;

  const fillColor = (label) => {
    const codeToEnum = convertToEnumValue(label.code);
    return label.css_background === '' ? getProductLabelColor(codeToEnum) : label.css_background;
  };

  return (
    <React.Fragment>
      {globalContainer.isSpecificProject('alfabox') ? (
        <Corner bg={''}>
          <Box>{''}</Box>
        </Corner>
      ) : (
        <>
          {labels_test.map((label, i) => {
            return <Label bgColor={fillColor(label)} key={`label.label- + ${i}`}>{label.label}</Label>;
          })}
        </>
      )}
    </React.Fragment>
  );
}

const Labels = styled(Block)`
  display: flex;
  margin: 4px 0 2px 0;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 8px;
`;

type LabelTest = {|
  code: string,
  label: string,
  css_background: string,
|};

export function ProductLabels({ labels_test }: { labels_test: Array<LabelTest> }) {
  return (
    <Labels>
      <Block marginLeft={-14}>
        <ProductLabel labels_test={labels_test} />
      </Block>
    </Labels>
  );
}

const Cirle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 3px;
  top: 3px;
  color: white;
  background-color: ${styledTheme.color('errorText')};
  font-size: 12px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 4px;
  z-index: 10;
`;

export function DiscountCircle(props: {| discount: number |}) {
  return (
    <Cirle key="discount">
      &#8722;&nbsp;
      <Block fontSize={16}>{props.discount || 0}</Block>&nbsp;%
    </Cirle>
  );
}

// @flow

import * as React from 'react';
import { Trans } from '@lingui/react';
import { Helmet } from 'react-helmet';

import { css, Global } from '@emotion/core';
// ui
import * as baseUI from '../baseUI';
import { Block } from '../baseUI';
// app
import { useLoginContainer, useGlobalContainer, useLanguageContainer } from '../app/state';
import { TextLink } from '../app/link';
import GlobalLayout from '../layouts/GlobalLayout';
import AppFooter from '../rudorfer/AppFooter';
import { useTheme } from '../app/theme';
// local
// import type { DynamicPathKeys } from '../app/paths';

// $FlowFixMe
import FaviconRudorfer from './favicon/favicon-rudorfer.ico';
// $FlowFixMe
import FaviconJadtools from './favicon/favicon-jadtools.ico';
// $FlowFixMe
import FaviconAxima from './favicon/favicon-axima.ico';

function GlobalStyles() {
  // We don't want to have much global styles, basically just typography.
  // So we don't have to create a separe CSS files just for a few lines.

  // Its also a quite annoying during development, because the css file is loaded
  // on mount and then it flashes the server-rendered content. DX is sucking.

  // If some component has CSS styles it'll be loaded dynamically via webpack chunks.
  const theme = useTheme();
  return (
    <Global
      styles={css`
        html {
          font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
            'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
          font-weight: normal;
          font-style: normal;
          font-size: 14px;
        }

        body {
          margin: 0;
          padding: 0;
          color: #1b2631;
        }

        html,
        body,
        #root {
          height: 100%;
        }
        .serverMarkup {
          a {
            color: ${theme.color('primary')};
          }
        }
      `}
    />
  );
}

function ResetStyles() {
  // reset lengend by https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
  return (
    <Global
      styles={css`
        legend {
          padding: 0;
          display: table;
        }
        fieldset {
          border: 0;
          padding: 0.01em 0 0 0;
          margin: 0;
          min-width: 0;
        }
        body:not(:-moz-handler-blocked) fieldset {
          display: table-cell;
        }
      `}
    />
  );
}

export function UserBox() {
  const userLogin = useLoginContainer();
  const globalContainer = useGlobalContainer();

  const isLogged = userLogin.isLogged();
  return (
    <baseUI.FlexRow>
      {isLogged || (
        <Block marginRight={20}>
          <TextLink to={globalContainer.selectSystemPageUrl('register')}>
            <Trans>Register</Trans>
          </TextLink>
        </Block>
      )}
      <div>
        {isLogged || (
          <TextLink to={globalContainer.selectSystemPageUrl('login')}>
            <Trans>Log in</Trans>
          </TextLink>
        )}
      </div>
    </baseUI.FlexRow>
  );
}

type LocalProps = {| children: any |};

function GlobalApp(props: LocalProps) {
  const globalContainer = useGlobalContainer();
  const languageContainer = useLanguageContainer();
  const defaultTitle = globalContainer.selectDefaultPageTitle();
  const lang = languageContainer.selectActiveLanguage();
  let favicon = FaviconRudorfer;
  if (globalContainer.isSpecificProject('jadtools')) {
    favicon = FaviconJadtools;
  }
  if (globalContainer.isSpecificProject('axima')) {
    favicon = FaviconAxima;
  }
  const body = (
    <React.Fragment>
      {props.children}
      <GlobalStyles />
      <ResetStyles />
      <Helmet>
        <html lang={lang} />
        <title>{defaultTitle}</title>
        <link href={favicon} rel="shortcut icon" type="image/png" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </Helmet>
    </React.Fragment>
  );

  return <GlobalLayout body={body} footer={<AppFooter />} />;
}

export default GlobalApp;

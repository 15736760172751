// @flow

import React from 'react';
import type { InitialState } from '../appState/types';
import {
    useInitialContainer,
    useGlobalContainer,
    useCartContainer,
    useProfileContainer,
} from '../app/state';

export const FACEBOOK_PIXEL_SOFTWAREMODULE_CODE = 'facebook_pixel'

export function initFacebookPixel(initialData: InitialState) {
    if (typeof window !== 'undefined') {
        import('react-facebook-pixel')
            .then(module => module.default)
            .then(ReactPixel => {
                const options = {
                    autoConfig: true,
                    debug: false,
                };
                let fbPixelId = '';
                initialData.softwaremodules.forEach((softwaremodule) => {
                    if(softwaremodule.code === FACEBOOK_PIXEL_SOFTWAREMODULE_CODE)
                        fbPixelId = softwaremodule.data.pixelId
                })
                ReactPixel.init(fbPixelId, {}, options);
            })
    }
}

export function isFacebookPixelInInitialData(initialData: InitialState) {
    return initialData.softwaremodules.some(
        (softwaremodule) => softwaremodule.code === FACEBOOK_PIXEL_SOFTWAREMODULE_CODE
    );
}

export function useFacebookPixelEvents() {
    const initialContainer = useInitialContainer();
    const globalContainer = useGlobalContainer();
    const cartContainer = useCartContainer();
    const profileContainer = useProfileContainer();
    const profile = profileContainer.selectProfile();

    const cartResource = cartContainer.lazyCartDetail();
    const cartState = cartResource.resource;

    const cartDetail = cartState;

    const [initialState, setInitialState] = React.useState<InitialState | null>(null);

    React.useEffect(() => {
        initialContainer.getInitialState().then((data) => {
            setInitialState(data);
        })
    }, [])

    const softwaremodules = globalContainer.getSoftwaremodules();

    let facebookPixelSoftwaremodule = null;

    if (softwaremodules)
        facebookPixelSoftwaremodule = softwaremodules.find(
            (softwaremodule) => softwaremodule.code === FACEBOOK_PIXEL_SOFTWAREMODULE_CODE
        );

    const isFacebookPixelEnabled = (): boolean => {
        return globalContainer.isSoftwaremoduleActive(FACEBOOK_PIXEL_SOFTWAREMODULE_CODE);
    };

    const addToCart = async (
        product: {id: string, name: string, price: number},
        quantity: number
    ) => {
        if (typeof window !== 'undefined' && isFacebookPixelEnabled() && initialState)
            import('react-facebook-pixel')
                .then(module => module.default)
                .then((ReactPixel) => {
                    ReactPixel.track('AddToCart', {
                        content_ids: [product.id],
                        content_name: product.name,
                        content_type: 'product',
                        contents: [
                            {id: product.id, quantity: quantity}
                        ],
                        currency: initialState.price.currency,
                        value: product?.price || 0,
                    });
                })
    }

    const completeRegistration = async (
        {lastName, firstName}: {lastName: string, firstName: string}
    ) => {
        if (typeof window !== 'undefined' && isFacebookPixelEnabled())
            import('react-facebook-pixel')
                .then(module => module.default)
                .then(ReactPixel => {
                    ReactPixel.track('CompleteRegistration', {})
                });
    }

    const subscribeToNewsletter = async () => {
        if (typeof window !== 'undefined' && isFacebookPixelEnabled())
            import('react-facebook-pixel')
                .then(module => module.default)
                .then(ReactPixel => {
                    ReactPixel.trackCustom('SubscribeToNewsletter', {
                        subscribed: 'yes'
                    })
                });
    }

    const addToFavorite = async (product: {id: string, name: string}) => {
        if (typeof window !== 'undefined' && isFacebookPixelEnabled())
            import('react-facebook-pixel')
                .then(module => module.default)
                .then(ReactPixel => {
                    ReactPixel.track('AddToFavorite', {
                        product_id: product.id,
                        product_name: product.name
                    })
                });
    }

    const startOrder = async (data: {
        productCategory: string,
        productIds: Array<string>,
        products: Array<{id: string, quantity: number}>,
        numItems: number,
        priceAmount: number
    }) => {
        if (typeof window !== 'undefined' && isFacebookPixelEnabled() && initialState)
            import('react-facebook-pixel')
                .then(module => module.default)
                .then(ReactPixel => {
                    ReactPixel.track('InitiateCheckout', {
                        content_category: data.productCategory,
                        content_ids: data.productIds,
                        contents: data.products,
                        currency: initialState.price.currency,
                        num_items: data.numItems,
                        value: data.priceAmount
                    })
                });
    }

    const completeOrder = async (data: {
        contentIds: Array<string>,
        contentName: Array<string>,
        contents: Array<{id: string, quantity: number}>,
        numItems: number,
        value: number
    }) => {
        if (typeof window !== 'undefined' && isFacebookPixelEnabled() && initialState)
            import('react-facebook-pixel')
                .then(module => module.default)
                .then(ReactPixel => {
                    ReactPixel.track('Purchase', {
                        content_ids: data.contentIds,
                        content_name: data.contentName,
                        content_type: 'product',
                        contents: data.contents,
                        currency: initialState.price.currency,
                        num_items: data.numItems,
                        value: data.value
                    })
                });
    }

    const changeDeliveryMethod = async (deliveryMethod: {
        id: string,
        name: string,
        code: string
    }) => {
        if (typeof window !== 'undefined' && isFacebookPixelEnabled())
            import('react-facebook-pixel')
                .then(module => module.default)
                .then(ReactPixel => {
                    ReactPixel.track('ChangeDeliveryMethod', {
                        id: deliveryMethod.id,
                        name: deliveryMethod.name,
                        code: deliveryMethod.code
                    })
                });
    }

    const changePaymentMethod = async (paymentMethod: {
        id: string,
        name: string,
        code: string
    }) => {
        if (typeof window !== 'undefined' && isFacebookPixelEnabled())
            import('react-facebook-pixel')
                .then(module => module.default)
                .then(ReactPixel => {
                    ReactPixel.track('ChangePaymentMethod', {
                        id: paymentMethod.id,
                        name: paymentMethod.name,
                        code: paymentMethod.code
                    })
                });
    }

    return {
        addToCart,
        completeRegistration,
        subscribeToNewsletter,
        addToFavorite,
        startOrder,
        completeOrder,
        changePaymentMethod,
        changeDeliveryMethod,
    };
}

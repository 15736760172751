// @flow

import useMediaQueryMUI from '@material-ui/core/useMediaQuery';

import { useMuiTheme } from '../AppRoot/OurMuiThemeContext';

export function useMediaQuery(query: string) {
  const theme = useMuiTheme();
  const fixedQuery = query.replace('@media ', '');
  const { matches } = theme.props.MuiUseMediaQuery.ssrMatchMedia(fixedQuery);
  return useMediaQueryMUI(fixedQuery, { defaultMatches: matches });
}

export function useMediaDown(breakpoint: 'xs' | 'sm' | 'md' | 'lg') {
  const theme = useMuiTheme();
  return useMediaQuery(theme.breakpoints.down(breakpoint));
}

// @flow

import { Container } from '@whys/app/lib/state';

import type {
  ProductItem,
  ProductCartItem,
  StaticPageUrls,
  SystemPageUrls,
  PaymentOption,
  ShippingOption,
  ProductDetail
} from '../appState/types';
import type { CartItem } from '../whyshop/models/cart';
import type { CartState } from '../appState/types';
import {gtag} from '../AppRoot/gtm';
import {CartContainer} from '../whyshop/CartContainer';
import type {CategoryDetailInfo} from '../whyshop/types';
import type {CampaignOverviewModel} from './HomepageContainer';

const GoogleEvent = Object.freeze({
  AddToCart: 'add_to_cart',
  Login: 'login',
  Search: 'search',
  SignUp: 'sign_up',
  AddPaymentInfo: 'add_payment_info',
  AddShippingInfo: 'add_shipping_info',
  AddToWishlist: 'add_to_wishlist',
  AddToFavorite: 'add_to_favorite',
  BeginCheckout: 'begin_checkout',
  GenerateLead: 'generate_lead',
  Purchase: 'purchase',
  RemoveFromCart: 'remove_from_cart',
  SelectItem: 'select_item',
  SelectPromotion: 'select_promotion',
  ViewCart: 'view_cart',
  ViewItem: 'view_item',
  ViewItemList: 'view_item_list',
  ViewPromotion: 'view_promotion'
});

type PageName = 'homepage' | 'variant' | 'category' | 'search' | 'contact' | 'cart' | 'orders';

type GooglePage =
  | 'homepage'
  | 'product detail'
  | 'category'
  | 'searchresults'
  | 'info'
  | 'cart'
  | 'purchase'
  | 'other';

type LocalProps = {
  currency: string,
  staticPages: StaticPageUrls,
  systemPages: SystemPageUrls,
  status: 'logged' | 'anonymous',
  lang: string,
  userID: string,
  affiliation: string,
};

type LocalState = {
  brand: string,
  currency: string,
  affiliation: string,
};

const DEFAULT_BRAND: string = 'Rudorfer';

export class WebAnalyticsContainer extends Container<LocalState> {
  props: LocalProps;
  state: LocalState;

  constructor(props: LocalProps) {
    super();
    this.props = props;
    this.state = {
      brand: DEFAULT_BRAND,
      currency: props.currency,
      affiliation: props.affiliation
    };
  }

  static convertToGooglePage = (ourPageName: PageName): GooglePage => {
    switch (ourPageName) {
      case 'homepage':
        return 'homepage';
      case 'variant':
        return 'product detail';
      case 'category':
        return 'category';
      case 'search':
        return 'searchresults';
      case 'contact':
        return 'info';
      case 'cart':
        return 'cart';
      case 'orders':
        return 'purchase';

      default:
        return 'other'; // * when google doesn't have our kind of page
    }
  };

  sendDIGeneral = (pagetype: GooglePage) => {
    const { lang, status, userID } = this.props;

    let payload = {
      lang,
      pagetype,
      ...(status === 'logged' ? { status, userID } : { status }),
    };

    window.dataLayer.push({ event: 'Dl.ready.general', ...payload });
  };

  signUpEvent = (method: string = 'Email and Password') => {
    this.sendEvent(GoogleEvent.SignUp, { method: method });
  }

  loginEvent = (method: string = 'Email and Password') => {
    this.sendEvent(GoogleEvent.Login, { method: method });
  }
  
  searchEvent = (searchTerm: string) => {
    this.sendEvent(GoogleEvent.Search, { search_term: searchTerm });
  }

  removeFromCartEvent = (cartItems: CartItem[]) => {
    const mappedProducts = cartItems.map((product) => {
      return {
        item_id: product.productItem.id,
        item_name: product.productItem.name,
        affiliation: this.state.affiliation,
        discount: product.productItem.originalPrice - product.productItem.price,
        item_category: product.productItem.params.collectionTitle,
        item_variant: product.productItem.params.insideColor,
        price: product.productItem.price,
        quantity: product.quantity,
      }
    });

    const totalPrice = cartItems.reduce((acc, product) => acc + product.prices.totalDiscountNet, 0);

    const eventParams = {
      currency: this.state.currency,
      value: totalPrice,
      items: mappedProducts
    };

    this.sendEvent(GoogleEvent.RemoveFromCart, eventParams);
  }

  beginCheckoutEvent = (cartContainer: CartContainer) => {
    const mappedProducts = cartContainer.state.items.map((product, index) => {
      return {
          item_id: product.productItem.id,
          item_name: product.productItem.name,
          affiliation: this.state.affiliation,
          discount: product.productItem.originalPrice - product.productItem.price,
          index: index,
          item_category: product.productItem.params.collectionTitle,
          item_variant: product.productItem.params.insideColor,
          price: product.productItem.price,
          quantity: product.quantity,
        }
    });

    const eventParams = {
      currency: this.state.currency,
      value: cartContainer.state.prices.discountPriceGross,
      items: mappedProducts
    };

    this.sendEvent(GoogleEvent.BeginCheckout, eventParams);
  }

  viewItemEvent = (products: ProductDetail[]) => {
    const mappedProducts = products.map((product) => {
      return {
          item_id: product.id,
          item_name: product.name,
          affiliation: this.state.affiliation,
          discount: product.originalPrice - product.price,
          item_category: product.params.collectionTitle,
          item_variant: product.params.insideColor,
          price: product.price,
        }
    });

    const totalPrice = products.reduce((acc, product) => acc + product.price, 0);

    const eventParams = {
      currency: this.state.currency,
      value: totalPrice,
      items: mappedProducts
    };

    this.sendEvent(GoogleEvent.ViewItem, eventParams)
  };

  viewCartEvent = (products: CartItem[]) => {
    const mappedProducts = products.map((product, index) => {
      return {
          item_id: product.productItem.id,
          item_name: product.productItem.name,
          affiliation: this.state.affiliation,
          discount: product.productItem.originalPrice - product.productItem.price,
          index: index,
          item_category: product.productItem.params.collectionTitle,
          item_variant: product.productItem.params.insideColor,
          price: product.productItem.price,
          quantity: product.quantity,
        }
    });

    const totalPrice = products.reduce((acc, product) => acc + product.productItem.price * product.quantity, 0);

    const eventParams = {
      currency: this.state.currency,
      value: totalPrice,
      items: mappedProducts
    };

    this.sendEvent(GoogleEvent.ViewCart, eventParams);
  }

  // @todo: intended for bestsellers, but wasnt implemented.
  viewPromotionEvent = ({
    promotion,
    product,
    index = null
    }: {
      promotion: {creative_name: string, creative_slot: string, promotion_id: string, promotion_name: string},
      product: ProductItem,
      index: number | null
  }) => {
    const mappedProduct: any = {
      item_id: product.id,
      item_name: product.name,
      affiliation: this.state.affiliation,
      discount: product.originalPrice - product.price,
      item_category: product.params.collectionTitle,
      item_variant: product.params.insideColor,
      price: product.price,
    };

    if(index !== null)
      mappedProduct['index'] = index;

    const eventParams = {
      creative_name: promotion.creative_name,
      creative_slot: promotion.creative_slot,
      promotion_id: promotion.promotion_id,
      promotion_name: promotion.promotion_name,
      items: [mappedProduct]
    };

    this.sendEvent(GoogleEvent.ViewPromotion, eventParams);
  }

  viewItemListEvent = (
    {categoryInfo, products, page = null, pageSize = null}:
    {categoryInfo: CategoryDetailInfo, products: ProductItem[], page: number | null, pageSize: number | null}
  ) => {
    const mappedProducts = products.map((product, index) => {
      const mappedProduct = {
        item_id: product.id,
        item_name: product.name,
        affiliation: this.state.affiliation,
        discount: product.originalPrice - product.price,
        index: index,
        item_category: categoryInfo.title,
        item_list_id: categoryInfo.id,
        item_list_name: categoryInfo.title,
        item_variant: product.params.insideMaterial,
        price: product.price
      };

      if(page !== null && pageSize !== null)
        mappedProduct['index'] = (page - 1) * pageSize + index;

      return mappedProduct;
    });

    const eventParams = {
      item_list_id: categoryInfo.id,
      item_list_name: categoryInfo.title,
      items: mappedProducts
    };

    this.sendEvent(GoogleEvent.ViewItemList, eventParams);
  }

  selectPromotionEvent = (promotion: CampaignOverviewModel) => {
    const eventParams = {
      creative_name: "Homepage Top Promotions",
      creative_slot: "homepage_top_promotions",
      promotion_id: promotion.id,
      promotion_name: promotion.title,
    };

    this.sendEvent(GoogleEvent.SelectPromotion, eventParams);
  }
  
  addToFavoriteEvent = (product: ProductDetail) => {
    const eventParams = {
      event_name: 'add_to_favorite',
      item_id: product.id,
      item_name: product.name,
      item_category: product.params.collectionTitle,
      item_variant: product.params.insideColor,
      item_price: product.price,
    };

    this.sendEvent(GoogleEvent.AddToFavorite, eventParams);
  }
  
  addToCartEvent = (products: ProductCartItem[]) => {
    const mappedProducts = products.map((product) => {
      return {
          item_id: product.id,
          item_name: product.name,
          affiliation: this.state.affiliation,
          discount: product.originalPrice - product.price,
          item_category: product.params.collectionTitle,
          item_variant: product.params.insideColor,
          price: product.price,
          quantity: product.quantity,
        }
    });

    const totalPrice = products.reduce((acc, product) => acc + product.price * product.quantity, 0);

    const eventParams = {
      currency: this.state.currency,
      value: totalPrice,
      items: mappedProducts
    };

    this.sendEvent(GoogleEvent.AddToCart, eventParams);
  };

  addPaymentInfoEvent = ({cartItems, paymentMethod}: {
    cartItems: CartItem[],
    paymentMethod: PaymentOption
  }) => {
    const mappedProducts = cartItems.map((product, index) => {
      return {
        item_id: product.productItem.id,
        item_name: product.productItem.name,
        affiliation: this.state.affiliation,
        index: index,
        discount: product.productItem.originalPrice - product.productItem.price,
        item_category: product.productItem.params.collectionTitle,
        item_variant: product.productItem.params.insideColor,
        price: product.productItem.price,
        quantity: product.quantity,
      }
    });

    const eventParams = {
      currency: this.state.currency,
      value: paymentMethod.price,
      payment_type: paymentMethod.name,
      items: mappedProducts
    };

    this.sendEvent(GoogleEvent.AddPaymentInfo, eventParams);
  }

  addShippingInfoEvent = ({cartItems, shippingMethod}: {
    cartItems: CartItem[],
    shippingMethod: ShippingOption
  }) => {
    const mappedProducts = cartItems.map((product, index) => {
      return {
        item_id: product.productItem.id,
        item_name: product.productItem.name,
        affiliation: this.state.affiliation,
        index: index,
        discount: product.productItem.originalPrice - product.productItem.price,
        item_category: product.productItem.params.collectionTitle,
        item_variant: product.productItem.params.insideColor,
        price: product.productItem.price,
        quantity: product.quantity,
      }
    });

    const eventParams = {
      currency: this.state.currency,
      value: shippingMethod.price,
      shipping_tier: shippingMethod.name,
      items: mappedProducts
    };

    this.sendEvent(GoogleEvent.AddShippingInfo, eventParams);
  }


  purchaseEvent = (
    {cartItems, cartState, orderResult}: {
      cartItems: CartItem[],
      cartState: CartState,
      orderResult: any
    }
  ) => {
    const mappedProducts = cartItems.map((product, index) => {
      return {
        item_id: product.productItem.id,
        item_name: product.productItem.name,
        affiliation: this.state.affiliation,
        index: index,
        discount: product.productItem.originalPrice - product.productItem.price,
        item_category: product.productItem.params.collectionTitle,
        item_variant: product.productItem.params.insideColor,
        price: product.productItem.price,
        quantity: product.quantity,
      }
    });

    const eventParams = {
      transaction_id: orderResult.id,
      value: orderResult.prices.total_cart_discount_price_gross,
      tax: orderResult.prices.total_discount_vat,
      shipping: orderResult.shipping_method.price,
      currency: this.state.currency,
      items: mappedProducts
    };

    this.sendEvent(GoogleEvent.Purchase, eventParams);
  };

  sendEvent = (type: string, ...args: any) => {
    gtag('event', type, ...args);
  };

  microCookie = () => {
    const payload = {
      event: 'gaevent',
      eventCategory: 'cookie',
      eventAction: 'agree',
      eventLabel: window.location.pathname,
    };

    window.dataLayer.push(payload);
  };

  microNewsletter = () => {
    const payload = {
      event: 'lead',
      leadtype: 'newsletter',
    };

    window.dataLayer.push(payload);
  };

  microContact = () => {
    const payload = {
      event: 'lead',
      leadtype: 'dotaz_form',
    };

    window.dataLayer.push(payload);
  };
}

// @flow

import * as React from 'react';

import styled from '@emotion/styled';
import slugify from 'slugify';

import type { FilterInfoPack } from '../appState/types';

import { Block, FlexRow } from '../baseUI';
import { VerticalSpace, ThemedText, BlockLink, HorizontalSpace } from '../appUI';

import HorizontalSlider from '../appUI/HorizontalSlider';

import { FilterInfoContext } from './FilterInfoContext';
import { mapFiltersFromQueryParams } from './mapFiltersFromQueryParams';
import { useLingui } from '../app/state';

import { styledTheme } from '../app/theme';
import { getImagePlaceholder } from './getImagePlaceholder';

const Image = styled.img`
  max-width: 150px;
  width: 100%;
  height: auto;
`;

const ShowAllFilterImage = styled.div`
  width: 150px;
  height: 150px;
  margin: 0 auto;
  background-color: ${styledTheme.color('primary')};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterBlock = styled(Block)`
  position: relative;
  cursor: pointer;
  text-align: center;
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
  &::after {
    content: '';
    display: ${(props) => (props.clicked ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${styledTheme.color('primary')};
    border-radius: 5px;
    z-index: -1;
  }
`;

const SubCategory = (props: {|
  url: string,
  title: string,
  imageSrc?: string,
  isFilterGallery?: boolean,
|}) => {
  const { url, title, imageSrc, isFilterGallery } = props;
  const filterInfo = React.useContext(FilterInfoContext);
  const [clicked, setClicked] = React.useState(false);
  // find out if current one is the "SHOW ALL" filter
  const checkIfShowAll = SHOWALLFILTER === url;
  // get all filters
  const filters = filterInfo ? filterInfo.filters : [];
  // get id of filter with type 'img-gallery'
  const imgFilter = filters.find((el) => el.filterType === 'img-gallery');
  const filterId = imgFilter ? imgFilter.id : '';
  const imgPlaceholder = getImagePlaceholder();

  React.useEffect(() => {
    // each time filterInfo changes call setClicked hook for reset
    if (filterInfo && filterInfo.activeFilters)
      setClicked(filterInfo.activeFilters.attributeIds.includes(url));
  }, [filterInfo]);

  const onFiltersChange = (filterValues?: *) => {
    const mappedFilterValues = mapFiltersFromQueryParams(filterInfo);
    let filterNames = filterValues?.filterParams ? filterValues?.filterParams : {};
    delete filterValues?.filterParams;
    const attributeIds = Object.values(filterValues || {})
      .reduce((acc, val) => acc.concat(val), [])
      .map(String);

    const foundFilter = filterInfo.filters.find((param) => param.filterType === 'img-gallery');
    let label = '';
    attributeIds.forEach((attrId) => {
      foundFilter.imgGalleryOptions.forEach((opt) => {
        if (attrId === opt.value) {
          label = slugify(opt.label, { lower: true, remove: /[*+~.()'"!:@]/g });
        }
      });
    });
    filterNames = { ...filterNames, kategorie: label };

    const change = {
      attributeIds,
      filterNames: filterNames,
      namedFilters: { ...mappedFilterValues.intervalValues },
    };
    filterInfo.onChange(change);
  };

  const onChange = (filterId: string) => (value: Array<string>) => {
    const mappedFilterValues = mapFiltersFromQueryParams(filterInfo);
    const filterValues = { ...mappedFilterValues.filterValues, [filterId]: value };
    onFiltersChange(filterValues);
  };

  const onUnset = (filterId: string) => () => {
    const mappedFilterValues = mapFiltersFromQueryParams(filterInfo);
    let filterValues = { ...mappedFilterValues.filterValues };
    delete filterValues[filterId];
    onFiltersChange(filterValues);
  };

  const renderInnerPart = () => {
    return (
      <React.Fragment>
        <VerticalSpace size="small" />
        {!checkIfShowAll ? (
          <Image title={title} src={imageSrc || imgPlaceholder} />
        ) : (
          <ShowAllFilterImage>
            <ThemedText size="bigger">{title}</ThemedText>
          </ShowAllFilterImage>
        )}
        <VerticalSpace />
        <ThemedText size="bigger" color="primary" style={{ color: clicked && '#fff' }}>
          {!checkIfShowAll ? title : ''}
        </ThemedText>
        <VerticalSpace size="small" />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {isFilterGallery ? (
        <div
          onClick={() => {
            if (checkIfShowAll) return onUnset(filterId)();
            setClicked(!clicked);
            return clicked ? onUnset(filterId)() : onChange(filterId)([url]);
          }}
        >
          <FilterBlock clicked={clicked}>{renderInnerPart()}</FilterBlock>
        </div>
      ) : (
        <BlockLink to={url}>{renderInnerPart()}</BlockLink>
      )}
    </React.Fragment>
  );
};

type LocalProps = {|
  showAll: boolean,
  items: *,
  perSlideCount: number,
  isFilterGallery?: boolean,
|};

// global variable for checking if current Image is 'SHOW ALL' filter
const SHOWALLFILTER = 'show all filters';

function CategorySlider(props: LocalProps) {
  const { showAll, items, perSlideCount, isFilterGallery } = props;
  const i18n = useLingui();

  const renderSlide = (items: *, index: number) => {
    return (
      <Block
        display="grid"
        gridTemplateColumns={`repeat(${perSlideCount}, 150px)`}
        gridGap="1em"
        overflow="hidden"
      >
        {items.map((item, index) => (
          <SubCategory key={index} {...item} isFilterGallery={isFilterGallery} />
        ))}
      </Block>
    );
  };

  return (
    <React.Fragment>
      {showAll ? (
        <FlexRow flexWrap="wrap" justifyContent="space-between">
          {items.map((item, index) => (
            <Block key={index} marginBottom={10}>
              <SubCategory {...item} isFilterGallery={isFilterGallery} />
              <HorizontalSpace />
            </Block>
          ))}
        </FlexRow>
      ) : (
        <HorizontalSlider
          variant={'normal'}
          items={
            isFilterGallery
              ? [
                  {
                    // artificially created object for 'SHOW ALL' filter image
                    url: SHOWALLFILTER,
                    title: `${i18n.t`Cancel filter`}`,
                    imageSrc: SHOWALLFILTER,
                  },
                  ...items,
                ]
              : items
          }
          perSlideCount={perSlideCount}
          renderSlide={renderSlide}
          doAnimateHeight={isFilterGallery && items.length + 1 > perSlideCount}
        />
      )}
    </React.Fragment>
  );
}

export default CategorySlider;

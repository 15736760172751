// @flow

import Cookies from 'js-cookie';
import {
  useGlobalContainer,
  useCartContainer,
  useProfileContainer,
} from '../app/state';

export function useIncomakerEvents() {
  const globalContainer = useGlobalContainer();
  const cartContainer = useCartContainer();
  const profileContainer = useProfileContainer();
  const profile = profileContainer.selectProfile();


  const cartResource = cartContainer.lazyCartDetail();
  const cartState = cartResource.resource;

  const cartDetail = cartState;

  const softwaremodules = globalContainer.getSoftwaremodules();

  let incomakerSoftwaremodule = null;

  if (softwaremodules)
    incomakerSoftwaremodule = softwaremodules.find(
      (softwaremodule) => softwaremodule.code === 'incomaker'
    );

  let baseUrl = `https://api.incomaker.com/commons/v3/event/`;
  if (incomakerSoftwaremodule)
    baseUrl = `https://api.incomaker.com/commons/v3/event/${incomakerSoftwaremodule?.data.apiKey}`;

  //const baseUrl = `https://testa.incomaker.com/commons/v3/event/${incomakerSoftwaremodule?.data.apiKey}`

  const permId = Cookies.get('incomaker_p');
  const campaignId = Cookies.get('incomaker_c');

  const isIncomakerEnabled = (): boolean => {
    return globalContainer.isSoftwaremoduleActive('incomaker');
  };

  // I didn't use fetchJson because it doesn't work with PUT method
  const incomakerCartAddEvent = async ({ itemId }: { itemId: string }) => {
    if (!isIncomakerEnabled()) return;

    await fetch(baseUrl, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        name: 'cart_add',
        permId: permId,
        sessionId: String(cartDetail?.id),
        content: {
          relatedId: String(itemId),
        },
        contact: {
          clientContactId: String(profile.id),
          firstName: profile.first_name,
          lastName: profile.last_name,
          email: profile.email,
        },
      }),
    });
  };

  const incomakerCartRemoveEvent = async ({ itemId }: { itemId: string }) => {
    if (!isIncomakerEnabled()) return;

    await fetch(baseUrl, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        name: 'cart_remove',
        permId: permId,
        sessionId: String(cartDetail?.id),
        // todo: profile.id or profile.customer_id?
        content: {
          relatedId: String(itemId),
        },
        contact: {
          clientContactId: String(profile.id),
          firstName: profile.first_name,
          lastName: profile.last_name,
          email: profile.email,
        },
      }),
    });
  };

  const incomakerLoginEvent = async ({ userId }: { userId: number }) => {
    if (!isIncomakerEnabled()) return;

    await fetch(baseUrl, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        name: 'login',
        permId: permId,
        contact: {
          clientContactId: String(userId),
        },
      }),
    });
  };

  // implemented on backend
  const incomakerOrderAddEvent = async ({ orderId }: { orderId: string }) => {
    if (!isIncomakerEnabled()) return;

    const params = {
      name: 'order_add',
      permId: permId,
      sessionId: String(cartDetail?.id),
      relatedId: String(orderId),
      campaignId: campaignId,
    }

    await fetch(baseUrl, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(params),
    });
  };

  const incomakerOrderFinishEvent = async ({ orderId }: { orderId: string }) => {
    if (!isIncomakerEnabled()) return;

    await fetch(baseUrl, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        name: 'order_finish',
        permId: permId,
        sessionId: String(cartDetail?.id),
        relatedId: String(orderId),
      }),
    });
  };

  return {
    incomakerCartAddEvent,
    incomakerCartRemoveEvent,
    incomakerLoginEvent,
    // implemented on backend
    incomakerOrderAddEvent,
    incomakerOrderFinishEvent,
  };
}

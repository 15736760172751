import React from 'react';

import Button from '@material-ui/core/Button/Button';
import { Trans } from '@lingui/react';
import { HorizontalSpace } from '../appUI';

import { styledTheme } from '../app/theme';
import styled from '@emotion/styled';
import { useLingui } from '../app/state';
import { useGlobalContainer } from '../app/use';
import { IconButton } from '@material-ui/core';

import CartIcon from '@material-ui/icons/ShoppingCart';
import AddToCartAnimation from './AddToCartAnimation';

export const CartIconButton = styled(Button)`
  /* this syntax bumps up specificity by repeating the class name */
  && {
    height: 27px;
    box-shadow: none;
    text-transform: none;
    padding: 4px;
    margin-left: 15px;
    border: none;
    line-height: 1.5;
    color: ${styledTheme.color('secondary')};
    min-width: 32px;
    background-color: ${styledTheme.color('primaryBg')};
    transition: all 0.5s ease;
    &:hover {
      background-color: ${styledTheme.color('primaryBg')};
      color: ${styledTheme.color('secondaryDark')};
      box-shadow: none;
    }
    &:active {
      color: ${styledTheme.color('primaryBg')};
      background-color: ${styledTheme.color('secondaryDark')};
      box-shadow: 0 0 0 0.2rem #fc5c4e90;
    }
    &:focus {
      box-shadow: none;
    }
    & svg {
      transition: all 0.5s ease;
      font-size: 1.4rem;
    }
    &:hover svg {
      font-size: 1.6rem;
    }
  }
`;

export const CartButton = styled(Button)`
  && {
    box-shadow: none;
    text-transform: none;
    font-size: 16px;
    padding: 3px 24px;
    border: 1px solid;
    line-height: 1.2;
    margin-left: 10px;
    width: 100%;
    color: #fff;
    background-color: ${styledTheme.color('secondary')};
    &:hover {
      background-color: ${styledTheme.color('secondaryDark')};
      box-shadow: none;
    }
    &:active {
      box-shadow: none;
      background-color: ${styledTheme.color('secondaryDark')};
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem #fc5c4e90;
    }
    &:disabled {
      background-color: #999;
      border: 1px solid transparent;
    }
  }
`;

export const AddToCartBtn = (props: {| disabled?: boolean, onClick: () => Promise<boolean> |}) => {
  const i18n = useLingui();
  const isAlfabox = useGlobalContainer().isSpecificProject('alfabox');
  return (
    <React.Fragment>
      {isAlfabox ? (
        <React.Fragment>
          <HorizontalSpace />
          <CartButton onClick={props.onClick} disabled={props.disabled} title={i18n.t`Add to cart`}>
            <Trans>Add To Cart</Trans>
          </CartButton>
        </React.Fragment>
      ) : (
        <IconButton
          onClick={props.onClick}
          disabled={props.disabled}
          color="primary"
          title={i18n.t`Add to cart`}
          data-cy="add-item-icon"
        >
          <CartIcon />
        </IconButton>
      )}
    </React.Fragment>
  );
};

export const CartBtn = (props: {|
  showCartButton: boolean,
  animateAdd: boolean,
  useIconButton: boolean,
  handleClick: Function,
  quantity: number,
  getCartEl: Function,
  handleDone: Function,
|}) => {
  return (
    <React.Fragment>
      {props.showCartButton && (
        <AddToCartAnimation
          showAnimation={props.animateAdd}
          getCartEl={props.getCartEl}
          onDone={props.handleDone}
        >
          {props.useIconButton ? (
            <CartIconButton onClick={props.handleClick} disabled={props.quantity === 0}>
              <CartIcon />
            </CartIconButton>
          ) : (
            <AddToCartBtn
              onClick={props.handleClick}
              disabled={props.quantity === 0}
              data-cy="add-item-icon"
            />
          )}
        </AddToCartAnimation>
      )}
    </React.Fragment>
  );
};

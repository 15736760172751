// @flow

import * as React from 'react';
import { useCallback } from 'react';
import { useTransition, animated } from 'react-spring';

import * as baseUI from '../baseUI';

import ProductCard from './ProductCard';
import type { LayoutVariantEnum, ProductItem } from '../appState/types';
import { Grid } from '../baseUI/emotion';

import { useFavoritesContainer } from '../app/state';
import { useIsSystemPage } from '../app/router';

import styled from '@emotion/styled';
import { useGlobalContainer } from '../app/state';

const AlfaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: 1fr;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
`;

type LocalProps = {|
  variant: LayoutVariantEnum,
  products: Array<ProductItem>,
|};

// const listVariantToHeight = {
//   row: 226,
//   grid: 513,
// };

const ProductCardMemo = React.memo(ProductCard);

function ProductList(props: LocalProps) {
  const { products, variant } = props;

  const favoritesContainer = useFavoritesContainer();

  const globalContainer = useGlobalContainer();

  // const height = listVariantToHeight[variant];
  const transitions = useTransition(products, (item) => item.id, {
    // initial: { transform: 'translate3d(0%, 0%,0)' },
    // from: { transform: 'translate3d(0%,-100%,0)' },
    // enter: { transform: 'translate3d(0%, 0%,0)' },
    // leave: { transform: 'translate3d(100%,0%,0)' },
    // initial: { height: 222 },
    from: { opacity: 1 },
    // enter: { height: 222 },
    leave: { opacity: 0 },
  });

  const isFavoritesPage = useIsSystemPage('favorite');

  const onFavoriteChange = useCallback(
    async (product, favorite) => {
      const result = await favoritesContainer.alterIsFavorite(product, favorite);
      if (isFavoritesPage) {
        await favoritesContainer.reloadFavoriteProducts();
      }
      return result;
    },
    [favoritesContainer, isFavoritesPage]
  );

  const productsRendered = transitions.map(({ item: product, props, key }) => {
    const isFavorite = favoritesContainer.isFavorite(product);
    return (
      <animated.div key={key} style={props}>
        <ProductCardMemo
          variant={variant}
          product={product}
          isFavorite={isFavorite}
          onFavoriteChange={onFavoriteChange}
        />
      </animated.div>
    );
  });

  if (variant === 'row') {
    return <baseUI.FlexColumn>{productsRendered}</baseUI.FlexColumn>;
  }

  return (
    <React.Fragment>
      {globalContainer.isSpecificProject('alfabox') ? (
        <AlfaGrid>{productsRendered}</AlfaGrid>
      ) : (
        <Grid
          className="product-list"
          gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
          gridGap="1em"
        >
          {productsRendered}
        </Grid>
      )}
    </React.Fragment>
  );
}

export default ProductList;

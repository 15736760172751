// @flow

import * as React from 'react';
import anime from 'animejs';

import { zIndexes } from '../app/config';

type LocalProps = {|
  children: React.Element<*>,
  showAnimation: boolean,
  getCartEl: () => ?HTMLElement,
  onDone: () => any,
|};

class AddToCartAnimation extends React.Component<LocalProps> {
  containerRef: * = React.createRef();
  cleanupDOM: ?Function;

  componentWillUnmount() {
    if (this.cleanupDOM) {
      this.cleanupDOM();
      this.cleanupDOM = null;
    }
  }

  componentDidUpdate(prevProps: LocalProps) {
    if (prevProps.showAnimation === false && this.props.showAnimation === true) {
      this.doAnimate();
    }
  }

  onComplete = () => {
    this.cleanupDOM && this.cleanupDOM();
    this.cleanupDOM = null;
    this.props.onDone();
  };

  doAnimate() {
    const el = this.containerRef.current;
    if (!el) {
      return;
    }

    const rect = el.getBoundingClientRect();
    const elCopy = el.cloneNode(true);
    elCopy.style.position = 'absolute';

    // Note(20): add it a little above above of cursor (because of zIndexes problems closing popup)

    const aboveY = 48;

    elCopy.style.top = `${window.pageYOffset + rect.top - aboveY}px`;
    elCopy.style.left = `${rect.left}px`;
    elCopy.style.zIndex = zIndexes.animatedCart;

    global.document.body.appendChild(elCopy);

    this.cleanupDOM = () => {
      global.document.body.removeChild(elCopy);
    };

    const [translateX, translateY] = (() => {
      const cartEl = this.props.getCartEl();
      if (cartEl) {
        const cartRect = cartEl.getBoundingClientRect();
        const x = cartRect.left - rect.left;
        const y = cartRect.top - rect.top + aboveY;
        return [x, y];
      } else {
        const x = global.document.documentElement.clientWidth - rect.left - rect.width;
        const y = -rect.top;
        return [x, y];
      }
    })();

    anime({
      targets: elCopy,
      translateX,
      translateY,
      opacity: 0.15,
      scale: 0.5,
      easing: 'easeInOutCirc',
      complete: this.onComplete,
    });
  }

  render() {
    const { children } = this.props;
    return <div ref={this.containerRef}>{children}</div>;
  }
}

export default AddToCartAnimation;

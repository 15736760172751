import React from 'react';

import styled from '@emotion/styled';

import { useMediaQuery } from '../hooks/responsive';
import { themeMUI } from '../app/state';

import Ribbon from './alfabox/assets/footerGraphic.svg';
import { styledTheme } from '../app/theme';
import './alfabox/styles/font.css';
import { FlexRow } from '../baseUI';

const TitlesContainer = styled(FlexRow)`
  position: absolute;
  top: 0;
  left: 0;
`;

const RibbonTitleContainer = styled.div`
  width: 100%;
  height: 6rem;
  position: relative;
  margin-top: 0;
  margin-bottom: 3rem;
  & > img {
    width: 100%;
    height: 100%;
    display: inline-block;
    object-fit: contain;
  }
`;

const MainTitle = styled.div`
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: normal;
  z-index: 20;
`;

const SubTitle = styled.div`
  ${(props) =>
    !props.visible &&
    `
    display: inline-block;
    white-space: nowrap; 
    width: 0; 
    overflow: hidden;
    text-overflow: clip; 
  `}
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  display: inline-block;
  font-family: 'Dancing Script', cursive;
  text-transform: lowercase;
  font-size: 4rem;
  font-weight: normal;
  margin-top: -0.7rem;
  margin-left: -0.5rem;
  color: ${styledTheme.color('secondary')};
  opacity: 0.25;
`;

function AlfaboxRibbon(props: { title: String, isSubtitle?: boolean }) {
  const { title, isSubtitle } = props;

  const isSmall = useMediaQuery(themeMUI.breakpoints.down('xs'));
  const isMedium = useMediaQuery(themeMUI.breakpoints.down('sm'));

  return (
    <div>
      <RibbonTitleContainer isSmall={isSmall} isMedium={isMedium}>
        <TitlesContainer alignItems="center">
          <MainTitle>{title}</MainTitle>
          {!isSmall && <SubTitle visible={isSubtitle}>{title}</SubTitle>}
        </TitlesContainer>
        {!isSmall && <img src={Ribbon} alt="company graphic" />}
      </RibbonTitleContainer>
    </div>
  );
}

export default AlfaboxRibbon;

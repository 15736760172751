// @flow

import * as React from 'react';
import { useState, useEffect } from 'react';
import { FlexRow } from '../baseUI';
import MenuItem from './MenuItem';
import type { TopMenuItem } from '../appState/types';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider/Divider';
import IconButton from '@material-ui/core/IconButton/IconButton';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/core';
import { themeMUI, useLingui } from '../app/state';
import HotIcon from '@material-ui/icons/Whatshot';
import PrintIcon from '@material-ui/icons/Print';

import { useMediaQuery } from '../hooks/responsive';
import { Link } from 'react-router-dom';
import { Badge, ThemedText } from '../appUI';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AlarmIcon from '@material-ui/icons/Alarm';
import { Trans } from '@lingui/react';
import { LanguageSelect } from './LanguageSelect';
import { AngleDoubleDown, AngleDoubleLeft, AngleDoubleRight, AngleDoubleUp } from './icons';
import { DotLoader } from '../UI/loaders';
import { useGlobalContainer } from '../app/use';

import styled from '@emotion/styled';
// import HotIcon from '../icons/whatshot.svg';
// import PrintIcon from '../icons/print.svg';

export const menuOpenWidth = 235;
export const menuClosedWidth = 54;
export const SPECIAL_ITEMS_INDEX = 1;

// const Title = styled.div`
//   font-size: 0.9em;
//   font-weight: bold;
//   display: inline-block;
// `;

const styles = (theme) => ({
  root: {
    display: 'flex',
    maxWidth: menuOpenWidth,
  },
  drawer: {
    overflowX: 'hidden',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.3)',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      zIndex: 1300,
    },
  },
  alfaboxDrawer: {
    boxShadow: 'none',
  },
  drawerOpen: {
    width: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: menuClosedWidth,
  },
  paper: {
    width: menuOpenWidth,
    position: 'relative',
    height: 'auto',
  },
  alfaboxPaper: {
    width: '100%',
    position: 'relative',
    height: 'auto',
    backgroundColor: '#e6e6e6',
    paddingBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      width: '250px',
      height: '100%',
    },
  },
  header: {
    position: 'sticky',
    bottom: 0,
    zIndex: 2,
    backgroundColor: 'inherit',
  },
  toolbar: {
    alignItems: 'center',
    padding: '0 2px',
    height: '50px',
  },
  backdropOpen: {
    zIndex: 1200,
  },
  alfaboxDrawerWrapper: {
    maxWidth: '100%',
    width: '100%',
    backgroundColor: '#e6e6e6',
    borderRadius: '5px',
    marginLeft: '0',
    [theme.breakpoints.up('md')]: {
      minHeight: '750px',
      paddingTop: '2px',
      paddingBottom: '2px',
      marginLeft: '10px',
    },
  },
});

const CategoriesTitle = styled.h1`
  display: inline-block;
  font-weight: normal;
  margin: 0;
  color: #000;
  background-color: #e6e6e6;
  font-size: 1.3rem;
  text-transform: uppercase;
  padding: 10px 0 0 1.9rem;
`;

type LocalProps = {|
  items: Array<TopMenuItem>,
  itemsLoaded: boolean,
  classes: *,
  isSmall: boolean,
  isExpanded: boolean,
  setExpanded: (boolean) => void,
  toggleExpanded: () => void,
  areItemsExpanded: boolean,
  toggleItemsExpanded: () => void,
  navigateToItem: (item: TopMenuItem) => void,
  isVariant: boolean,

  watchedCount: number,
  favoritesCount: number,
|};

function AppMenu(props: LocalProps) {
  // const isXs = useMediaQuery(themeMUI.breakpoints.down('xs'));
  const isSmall = useMediaQuery(themeMUI.breakpoints.down('sm'));
  const isMedium = useMediaQuery(themeMUI.breakpoints.down('md'));
  const [popoverId, setPopoverId] = useState(null);
  const [openItems, setOpenItems] = useState(() => {
    let openItems = {};
    if (props.areItemsExpanded) {
      props.items.forEach((item) => (openItems[item.id] = true));
    }
    return openItems;
  });
  const { classes, setExpanded, isExpanded, areItemsExpanded, items, toggleItemsExpanded } = props;
  const i18n = useLingui();
  const globalContainer = useGlobalContainer();
  const isAlfabox = globalContainer.isSpecificProject('alfabox');
  const isDealer = globalContainer.isSpecificSite('rudorfer.dealer');

  useEffect(() => {
    let openItems = {};
    if (areItemsExpanded) {
      items.forEach((item) => (openItems[item.id] = areItemsExpanded));
    }
    setOpenItems(openItems);
  }, [areItemsExpanded, items]);

  let extraItems = [];
  if (isMedium) {
    const printOptionsInfo = globalContainer.selectStaticPageInfo('print-options');
    extraItems = [
      {
        categoryUrl: globalContainer.selectSystemPageUrl('quick-order'),
        id: 'rychla objednavka',
        title: i18n.t`Quick order`,
        iconUrl: '',
        image: { original: '', thumbnail: '' },
        icon: <HotIcon color="primary" />,
        labels: {},
        labels_test: [],
        subs: [],
      },
      {
        categoryUrl: printOptionsInfo.url,
        id: 'potisk',
        title: printOptionsInfo.name,
        iconUrl: '',
        image: { original: '', thumbnail: '' },
        icon: <PrintIcon color="primary" />,
        labels: {},
        labels_test: [],
        subs: [],
      },
    ];
  }

  const allItems = [...extraItems, ...props.items];

  let showTimeout;
  let closeTimeout;

  const onShowPopover = (id) => {
    clearTimeout(showTimeout);
    clearTimeout(closeTimeout);
    if (id !== popoverId) {
      showTimeout = setTimeout(() => {
        setPopoverId(id);
      }, 250);
    }
  };

  const onHidePopover = () => {
    closeTimeout = setTimeout(() => {
      setPopoverId(null);
    }, 250);
  };

  const onExpandItem = (id) => {
    if (isExpanded) {
      setOpenItems({ ...openItems, [id]: !openItems[id] });
    }
  };

  const closeAfterRedirect = () => {
    if (isSmall) {
      setExpanded(false);
    }
  };

  const onIconClick = (item: TopMenuItem) => {
    if (isExpanded) {
      onExpandItem(item.id);
    } else {
      props.navigateToItem(item);
    }
  };

  const renderMenuItems = () => {
    return (
      <React.Fragment>
        {allItems.map((item, idx) => {
          return (
            <MenuItem
              key={item.id}
              item={item}
              menuClosed={!isExpanded}
              onRedirect={closeAfterRedirect}
              onIconClick={onIconClick}
              onShowPopover={onShowPopover}
              onHidePopover={onHidePopover}
              hasPopover={popoverId === item.id}
              isOpen={isExpanded && openItems[item.id]}
              onExpandItem={() => onExpandItem(item.id)}
              index={isMedium ? idx - 2 : idx}
              isHighlighted={
                isMedium ? idx === SPECIAL_ITEMS_INDEX + 2 : idx === SPECIAL_ITEMS_INDEX
              }
              isAlfabox={isAlfabox}
              isSmall={isSmall}
              isDealer={isDealer}
            />
          );
        })}
      </React.Fragment>
    );
  };

  const renderAlfaboxMenu = () => {
    return (
      <div className={`${classes.root} ${classes.alfaboxDrawerWrapper}`}>
        <Drawer
          variant={isSmall ? 'temporary' : 'permanent'}
          anchor="left"
          open={isExpanded}
          className={`${classes.drawer} ${isExpanded ? classes.drawerOpen : classes.drawerClose} ${
            classes.alfaboxDrawer
          }`}
          classes={{
            paper: classes.alfaboxPaper,
          }}
          onClose={() => setExpanded(false)}
        >
          {<CategoriesTitle>{i18n.t`Categories`}</CategoriesTitle>}
          {!props.itemsLoaded ? <DotLoader /> : null}
          {renderMenuItems()}
        </Drawer>
      </div>
    );
  };

  const renderRudorferMenu = () => {
    return (
      <div className={classes.root}>
        <Drawer
          variant={isSmall ? 'temporary' : 'permanent'}
          anchor="left"
          open={isExpanded}
          className={`${classes.drawer} ${isExpanded ? classes.drawerOpen : classes.drawerClose}`}
          classes={{
            paper: classes.paper,
          }}
          onClose={() => setExpanded(false)}
          data-cy="app-menu"
        >
          {!props.itemsLoaded ? <DotLoader /> : null}
          {isSmall && (
            <FlexRow width="100%" justifyContent="center" paddingTop={10}>
              <Link to={globalContainer.selectSystemPageUrl('watchdog')}>
                <IconButton color="primary" title={i18n.t`Watchdog`}>
                  <Badge badgeContent={props.watchedCount}>
                    <AlarmIcon />
                  </Badge>
                </IconButton>
              </Link>
              <Link to={globalContainer.selectSystemPageUrl('favorite')}>
                <IconButton color="primary" title={i18n.t`Favorite products`}>
                  <Badge badgeContent={props.favoritesCount}>
                    <FavoriteIcon />
                  </Badge>
                </IconButton>
              </Link>
            </FlexRow>
          )}
          {renderMenuItems()}
          {isSmall && (
            <FlexRow width="100%" justifyContent="center" paddingTop={10}>
              <LanguageSelect />
            </FlexRow>
          )}
          {isExpanded ? (
            <div className={classes.header}>
              <FlexRow className={classes.toolbar} justifyContent="center">
                <ThemedText color="primary" size="bigger">
                  {areItemsExpanded ? <Trans>Roll up</Trans> : <Trans>Unroll</Trans>}
                </ThemedText>
                <IconButton onClick={toggleItemsExpanded} color="primary">
                  {areItemsExpanded ? <AngleDoubleUp /> : <AngleDoubleDown />}
                </IconButton>
              </FlexRow>
              <Divider />
            </div>
          ) : null}

          <div className={classes.header}>
            <Divider />
            <FlexRow
              className={classes.toolbar}
              justifyContent={isExpanded ? 'flex-end' : 'flex-start'}
            >
              {isExpanded && (
                <ThemedText color="primary" size="bigger">
                  <Trans>Collapse</Trans>
                </ThemedText>
              )}
              <IconButton onClick={() => setExpanded(!isExpanded)} color="primary">
                {isExpanded ? <AngleDoubleLeft /> : <AngleDoubleRight />}
              </IconButton>
            </FlexRow>
          </div>
        </Drawer>
      </div>
    );
  };

  return isAlfabox ? renderAlfaboxMenu() : renderRudorferMenu();
}

export default withStyles(styles)(AppMenu);

// @flow

import * as React from 'react';

import TextFieldLib from '@material-ui/core/TextField';
import ButtonLib from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Block } from '../baseUI';

export function TextField(props: {}) {
  return <TextFieldLib margin="normal" fullWidth {...props} />;
}

export const Button = withStyles({
  root: {
    textTransform: 'none',
  },
})((props: *) => {
  return <ButtonLib color="primary" {...props} />;
});

export const PrimaryButton = withStyles((theme) => ({
  root: {
    padding: '8px 48px',
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))((props: *) => {
  return <ButtonLib color="primary" variant="contained" {...props} />;
});

export const SecondaryButton = withStyles((theme) => ({
  root: {
    padding: '8px 48px',
    boxShadow: 'none',

    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))((props: *) => {
  return <ButtonLib color="primary" variant="outlined" {...props} />;
});

export const SelectControl = withStyles({
  shrink: {
    fontSize: '18px',
    whiteSpace: 'nowrap',
  },
})(
  (props: {
    classes: *,
    inputProps: {
      id: string,
    },
    onChange: Function,
    value: string,
    options: Array<{| value: string, label: string |}>,
    label?: string,
    inputLabelProps: Object,
    formControlProps: Object,
    selectProps: Object,
  }) => {
    const { id } = props.inputProps;

    return (
      <FormControl margin="normal" {...props.formControlProps}>
        {props.label && (
          <InputLabel classes={props.classes} htmlFor={id} {...props.inputLabelProps}>
            {props.label}
          </InputLabel>
        )}
        <Select
          value={props.value}
          onChange={props.onChange}
          inputProps={props.inputProps}
          data-cy={id === 'select-business' ? 'shipping-address-select' : ''}
          {...props.selectProps}
        >
          {props.options.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

export const SelectMultipleControl = withStyles({
  shrink: {
    fontSize: '18px',
  },
})(
  (props: {
    classes: *,
    inputProps: {
      id: string,
    },
    onChange: Function,
    value: Array<string>,
    options: Array<{| value: string, label: string, component?: React.Node |}>,
    label?: string,
    inputLabelProps: Object,
    formControlProps: Object,
    selectProps: Object,
  }) => {
    const { id } = props.inputProps;

    return (
      <FormControl margin="normal" {...props.formControlProps}>
        {props.label && (
          <InputLabel classes={props.classes} htmlFor={id} {...props.inputLabelProps}>
            {props.label}
          </InputLabel>
        )}
        <Select
          multiple
          value={props.value}
          onChange={props.onChange}
          inputProps={props.inputProps}
          data-cy={id === 'Použití' ? 'filter-usage-select' : ''}
          {...props.selectProps}
        >
          {props.options.map((opt) => (
            <MenuItem
              key={opt.value}
              value={opt.value}
              component={opt.component}
              data-cy="filter-option"
            >
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

export function TextFieldRow(props: *) {
  return (
    <Block>
      <TextField {...props} InputProps={{ classes: { underline: 'MUIunderline' } }} />
    </Block>
  );
}

export const TextArea = withStyles({
  root: {
    // backgroundColor: __internalThemeDoNotUse.colors.secondaryBg,
  },
})((props: *) => {
  return <TextField multiline variant="outlined" rows={4} {...props} />;
});

// @flow

import React from 'react';
import { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import anime from 'animejs';

type LocalProps = {|
  // on any change of the value, the children is animated
  value: any,
  children: any,
|};

const AnimationParent = styled.div`
  display: flex;
  align-items: center;
  opacity: 1;
`;

export function AnimateCartChange({ value, children }: LocalProps) {
  const [didChange, setDidChange] = useState(false);
  const [lastValue, setLastValue] = useState(value);

  const elRef = useRef(null);

  useEffect(() => {
    if (lastValue !== value) {
      anime({
        targets: elRef.current,
        keyframes: [
          { translateY: 4, scale: 1 },
          { translateY: 0, scale: 1.4 },
          { translateY: 0, scale: 1 },
        ],
        duration: 400,
        easing: 'easeOutQuad',
        complete: () => {
          // reset
          setDidChange(false);
        },
      });
      setLastValue(value);
    }
  }, [value, lastValue]);

  return (
    <AnimationParent ref={elRef} isActive={didChange}>
      {children}
    </AnimationParent>
  );
}

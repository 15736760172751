// @flow

import { FlexRow } from '../baseUI';
import React from 'react';
import styled from '@emotion/styled';
import { styledTheme } from '../app/theme';
import { ThemedText } from '../appUI';
import RightIcon from '@material-ui/icons/ChevronRight';
import LeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton/IconButton';

import { Trans } from '@lingui/react';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const PageNumber = styled.span`
  color: ${(props) => (props.isAlfabox ? '#000' : styledTheme.color('primary'))};
  font-weight: ${(props) => (props.current ? 'bold' : 'normal')};
  cursor: pointer;
  font-size: 16px;
  padding: ${(props) => (props.isAlfabox ? '2px 5px 2px' : '10px 15px 12px')};
`;

const PageHeading = styled.span`
  color: #000;
  font-weight: normal;
  font-size: 16px;
  padding-right: 10px;
`;

const EmptySpace = styled.span`
  width: 1rem;
`;

const ArrowContainer = styled.div`
  transform: ${(props) => props.isLeft && 'rotate(180deg)'};
  cursor: pointer;
  & > svg {
    font-size: 1rem;
  }
`;

type LocalProps = {|
  // from 1
  currentPage: number,
  pageCount: number,
  setPage: (number) => any,
  isAlfabox?: boolean,
|};

function CategoryPagination(props: LocalProps) {
  const { pageCount, currentPage, setPage, isAlfabox } = props;

  let fromPageNum = Math.max(currentPage - 2, 1);
  const toPageNum = Math.min(fromPageNum + 4, pageCount);
  fromPageNum = Math.max(Math.min(fromPageNum, toPageNum - 4), 1);

  let items = [];
  for (let i = fromPageNum; i <= toPageNum; i++) {
    items.push(
      <PageNumber
        key={i}
        current={i === currentPage}
        onClick={() => setPage(i)}
        isAlfabox={isAlfabox}
      >
        {i}
      </PageNumber>
    );
  }

  return (
    <FlexRow alignItems="center">
      {isAlfabox && (
        <PageHeading>
          <Trans>Page</Trans>
        </PageHeading>
      )}
      {currentPage !== 1 && (
        <React.Fragment>
          {isAlfabox ? (
            <ArrowContainer isLeft={true}>
              <DoubleArrowIcon color="secondary" onClick={() => setPage(currentPage - 1)} />
            </ArrowContainer>
          ) : (
            <IconButton onClick={() => setPage(currentPage - 1)}>
              <LeftIcon color="primary" />
            </IconButton>
          )}
        </React.Fragment>
      )}
      {isAlfabox && currentPage === 1 && <EmptySpace />}
      {currentPage >= 4 && fromPageNum !== 1 && (
        <React.Fragment>
          <PageNumber onClick={() => setPage(1)} isAlfabox={isAlfabox}>
            1
          </PageNumber>{' '}
          <ThemedText color="primary" size="large" letterSpacing={2}>
            ...
          </ThemedText>
        </React.Fragment>
      )}
      {items}
      {toPageNum !== pageCount && (
        <React.Fragment>
          <ThemedText color="primary" size="large" letterSpacing={2}>
            ...
          </ThemedText>{' '}
          <PageNumber onClick={() => setPage(pageCount)} isAlfabox={isAlfabox}>
            {pageCount}
          </PageNumber>
        </React.Fragment>
      )}
      {currentPage !== pageCount && (
        <React.Fragment>
          {isAlfabox ? (
            <ArrowContainer>
              <DoubleArrowIcon onClick={() => setPage(currentPage + 1)} color="secondary" />
            </ArrowContainer>
          ) : (
            <IconButton onClick={() => setPage(currentPage + 1)}>
              <RightIcon color="primary" />
            </IconButton>
          )}
        </React.Fragment>
      )}
      {isAlfabox && currentPage === pageCount && <EmptySpace />}
    </FlexRow>
  );
}

export default CategoryPagination;

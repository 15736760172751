// @flow

import React, { useState } from 'react';
import { FlexRow, FlexColumn } from '../baseUI';
import styled from '@emotion/styled';
import { ThemedText, HorizontalSpace, VerticalSpace } from '../appUI';
import { Trans } from '@lingui/react';
import { styledTheme } from '../app/theme';
import PlusIcon from '@material-ui/icons/Add';
import { useLingui } from '../app/lingui';
import type { ProductItem, ProductDetail, ProductCartItem } from '../appState/types';
import { getImagePlaceholder } from './getImagePlaceholder';

const Image = styled.img`
  width: 100px;
  height: 100px;
`;

const Package = styled.div`
  width: ${(props) => (props.isWholePackage ? '92px' : '96px')};
  height: ${(props) => (props.isWholePackage ? '86px' : '90px')};
  border: solid ${styledTheme.color('primary')};
  border-width: ${(props) => (props.isWholePackage ? '3px' : '1px')};
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  background-color: ${(props) => (props.active ? styledTheme.color('primary') : 'initial')};
  color: ${(props) => (props.active ? 'white' : styledTheme.color('primary'))};
`;

const PlusButton = styled(Package)`
  background-color: ${styledTheme.color('primary')};
  color: #ffffff;
`;

function AmountPicker(props: {|
  product: ProductItem | ProductDetail | ProductCartItem,
  quantity: number,
  max: number,
  onChoose: Function,
  showCartButton: boolean,
  animateAdd: boolean,
  useIconButton: boolean,
  handleClick: Function,
  getCartEl: Function,
  handleDone: Function,
  pieceSale: boolean,
|}) {
  const { product } = props;
  const i18n = useLingui();
  const { packageSize, measureUnit } = product.params;
  const orderAmount =
    product.params.saleType === 'amount' || measureUnit.erp_id === 'BAL'
      ? product.params.minAmountToOrder
      : packageSize;

  const step = 10;
  const maxPackages = Math.floor(props.max / orderAmount);
  const numberOfPackages = props.quantity / orderAmount;
  const imgPlaceholder = getImagePlaceholder();

  const [displayedPackages, setDisplayedPackages] = useState(
    Math.min(maxPackages, Math.ceil((numberOfPackages + 1) / step) * step)
  );

  if (displayedPackages === maxPackages) {
    setDisplayedPackages(displayedPackages + 1);
  }
  const packages = [];
  for (let i = 1; i < displayedPackages; i++) {
    const pieces = i * orderAmount;
    const isActive = props.quantity === pieces;
    const moduloBy = measureUnit.erp_id === 'BAL' ? orderAmount : packageSize;
    const isWholePackage = pieces % moduloBy === 0;
    packages.push(
      <Package
        key={i}
        active={isActive}
        isWholePackage={isWholePackage}
        onClick={() => props.onChoose(pieces)}
      >
        {isWholePackage && (
          <div style={{ textAlign: 'center', width: '100%', fontFamily: 'monospace' }}>
            <ThemedText fontWeight="900" size="bigger">
              {orderAmount === 0 ? null : Math.floor(pieces / moduloBy)}
              <HorizontalSpace size="small" />
              <Trans>packages</Trans>
            </ThemedText>
          </div>
        )}
        <ThemedText size="smaller">
          {measureUnit.erp_id === 'BAL' ? packageSize * i : pieces}
          <HorizontalSpace size="small" />
          <Trans>pcs</Trans>
        </ThemedText>
      </Package>
    );
  }

  return (
    <FlexColumn padding={20}>
      <FlexRow alignItems="center" minHeight="120px">
        <Image src={product.images.length > 0 ? product.images[0].thumbnail : imgPlaceholder} />
        <HorizontalSpace />
        <ThemedText size="large" color="primary">
          <Trans>Choose number of packages for</Trans> {product.code}
        </ThemedText>
      </FlexRow>

      <VerticalSpace size="large" />
      <FlexRow flexWrap="wrap" justifyContent="center">
        {packages}
        {displayedPackages < maxPackages && (
          <PlusButton
            onClick={() => setDisplayedPackages(Math.min(maxPackages, displayedPackages + step))}
            title={i18n.t`Add more packages`}
          >
            <PlusIcon />
          </PlusButton>
        )}
      </FlexRow>
    </FlexColumn>
  );
}

export default AmountPicker;

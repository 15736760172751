// @flow

import { withStyles } from '@material-ui/core';
import Input from '@material-ui/core/Input/Input';
import { useMediaQuery } from '../hooks/responsive';
import SearchIcon from '@material-ui/icons/Search';
import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
import {themeMUI, useLoginContainer, useGlobalContainer, useWebAnalyticsContainer} from '../app/state';
import BasicPopover from '../appUI/BasicPopover';
import { FlexRow, Block } from '../baseUI';
import { PrimaryButton } from '../materialUI';
import SearchPopup from './SearchPopup';
import { useLingui } from '../app/lingui';
import styled from '@emotion/styled';
import anime from 'animejs';
import { Trans } from '@lingui/react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { useRouter } from '../app/router';
import { useSearch, searchStateIsIn } from '../RudorferApp/useSearch';
import { useSearchInput } from '../RudorferApp/useSearchInput';
import { styledTheme } from '../app/theme';
import { gtag } from '../AppRoot/gtm';

const tooltipColor = '#AEEEEE';

const Tooltip = styled.div`
  position: absolute;
  background-color: transparent;
  top: ${(props) => (props.isXs ? 50 : props.isSm ? 36 : 27)}px;
  left: 87px;
  color: ${tooltipColor};
  white-space: nowrap;
`;

const TooltipText = styled.div`
  font-size: ${(props) => (props.isXs ? 24 : props.isSm ? 40 : 57)}px;
`;

const Arrow = styled.svg`
  width: 70px;
  position: absolute;
  left: 20px;
  top: 12px;
  color: ${tooltipColor};
  fill: currentColor;
`;

const BorderBottomSearch = styled.div`
  display: flex;
  border-bottom: 3px solid ${styledTheme.color('secondary')};
  width: 100%;
  margin: auto;
`;

const SearchTooltip = (props) => {
  const ref = useRef(null);
  const [visible, setVisible] = useState(true);
  const [visibleButton, setVisibleButton] = useState(false);
  const isXs = useMediaQuery(themeMUI.breakpoints.down('xs'));
  const isSm = useMediaQuery(themeMUI.breakpoints.down('sm'));
  useEffect(() => {
    anime({
      targets: ref.current,
      keyframes: [
        { translateX: 0, translateY: 0, rotate: 0, scale: 1 },
        { translateX: -31, translateY: -15, rotate: 64, scale: 1.3 },
        { translateX: -31, translateY: -15, rotate: 64, scale: 1.3 },
      ],
      duration: 2000,
      easing: 'linear',
      direction: 'alternate',
      loop: 10,
      delay: 3000,
    });
  }, []);

  return visible ? (
    <React.Fragment>
      <Tooltip
        onMouseEnter={() => setVisibleButton(true)}
        onMouseLeave={() => setVisibleButton(false)}
        isXs={isXs}
        isSm={isSm}
      >
        <FlexRow>
          <TooltipText isXs={isXs} isSm={isSm}>
            <Trans>enter code and order</Trans>
          </TooltipText>
          {visibleButton && (
            <Block marginTop={-12}>
              <IconButton color="inherit" onClick={() => setVisible(false)}>
                <CloseIcon />
              </IconButton>
            </Block>
          )}
        </FlexRow>
      </Tooltip>
      <Arrow ref={ref} viewBox="0 0 32 32">
        <path
          fill="inherit"
          d="M 11 4.96875 L 10.28125 5.65625 L 4.28125 11.65625 L 5.71875 13.09375 L 10 8.8125 L 10 10 C 10 19.377379 17.622621 27 27 27 L 27 25 C 18.703379 25 12 18.296621 12 10 L 12 8.8125 L 16.28125 13.09375 L 17.71875 11.65625 L 11.71875 5.65625 L 11 4.96875 z"
          overflow="visible"
        />
      </Arrow>
    </React.Fragment>
  ) : null;
};

const searchInputStylesRudorfer = {
  root: {
    fontSize: 15,
    padding: '2px 8px',
    marginLeft: 5,

    '&&:before': {
      borderBottom: '1px solid',
    },
    '&&:hover': {
      border: 'none',
    },
  },
};

const searchInputStylesAlfabox = {
  root: {
    fontSize: 15,
    padding: '2px 8px',

    '&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
    '&&:hover::before': {
      borderBottom: 'none',
    },
    '&&:focus::before': {
      borderBottom: 'none',
    },
    '&&:hover': {
      borderBottom: 'none',
    },
    '&:placeholder': {
      color: '#000',
    },
  },
};

const btnStylesAlfabox = (theme) => ({
  root: {
    padding: '5px',
    backgroundColor: 'transparent',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    transition: 'all 0.3s ease',
    '& svg': {
      fontSize: '2.5rem',
    },
    '&&:hover': {
      filter: 'brightness(90%)',
      backgroundColor: 'transparent',
      transform: 'scale(1.2)',
    },
  },
});

const AlfaboxPrimaryBtn = withStyles(btnStylesAlfabox)((props) => {
  return (
    <PrimaryButton {...props}>
      <SearchIcon />
    </PrimaryButton>
  );
});

const SearchInputRudorfer = withStyles(searchInputStylesRudorfer)((props) => {
  return <Input {...props} />;
});

const SearchInputAlfabox = withStyles(searchInputStylesAlfabox)((props) => {
  return <Input {...props} />;
});

type LocalProps = {| popupHeight: number, forcePopupClosed: boolean |};

type SearchBarProps = {|
  inputRef: any,
  onKeyDown: Function,
  onChange: Function,
  onClick: Function,
  inputValue: string,
  onSearchClick: Function,
|};

function RudorferSearchBar(props: SearchBarProps) {
  const { inputRef, onKeyDown, onChange, onClick, inputValue, onSearchClick } = props;
  const i18n = useLingui();
  return (
    <React.Fragment>
      <SearchIcon />
      <SearchInputRudorfer
        inputRef={inputRef}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onClick={onClick}
        value={inputValue}
        fullWidth
        placeholder={i18n.t`11001.01, ring, gold...`}
        data-cy="search-input"
      />
      <PrimaryButton onClick={onSearchClick} data-cy="search-button">
        <Trans>Search</Trans>
      </PrimaryButton>
    </React.Fragment>
  );
}

function AlfaboxSearchBar(props: SearchBarProps) {
  const { inputRef, onKeyDown, onChange, onClick, inputValue, onSearchClick } = props;
  const i18n = useLingui();
  return (
    <BorderBottomSearch>
      <Block width={300}>
        <SearchInputAlfabox
          inputRef={inputRef}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onClick={onClick}
          value={inputValue}
          fullWidth
          placeholder={i18n.t`Search in store...`}
        />
      </Block>
      <AlfaboxPrimaryBtn onClick={onSearchClick} />
    </BorderBottomSearch>
  );
}

function Search(props: LocalProps) {
  const tooltipRelativeRef = useRef(null);
  const inputRef = useRef(null);
  const isXs = useMediaQuery(themeMUI.breakpoints.down('xs'));
  const router = useRouter();
  const isLogged = useLoginContainer().isLogged();
  const globalContainer = useGlobalContainer();
  const { pathname } = router.location;
  const isSearchStartPage = true;
  const [openModal, setOpenModal] = useState(false);

  const { inputValue, setInputValue, searchTerm } = useSearchInput();
  const [forcePopup, setForcePopup] = useState<'none' | 'closed' | 'open'>(
    isSearchStartPage ? 'open' : 'none'
  );
  const searchInfo = useSearch(searchTerm);

  const webAnalytics = useWebAnalyticsContainer();

  useEffect(() => {
    // const isSearchPage = paths.isSearchPage(pathname);
    // // closes popup on transition to product page or any other page
    // if (!isSearchPage) {
    // }
    setForcePopup('closed');
  }, [pathname]);

  const focusInput = () => {
    const el = inputRef.current;
    if (el) {
      el.focus();
    }
  };

  const submitSearch = () => {
    webAnalytics.searchEvent(searchTerm);

    const isSearchable = !!searchTerm;
    if (!isSearchable) {
      focusInput();
      return;
    }

    setForcePopup('closed');
    window.scrollTo(0, 0);
    focusInput();

    // searchable => go to page results unless already there
    if (!searchInfo.pageOpen) {
      router.history.push(searchInfo.getSearchUrl(searchTerm));
    }
  };

  const onSearchClick = () => {
    submitSearch();
  };

  const onChange = (e) => {
    const inputValue = e.currentTarget.value;
    setInputValue(inputValue);
    setForcePopup('open');
  };

  const onClick = () => {
    setForcePopup('open');
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      submitSearch();
    }
    if (e.key === 'Escape') {
      setForcePopup('closed');
    }
  };

  const onClose = () => {
    if (!openModal) {
      setForcePopup('closed');
    }
  };

  const showPopup = (() => {
    if (props.forcePopupClosed || forcePopup === 'closed') {
      return false;
    }
    return (!!inputValue && !searchInfo.pageOpen) || forcePopup === 'open';
  })();

  return (
    <div ref={tooltipRelativeRef}>
      <FlexRow alignItems="center" paddingLeft={10} position="relative">
        {globalContainer.isSpecificProject('alfabox') ? (
          <AlfaboxSearchBar
            inputRef={inputRef}
            onKeyDown={onKeyDown}
            onChange={onChange}
            onClick={onClick}
            inputValue={inputValue}
            onSearchClick={onSearchClick}
          />
        ) : (
          <RudorferSearchBar
            inputRef={inputRef}
            onKeyDown={onKeyDown}
            onChange={onChange}
            onClick={onClick}
            inputValue={inputValue}
            onSearchClick={onSearchClick}
          />
        )}
        {globalContainer.isUrlForSystemPage(pathname, 'quick-order') && <SearchTooltip />}
      </FlexRow>
      {showPopup && (
        <BasicPopover
          isVisible={showPopup}
          anchorEl={tooltipRelativeRef.current}
          popoverId="search-popover"
          placement="bottom"
          onClose={onClose}
        >
          <SearchPopup
            height={props.popupHeight}
            results={searchInfo.results}
            productsMap={searchInfo.productDetails}
            isSmall={isXs}
            isLogged={isLogged}
            searchTerm={searchTerm}
            isLoading={searchStateIsIn(searchInfo.state, 'pending')}
            hasLoaded={searchStateIsIn(searchInfo.state, 'firstResult', 'done')}
            onToggleModal={setOpenModal}
          />
        </BasicPopover>
      )}
    </div>
  );
}

export default Search;

// @flow

import { asyncComponent } from '@whys/app/lib/pages';
import React from 'react';

import Loader from '../appUI/Loader';

function Placeholder() {
  return <Loader />;
}

export default [
  {
    path: '/dev',
    exact: true,
    component: asyncComponent({
      loader: () => import('../RudorferApp/DevelopmentPage'),
      Placeholder,
    }),
  },
  // Note: NiceURLPage matches any path with many slashes (and ends with slug) + handles 404
  {
    path: '*',
    exact: true,
    component: asyncComponent({
      loader: () => import('../RudorferApp/NiceURLPage'),
      Placeholder,
    }),
  },
];

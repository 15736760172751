// @flow

import * as React from 'react';
import { Trans, Plural } from '@lingui/react';

import { HorizontalSpace, ThemedText } from '../appUI';
import type { appColorEnum } from '../appState/types';
import { FlexRow } from '../baseUI';
import styled from '@emotion/styled';

type LocalProps = {|
  amount: number,
  splitBy: number,
  size: 'smaller' | 'bigger',
|};

type LocalState = *;

type AvailabilityType = 'in_stock_exact' | 'in_stock' | 'out_of_stock';

const Indicator = styled.div`
  width: 15px;
  height: 15px;
  display: inline-block;
  background-color: ${({ inStock }) => (inStock ? '#05b127' : '#b21506')};
  border-radius: 50%;
`;

const SplitAmountNum = 200;

class ProductAvailability extends React.Component<LocalProps, LocalState> {
  static defaultProps = { size: 'bigger', splitBy: SplitAmountNum };

  matchColor(type: AvailabilityType): appColorEnum {
    return {
      out_of_stock: 'primary',
      in_stock_exact: 'neutralText',
      in_stock: 'neutralText',
    }[type];
  }

  getTitle(type: AvailabilityType) {
    const { amount } = this.props;
    if (type === 'out_of_stock') {
      return <Trans>Not in stock</Trans>;
    }
    if (type === 'in_stock_exact') {
      return (
        <React.Fragment>
          <Trans>In stock</Trans>&nbsp;
          <Plural value={amount} one="# piece" other="# pieces" />
        </React.Fragment>
      );
    }
    return <Trans>In stock</Trans>;
  }

  getType(): AvailabilityType {
    const { amount, splitBy } = this.props;
    if (amount === 0) {
      return 'out_of_stock';
    }
    if (amount <= splitBy) {
      return 'in_stock_exact';
    }
    return 'in_stock';
  }

  render() {
    const type = this.getType();
    const { size } = this.props;

    return (
      <FlexRow alignItems="center" justifyContent="center">
        <Indicator inStock={type !== 'out_of_stock'} />
        <HorizontalSpace />
        <ThemedText
          textAlign="center"
          width="fit-content"
          color={this.matchColor(type)}
          size={size}
        >
          {this.getTitle(type)}
        </ThemedText>
      </FlexRow>
    );
  }
}

export default ProductAvailability;

// @flow

import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

export function DeleteButton(props: { onClick: Function }) {
  return (
    <IconButton {...props}>
      <DeleteIcon />
    </IconButton>
  );
}

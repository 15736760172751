// @flow

// framework
import { Container } from '@whys/app/lib/state';

import { getJSON } from '@whys/fetch/lib/json';
import { create as createCursorSuperCache } from '@whys/fetch/lib/pagination/CursorSuperCache';
import type { CursorSuperCache } from '@whys/fetch/lib/pagination/CursorSuperCache';

// whyshop
import type { ProductItem, QuickSearchItem } from '../whyshop/models/product';
import type { ProductContainerType } from '../whyshop/ProductContainer';
import { getNextUrl } from '../whyshop/requests';

// app
import type { FetchEnvType, AppCacheType } from '../types/app';

const resources = {
  quickSearch: (query) => ({ url: `/api/search/?q=${query}` }),
  fullSearch: (query) => ({ url: `/api/search/results/?q=${query}` }),
};

type LocalProps = {|
  productContainer: ProductContainerType<$FlowFixMe, $FlowFixMe>,
  fetchEnv: FetchEnvType,
  // cache: CacheType<StaticPageUrls | null>,
  appCache: AppCacheType,
  mapPayload: (any) => ProductItem,
  mapQuickPayload: (any) => QuickSearchItem,
|};

type LocalState = {
  productDetails: { [string]: ProductItem },
};

export class SearchContainer extends Container<LocalState> {
  state: LocalState;
  props: LocalProps;

  searchCaches: CursorSuperCache<ProductItem>;

  constructor(props: LocalProps) {
    super();

    const { fetchEnv, appCache, mapPayload } = props;

    this.searchCaches = createCursorSuperCache({
      cachePrefix: 'SearchContainer',
      appCache,
      fetchEnv,
      getNextUrl: ({ response }) => {
        return getNextUrl(response);
      },
      mapData: mapPayload,
    });

    this.props = props;
    this.state = { productDetails: {} };
  }

  //
  // Fetch
  //

  async loadProductDetails(productIds: string[]): Promise<void> {
    let productDetails = { ...this.state.productDetails };
    const products = await this.props.productContainer.resolveProductItemsByIds(productIds);
    for (const _product of products) {
      productDetails[_product.id] = _product;
    }
    await this.setState({ productDetails });
  }

  async quickSearch(query: string): Promise<QuickSearchItem[]> {
    const { fetchEnv } = this.props;
    const result = await getJSON(resources.quickSearch(query).url, fetchEnv);
    if (result.status === 'ok') {
      const { data } = result;
      const { mapQuickPayload } = this.props;
      return data.map(mapQuickPayload);
    }
    return [];
  }

  async getSearchResults(query: string): Promise<ProductItem[]> {
    const initialUrl = resources.fullSearch(query).url;
    const queryCache = this.searchCaches.getCacheByUrl(initialUrl);
    return queryCache.getAllItems();
  }

  async fetchNextPage(query: string): Promise<void> {
    const initialUrl = resources.fullSearch(query).url;
    const queryCache = this.searchCaches.getCacheByUrl(initialUrl);
    await queryCache.fetchNextPage();
  }

  hasNextPage(query: string): boolean {
    const initialUrl = resources.fullSearch(query).url;
    const queryCache = this.searchCaches.getCacheByUrl(initialUrl);
    return queryCache.hasNextPage();
  }

  //
  // Selectors
  //

  selectProductDetails(): { [string]: ProductItem } {
    return this.state.productDetails;
  }
}

// @flow

import * as React from 'react';
import { useRef, useState } from 'react';

import useComponentSize from '@rehooks/component-size';

type SizeType = {| width: number, height: number |};

type OnCallType = <TErrorType>(
  () => Promise<{| ok: boolean, errorType?: ?TErrorType |}>
) => Promise<void>;

export function useInlineResult<TErrorType>(opts: {|
  render: ({ ref: React.Ref<any> }) => React.Element<any>,

  // confirm timeout?
  renderOk: ({ onConfirm: () => any, size: SizeType }) => React.Node,
  renderError: ({
    onRetry: () => any,
    isErrorType: (TErrorType) => boolean,
    size: SizeType,
  }) => React.Node,
  renderLoading: ({ size: SizeType }) => React.Node,
  renderConfirmed?: ({ size: SizeType }) => React.Node,
|}): [React.Node, OnCallType] {
  const [status, setStatus] = useState<'idle' | 'loading' | 'ok' | 'confirmed' | 'error'>('idle');
  const [errorType, setErrorType] = useState<any>(undefined);

  const ref = useRef(null);
  const size = useComponentSize(ref);

  const el = (() => {
    if (status === 'idle') {
      return opts.render({ ref });
    }
    if (status === 'ok') {
      return opts.renderOk({
        size,
        onConfirm: () => {
          setStatus('confirmed');
        },
      });
    }
    if (status === 'error') {
      return opts.renderError({
        size,
        isErrorType: (val) => val === errorType,
        onRetry: () => {
          setStatus('idle');
        },
      });
    }
    if (status === 'confirmed') {
      const { renderConfirmed } = opts;
      return renderConfirmed ? renderConfirmed({ size }) : null;
    }
    if (status === 'loading') {
      return opts.renderLoading({ size });
    }
    return null;
  })();

  const onCall = async (fn) => {
    setStatus('loading');
    const result = await fn();
    setStatus(result.ok ? 'ok' : 'error');
    setErrorType(result.errorType);
  };

  return [el, onCall];
}

// @flow

import React from 'react';
import { Trans } from '@lingui/react';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

type LocalProps = {| initiallyOpen: boolean, onClose: () => any, onReload: () => any |};

const titleId = 'forbidden-dialog-title';
const describeId = 'alert-dialog-description';

export function ForbiddenErrorDialog(props: LocalProps) {
  const { initiallyOpen } = props;
  const [open, setOpen] = React.useState(initiallyOpen == null ? false : initiallyOpen);

  const onClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby={titleId} aria-describedby={describeId}>
      <DialogTitle id={titleId}>
        <Trans>Login expired</Trans>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id={describeId}>
          <Trans>
            Your latest action failed because your login has expired. Please login again or reload
            the page.
          </Trans>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onReload} color="primary" autoFocus>
          <Trans>Reload page</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

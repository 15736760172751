type GtmPluginData = { gtmId: string };

export const GTM_ID = 'GTM-PLWG27S';

export function gtag(){
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}

function updateHTML(pluginData: GtmPluginData) {
  return (initialHtml: string) => {
    if (!pluginData) {
      return initialHtml;
    }
    const { gtmId } = pluginData;
    if (!gtmId) {
      return initialHtml;
    }
    const scriptHtml = `
    <script>
      window.dataLayer = window.dataLayer || [];
      dataLayer.push ({ "event": "dl.ready.general" });
    </script>
    <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${gtmId}');</script>`;
    const noscriptHtml = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <script>gtag('js', new Date());</script>`;


    let currentHtml = initialHtml;
    const headInjectAt = currentHtml.indexOf('<head>') + '<head>'.length;
    currentHtml = `${currentHtml.slice(0, headInjectAt)}${scriptHtml}${currentHtml.slice(
      headInjectAt
    )}`;
    const bodyInjectAt = currentHtml.indexOf('<body>') + '<body>'.length;
    currentHtml = `${currentHtml.slice(0, bodyInjectAt)}${noscriptHtml}${currentHtml.slice(
      bodyInjectAt
    )}`;
    return currentHtml;
  };
}

export function setup(options: {
  // note: this is non-standard (just for this plugin) setup option to decouple app from plugin
  resolvePluginContext: (ctx) => GtmPluginData,
}) {
  if (!options) return null;
  async function createPluginContext({ ctx }) {
    return options.resolvePluginContext(ctx);
  }
  return {
    updateHTML,
    createServerContext: createPluginContext,
    createClientContext: createPluginContext,
  };
}

// @flow

import idx from 'idx';

import { createMuiTheme } from '@material-ui/core/styles';
// framework
import { useContainer } from '@whys/app/lib/state';
import { setupI18n } from '@lingui/core';
// whyshop
import { ProfileContainer } from '../whyshop/ProfileContainer';
import { CategoryContainer } from '../whyshop/CategoryContainer';
import { CategoryProductsContainer } from '../whyshop/CategoryProductsContainer';
import { ProductContainer } from '../whyshop/ProductContainer';
import { CartContainer } from '../whyshop/CartContainer';
import { LanguageContainer } from '../whyshop/containers/LanguageContainer';
import { PageContainer } from '../whyshop/containers/PageContainer';
import { mapVariantToProductItem } from '../whyshop/models/product';
import { SearchContainer } from '../whyshop/SearchContainer';
import { ExternalCatalogContainer } from '../whyshop/containers/ExternalCatalogContainer';
// app (generalize + move to whyshop?)
import { LoginContainer } from '../containers/LoginContainer';
import { FavoritesContainer } from '../containers/FavoritesContainer';
import { MenuContainer } from '../containers/MenuContainer';
import { PreferenceContainer } from '../containers/PreferenceContainer';
import { InitialContainer } from '../containers/InitialContainer';
// app
import { __internalThemeDoNotUse } from './theme';
import { GlobalContainer } from '../containers/GlobalContainer';
import { WatchdogContainer } from '../containers/WatchdogContainer';
import type { AppContainerType, SentryLogger } from '../types/app';
import type {
  ProductParams,
  ProductLabelsType,
  ProductLabelsTestType,
  QuickSearchItem,
} from '../appState/types';
import { HomepageContainer } from '../containers/HomepageContainer';
import { ContactContainer } from '../containers/ContactContainer';
import createRegisterContainer from '../state/createRegisterContainer';
import { WebAnalyticsContainer } from '../containers/WebAnalyticsContainer';
// import localforage from 'localforage';

// re-export

export { useLingui } from '../app/lingui';

// Note
export const themeMUI = createMuiTheme({
  palette: {
    primary: { main: __internalThemeDoNotUse.colors.primary },
    secondary: { main: __internalThemeDoNotUse.colors.secondary },
  },
});

export function useLoginContainer(): LoginContainer {
  return useContainer(LoginContainer);
}

export function useWebAnalyticsContainer(): WebAnalyticsContainer {
  return useContainer(WebAnalyticsContainer);
}

export function useIsLogged() {
  const loginState = useLoginContainer();
  return loginState.isLogged();
}

export function createI18nObject(appContainer: AppContainerType) {
  const { catalogs, language } = appContainer.context;
  const i18n = setupI18n({ catalogs, language });
  return i18n;
}

export async function createLoginContainer(appContainer: AppContainerType) {
  const { djreactState, fetchEnv } = appContainer.context;
  const initiallyLogged = await djreactState.logged;
  const userLogin = new LoginContainer({ initiallyLogged, fetchEnv });
  return userLogin;
}

export async function createWebAnalyticsContainer(appContainer: AppContainerType) {
  const { djreactState, initialData, language } = appContainer.context;

  const initiallyLogged = await djreactState.logged;

  const initData = initialData || {};

  const webAnalytics = new WebAnalyticsContainer({
    currency: initData.currency || '',
    staticPages: initData.static_pages || [],
    systemPages: initData.system_pages || [],
    status: initiallyLogged ? 'logged' : 'anonymous',
    lang: language,
    userID: initData.profile ? String(initData.profile.id) : '',
    affiliation: idx(initData, (_) => _.site.name) || '',
  });
  return webAnalytics;
}

// export async function createRegisterContainer(appContainer: AppContainerType) {
//   const { fetchEnv } = appContainer.context;
//   return new RegisterContainer({ fetchEnv });
// }

async function createInitialState(appContainer: AppContainerType) {
  const initialContainer = await appContainer.resolve(createInitialContainer);
  const initialState = await initialContainer.getInitialState();
  return initialState;
}

export function useInitialContainer() {
  return useContainer(InitialContainer);
}

export async function createPreferenceContainer(
  appContainer: AppContainerType
): Promise<PreferenceContainer> {
  const { fetchEnv, appCache } = appContainer.context;
  const initialState = await appContainer.resolve(createInitialState);
  return new PreferenceContainer({ fetchEnv, appCache, initialState });
}

export async function createLanguageContainer(
  appContainer: AppContainerType
): Promise<LanguageContainer> {
  const { fetchEnv, djreactState } = appContainer.context;
  const globalContainer = await appContainer.resolve(createGlobalContainer);
  const { initialState } = globalContainer.props;
  const { availableLanguages, defaultLanguage } = initialState;
  return new LanguageContainer({
    fetchEnv,
    initialLanguage: (djreactState.language: any),
    defaultLanguage,
    availableLanguages,
  });
}

export function useLanguageContainer() {
  return useContainer(LanguageContainer);
}

export async function createPageContainer(appContainer: AppContainerType): Promise<PageContainer> {
  const { fetchEnv, appCache } = appContainer.context;
  return new PageContainer({ fetchEnv, appCache });
}

// export function usePageContainer() {
//   return useContainer(PageContainer);
// }

export function useMenuContainer() {
  return useContainer(MenuContainer);
}

export function usePreferenceContainer() {
  return useContainer(PreferenceContainer);
}

// async function loadOnClientOrDefault<T>(fn: Function, defaultValue: T): Promise<T> {
//   if (typeof window === 'undefined') {
//     return defaultValue;
//   }
//   return (await fn()) || defaultValue;
// }

// function getOnClientOrDefault<T>(fn: Function, defaultValue: T): T {
//   if (typeof window === 'undefined') {
//     return defaultValue;
//   }
//   return fn() || defaultValue;
// }

export async function createProfileContainer(appContainer: AppContainerType) {
  const { fetchEnv, appCache } = appContainer.context;
  const productsContainer = await appContainer.resolve(createProductContainer);
  const initialContainer = await appContainer.resolve(createInitialContainer);

  // This resolves to either empty object {} or ProfileModel
  // Depends if user is logged in
  const initialProfile = (await initialContainer.getInitialState()).profile;
  return new ProfileContainer({ fetchEnv, appCache, productsContainer, initialProfile });
}

type OrderTypeEnum = 'PIECE' | 'PACKAGE' | 'AMOUNT';

type ParamsPayload = {|
  dimensions: ?string,
  usage: ?string,
  print_option: ?string,
  package_size: ?number,
  measure_unit: {|
    erp_id: 'KS' | 'BAL',
    name: string,
  |},
  min_amount_to_order: ?number,
  min_amount_to_order_type: ?OrderTypeEnum,

  collection: ?string,
  collection_desc_html: ?string,
  category_video: ?string,
  inside_color: ?string,
  inside_material: ?string,
  inside_cut_img: ?string,
  threshold_display_stock: number,
  // NoteReview ...
  sell_text: ?string,
|};

function mapSaleType(val: OrderTypeEnum) {
  return {
    PIECE: 'piece',
    PACKAGE: 'package',
    AMOUNT: 'amount',
  }[val];
}

function mapParams(params: ?ParamsPayload): ProductParams {
  const defaultSaleType = 'piece';
  const defaultMeasureUnit = { erp_id: 'KS', name: '', multiplySize: 1 };

  if (!params) {
    return {
      dimensions: '',
      usage: '',
      printOption: '',
      packageSize: 0,
      minAmountToOrder: 0,
      saleType: defaultSaleType,
      measureUnit: defaultMeasureUnit,

      // ...
      collectionTitle: '',
      collectionDescHtml: '',
      categoryVideo: '',
      insideColor: '',
      insideMaterial: '',
      insideCutImg: '',
      thresholdDisplayStock: 0,
    };
  }
  return {
    dimensions: params.dimensions || '',
    usage: params.usage || '',
    printOption: params.print_option || '',

    saleType: params.min_amount_to_order_type
      ? mapSaleType(params.min_amount_to_order_type)
      : defaultSaleType,
    packageSize: params.package_size || 0,
    measureUnit:
      {
        erp_id: idx(params, (_) => _.measure_unit.erp_id) || 'KS',
        name: idx(params, (_) => _.measure_unit.name) || '',
        multiplySize: idx(params, (_) => _.measure_unit.erp_id) === 'BAL' ? 100 : 1,
      } || defaultMeasureUnit,
    minAmountToOrder: params.min_amount_to_order || 0,

    // ...
    collectionTitle: params.collection || '',
    collectionDescHtml: params.collection_desc_html || '',
    categoryVideo: params.category_video || '',
    insideColor: params.inside_color || '',
    insideMaterial: params.inside_material || '',
    insideCutImg: params.inside_cut_img || '',
    thresholdDisplayStock: params.threshold_display_stock || 0,
  };
}

function mapLabels(labels: ?{ [string]: boolean } = {}): ProductLabelsType {
  const nonNil = (value, defaultValue) => (value == null ? defaultValue : value);
  return {
    discount: nonNil(
      idx(labels, (_) => _.discount),
      false
    ),
    new: nonNil(
      idx(labels, (_) => _.new),
      false
    ),
    sale: nonNil(
      idx(labels, (_) => _.sale),
      false
    ),
    second_quality: nonNil(
      idx(labels, (_) => _.second_quality),
      false
    ),
  };
}

function mapLabelsTest(payload: Array<ProductLabelsTestType>): Array<ProductLabelsTestType> {
  return payload.map((i) => ({
    code: i.code || '',
    label: i.label || '',
    css_background: i.css_background || '',
  }));
}

function mapCategoryLabels(labels: any): ProductLabelsType {
  return (
    labels || {
      discount: false,
      new: false,
      sale: false,
      second_quality: false,
    }
  );
}

function mapCategoryLabelsTest(
  labels_test: Array<ProductLabelsTestType>
): Array<ProductLabelsTestType> {
  return (
    labels_test || [
      {
        code: '',
        label: '',
        css_background: '',
      },
    ]
  );
}

//
// ProductContainer
//

export async function createProductContainer(appContainer: AppContainerType) {
  const { fetchEnv, appCache } = appContainer.context;
  return new ProductContainer<ParamsPayload, ProductParams>({
    fetchEnv,
    appCache,
    mapParams,
    mapLabels,
    mapLabelsTest,
  });
}

export function useProductContainer(): ProductContainer<ParamsPayload, ProductParams> {
  return useContainer(ProductContainer);
}

export async function createFavoritesContainer(appContainer: AppContainerType) {
  const initialContainer = await appContainer.resolve(createInitialContainer);
  const initialState = await initialContainer.getInitialState();

  const mapPayload = (data) => {
    return mapVariantToProductItem(data, { mapParams, mapLabels, mapLabelsTest });
  };
  const { fetchEnv, appCache } = appContainer.context;

  return new FavoritesContainer({
    fetchEnv,
    appCache,
    mapPayload,
    initialState: {
      favoritesCount: initialState.favorites,
    },
  });
}

export function useFavoritesContainer(): FavoritesContainer {
  return useContainer(FavoritesContainer);
}

export async function createSearchContainer(
  appContainer: AppContainerType
): Promise<SearchContainer> {
  type QuickSearchResult = {|
    id: string,
    name: string,
    code: string,

    main_image_url: string,

    price: ?number,
    quantity: number,
    _suggestion_text: string,
  |};

  function mapQuickPayload(payload: QuickSearchResult): QuickSearchItem {
    return {
      id: String(payload.id),
      code: payload.code,
      name: payload.name,
      images: [{ thumbnail: payload.main_image_url || '', original: payload.main_image_url || '' }],
    };
  }

  const mapPayload = (data) => {
    return mapVariantToProductItem(data, { mapParams, mapLabels, mapLabelsTest });
  };

  const { fetchEnv, appCache } = appContainer.context;

  const productContainer = await appContainer.resolve(createProductContainer);
  return new SearchContainer({
    productContainer,
    fetchEnv,
    appCache,
    mapPayload,
    mapQuickPayload,
  });
}

export function useSearchContainer(): SearchContainer {
  return useContainer(SearchContainer);
}

//
// Cart
//

export async function createCartContainer(appContainer: AppContainerType): Promise<CartContainer> {
  const initialContainer = await appContainer.resolve(createInitialContainer);
  const initialState = await initialContainer.getInitialState();

  const { fetchEnv } = appContainer.context;
  const productsContainer = await appContainer.resolve(createProductContainer);
  return new CartContainer({
    fetchEnv,
    productsContainer,
    initialState: {
      total: initialState.cart.total,
      itemsCount: initialState.cart.itemsCount,
    },
  });
}

export function useCartContainer(): CartContainer {
  return useContainer(CartContainer);
}

//
// Category
//

export function useCategoryContainer(): CategoryContainer {
  return useContainer(CategoryContainer);
}

export async function createCategoryProductsContainer(appContainer: AppContainerType) {
  const { fetchEnv, appCache } = appContainer.context;
  return new CategoryProductsContainer<ParamsPayload, ProductParams>({
    fetchEnv,
    appCache,
    mapParams,
    mapLabels,
    mapLabelsTest,
  });
}

export function useCategoryProductsContainer(): CategoryProductsContainer<
  ParamsPayload,
  ProductParams
> {
  return useContainer(CategoryProductsContainer);
}

export async function createCategoryContainer(
  appContainer: AppContainerType
): Promise<CategoryContainer> {
  const { fetchEnv, appCache } = appContainer.context;
  const cache = appCache.getOrCreateCache('CategoryContainer');
  return new CategoryContainer({
    fetchEnv,
    cache,
    mapLabels: mapCategoryLabels,
    mapLabelsTest: mapCategoryLabelsTest,
  });
}

//
// Profile
//

export function useProfileContainer() {
  return useContainer(ProfileContainer);
}

export async function createMenuContainer(appContainer: AppContainerType): Promise<MenuContainer> {
  const { fetchEnv, appCache } = appContainer.context;
  const preferenceContainer = await appContainer.resolve(createPreferenceContainer);
  const cache = appCache.getOrCreateCache('MenuContainer.menu');
  return new MenuContainer({
    preferenceContainer,
    cache,
    fetchEnv,
    mapLabels: mapCategoryLabels,
    mapLabelsTest: mapCategoryLabelsTest,
  });
}

//
// Generic (but only for rudorfer)
//

export async function createGlobalContainer(
  appContainer: AppContainerType
): Promise<GlobalContainer> {
  const { fetchEnv, appCache, djreactState } = appContainer.context;
  const i18n = await appContainer.resolve(createI18nObject);
  const initialContainer = await appContainer.resolve(createInitialContainer);
  const initialState = await initialContainer.getInitialState();
  return new GlobalContainer({
    fetchEnv,
    initialState,
    djreactState,
    i18n,
    appCache,
    appContainer,
  });
}

export function useGlobalContainer() {
  return useContainer(GlobalContainer);
}

export function createInitialContainer(appContainer: AppContainerType): InitialContainer {
  const { fetchEnv, initialData } = appContainer.context;
  return new InitialContainer({ initialData, fetchEnv });
}

export async function createWatchdogContainer(
  appContainer: AppContainerType
): Promise<WatchdogContainer> {
  const initialContainer = await appContainer.resolve(createInitialContainer);
  const initialState = await initialContainer.getInitialState();
  const initialWatchCount = initialState.watched;
  const { fetchEnv, appCache } = appContainer.context;
  const mapPayload = (data) => {
    return mapVariantToProductItem(data, { mapParams, mapLabels, mapLabelsTest });
  };

  return new WatchdogContainer({ initialWatchCount, fetchEnv, appCache, mapPayload });
}

export function useWatchdogContainer(): WatchdogContainer {
  return useContainer(WatchdogContainer);
}

export async function createHomepageContainer(
  appContainer: AppContainerType
): Promise<HomepageContainer> {
  const { fetchEnv, appCache } = appContainer.context;
  const { profile } = await appContainer.resolve(createInitialState);

  const mapProduct = (data) => {
    return mapVariantToProductItem(data, { mapParams, mapLabels, mapLabelsTest });
  };

  return new HomepageContainer({ fetchEnv, appCache, mapProduct, profile });
}

export function useHomepageContainer(): HomepageContainer {
  return useContainer(HomepageContainer);
}

export async function createContactContainer(
  appContainer: AppContainerType
): Promise<ContactContainer> {
  const { fetchEnv } = appContainer.context;

  return new ContactContainer({ fetchEnv });
}

export function useContactContainer(): ContactContainer {
  return useContainer(ContactContainer);
}

export async function createExternalCatalogContainer(
  appContainer: AppContainerType
): Promise<ExternalCatalogContainer> {
  const { fetchEnv } = appContainer.context;
  return new ExternalCatalogContainer({ fetchEnv });
}

export function useExternalCatalogContainer(): ExternalCatalogContainer {
  return useContainer(ExternalCatalogContainer);
}

//
// Generic
//

export function createSentryLogger(appContainer: AppContainerType): SentryLogger {
  const { logger } = appContainer.context.pluginProps.sentry;
  return logger;
}

export const containerConfig = {
  singletons: [
    createGlobalContainer,
    createLoginContainer,
    createMenuContainer,
    createInitialContainer,
    createProfileContainer,
    createProductContainer,
    createCategoryProductsContainer,
    createPreferenceContainer,
    createCartContainer,
    createPageContainer,
    createSentryLogger,
    createRegisterContainer,
    createWebAnalyticsContainer,
  ],
  dependencies: [createI18nObject],
};

export type ProductContainerType = ProductContainer<ParamsPayload, ProductParams>;
export type PreferenceContainerType = PreferenceContainer;
export type { SentryLogger, InitialContainer, PageContainer, FavoritesContainer };

export { GlobalContainer };

// @flow

import React from 'react';
import type { ProductLabelsTestType } from '../appState/types';
import { getCategoryLabelColor } from '../app/config';
import styled from '@emotion/styled';

import { useGlobalContainer } from '../app/use';
import { convertToEnumValue } from './ProductLabels';

const Label = styled.p`
  font-weight: 700;
  background: ${(props) => props.color};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
`;

const MenuLabel = ({ color, text }) => <Label color={color}>{text}</Label>;

type LocalProps = {|
  labels_test: Array<ProductLabelsTestType>,
|};

export function MenuItemLabels(props: LocalProps) {
  const { labels_test } = props;
  const globalContainer = useGlobalContainer();

  if (globalContainer.isSpecificSite('rudorfer.dealer')) return null;

  const fillColor = (label) => {
    const codeToEnum = convertToEnumValue(label.code);
    return label.css_background === '' ? getCategoryLabelColor(codeToEnum) : label.css_background;
  };

  return (
    <div>
      {labels_test &&
        labels_test.map((label) => <MenuLabel color={fillColor(label)} text={label.label} />)}
    </div>
  );
}

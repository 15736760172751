// @flow

// framework
import { Container } from '@whys/app/lib/state';
import { fetchJSON } from '@whys/fetch/lib/json';
import type { CursorCache } from '@whys/fetch/lib/pagination/CursorCache';
import { create as createCursorCache } from '@whys/fetch/lib/pagination/CursorCache';
// whyshop
import type { ProductItem } from '../whyshop/models/product';
import { getNextUrl } from '../whyshop/requests';
// app
import type { FetchEnvType, AppCacheType } from '../types/app';

async function fetchJSONAsBool(props): Promise<boolean> {
  const result = await fetchJSON(props);
  return result.status === 'ok';
}

const resources = {
  addWatchdog: (id: string) => ({
    url: `/api/product/variant/${id}/watchdog/`,
    method: 'POST',
  }),
  deleteWatchdog: (id: string) => ({
    url: `/api/product/variant/${id}/watchdog/`,
    method: 'DELETE',
  }),
  listWatchdogs: {
    url: '/api/product/variant/watchdogs/',
  },
};

type LocalProps = {|
  fetchEnv: FetchEnvType,
  appCache: AppCacheType,

  mapPayload: (any) => ProductItem,

  initialWatchCount: number,
|};

type LocalState = {|
  tmpWatchdogs: Array<ProductItem>,
  watchdogsCount: number,
  loadingState: 'initial' | 'loading' | 'loaded',
|};

// NotePrototype(simon): lot of impl details, should be in whyshop package

export class WatchdogContainer extends Container<LocalState> {
  state: LocalState;
  props: LocalProps;

  __tmpWatched: Set<string> = new Set();
  __tmpUnwatched: Set<string> = new Set();

  watchdogProductList: CursorCache<ProductItem>;

  constructor(props: LocalProps) {
    super();

    const { fetchEnv, appCache, mapPayload, initialWatchCount } = props;

    this.watchdogProductList = createCursorCache({
      fetchEnv,
      cache: appCache.createCache(),
      initialUrl: resources.listWatchdogs.url,
      getNextUrl: ({ response }) => {
        return getNextUrl(response);
      },
      mapData: mapPayload,
    });

    this.state = {
      tmpWatchdogs: [],
      watchdogsCount: initialWatchCount || 0,
      loadingState: 'initial',
    };
    this.props = props;
  }

  async addWatchdog(id: string): Promise<boolean> {
    const { fetchEnv } = this.props;
    const { watchdogsCount } = this.state;

    this.__tmpWatched.add(id);
    this.__tmpUnwatched.delete(id);
    const result = await fetchJSONAsBool({ ...resources.addWatchdog(id), env: fetchEnv });
    this.reloadWatchdogProducts();
    this.setState({ watchdogsCount: watchdogsCount + 1 });
    return result;
  }

  async deleteWatchdog(id: string): Promise<boolean> {
    const { fetchEnv } = this.props;
    const { watchdogsCount } = this.state;

    this.__tmpWatched.delete(id);
    this.__tmpUnwatched.add(id);
    const result = await fetchJSONAsBool({ ...resources.deleteWatchdog(id), env: fetchEnv });
    this.reloadWatchdogProducts();
    this.setState({ watchdogsCount: watchdogsCount - 1 });
    return result;
  }

  isWatched(id: string): boolean {
    const { loadingState } = this.state;
    if (loadingState !== 'loaded' && loadingState !== 'loading') {
      this.setState({ loadingState: 'loading' });
      (async () => {
        await this.reloadWatchdogProducts();
      })();
    }
    return this.__tmpWatched.has(id);
  }

  selectWatchdogProducts(): Array<ProductItem> {
    return this.state.tmpWatchdogs;
  }

  selectWatchdogsCount(): number {
    return this.state.watchdogsCount;
  }

  async reloadWatchdogProducts(): Promise<boolean> {
    this.watchdogProductList.clearAllItems();
    const items = await this.watchdogProductList.getAllItems();
    this.setState({ tmpWatchdogs: items, watchdogsCount: items.length, loadingState: 'loaded' });
    items.forEach((i) => this.__tmpWatched.add(i.id));
    return true;
  }
}

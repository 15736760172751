// @flow

import { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

type LocalProps = {|
  initialValue?: number,
  onChange?: (number) => any,
  delay?: number,
|};

export function useDebounceQuantity(props: LocalProps) {
  const initialQuantity = props.initialValue || 0;
  const [quantity, setQuantity] = useState<number>(initialQuantity);
  const [debouncedCallback] = useDebouncedCallback(
    (quantity) => props.onChange && props.onChange(quantity),
    props.delay || 1000
  );

  useEffect(() => {
    if (quantity !== initialQuantity) {
      debouncedCallback(quantity);
    }
  }, [quantity]);

  return [quantity, setQuantity];
}

// @flow

import idx from 'idx';
import type { Node } from 'react';
import { Container } from '@whys/app/lib/state';
// import { fetchJSON, getJSON } from '@whys/fetch/lib/json';
import { create as createResourceCache } from '@whys/fetch/lib/resources/ResourceCache';
import type { ResourceCache } from '@whys/fetch';

import type { ImageModel } from '../whyshop/types';
import type { ImageJsonType, PageAttributes } from '../whyshop/jsonTypes';
import { defaultMenuStatus } from '../app/config';

import type { MenuVariantEnum } from '../appState/types';
import type { FetchEnvType, CacheType } from '../types/app';
import type { PreferenceContainerType } from '../types/containers';

const resources = {
  listMenu: { url: `/api/navigation/mainmenu/` },
  listFooter: { url: '/api/navigation/footer/' },
};

function mapImage(payload): ImageModel {
  return {
    original: idx(payload, (_) => _.main_image.big) || '',
    thumbnail: idx(payload, (_) => _.main_image.small) || '',
  };
}

type LabelsJSON = { [string]: boolean };

type LabelsTestJSON = { [string]: string };

type CategoryDetail = {|
  id: string,
  name: string,
  long_name: string,
  attributes: Array<{| id: number, name: string, value: string |}>,
  description: ?string,
  main_image?: ImageJsonType,
  images: ImageJsonType[],
  custom_url?: string,
  parent_id?: number,
  children: CategoryDetail[],
  icon: ?string,
  labels?: LabelsJSON,
  labels_test?: LabelsTestJSON,
  page_attributes?: PageAttributes,
  is_special: boolean,
|};

type CategoryCommonProps = {|
  id: string,
  title: string,
  categoryUrl: string,
  image: ImageModel,
  labels: LabelsJSON,
  labels_test: LabelsTestJSON,
  isSpecial: boolean,
|};

type SubCategoryItem = {|
  ...CategoryCommonProps,
|};

type TopCategoryItem = {|
  ...CategoryCommonProps,
  iconUrl: string,
  // NotePrototype... either iconUrl or icon
  // icon?: React.Node,
  icon?: Node,
  subs: SubCategoryItem[],
|};

type FooterProps = {|
  id: string,
  title: string,
  type: string,
  identifier: string,
  image: ImageModel,
  url: string,
  children: FooterProps[]
|};

function mapCategoryChild(payload, mapLabels, mapLabelsTest): SubCategoryItem {
  const subId = String(payload.id);
  return {
    id: subId,
    categoryUrl: idx(payload, (_) => _.page_attributes.url) || '',
    image: mapImage(payload),
    title: payload.name,
    labels: mapLabels(payload.labels),
    labels_test: mapLabelsTest(payload.labels_test),
    isSpecial: payload.is_special,
  };
}

function mapMenu(
  payload: CategoryDetail,
  opts: {| mapLabels: (*) => *, mapLabelsTest: (*) => * |}
): TopCategoryItem {
  const id = String(payload.id);

  return {
    id,

    title: payload.name,
    labels: opts.mapLabels(payload.labels),
    labels_test: opts.mapLabelsTest(payload.labels_test),
    categoryUrl: idx(payload, (_) => _.page_attributes.url) || '',
    image: mapImage(payload),

    isSpecial: payload.is_special,
    // top-level only

    // NotePrototype: add some icon so it can be used on mobile
    iconUrl: payload.icon || '',
    subs: payload.children.map((_) => mapCategoryChild(_, opts.mapLabels, opts.mapLabelsTest)),
    // isSale: false,
  };
}

type CategoryDetailInfo = { id: string, title: string };

type LocalState = {|
  menuStatus: MenuVariantEnum,
  prevMenuStatus: MenuVariantEnum,
  // menu items
  categories: Array<TopCategoryItem>,
  footer: Array<FooterProps>,
  footerFetched: boolean,
  categoriesFetched: boolean,
  categoryInfos: Map<string, CategoryDetailInfo>,
  categoriesNormalized: boolean,
|};

type LocalProps = {|
  fetchEnv: FetchEnvType,
  cache: CacheType<$FlowFixMe>,

  // NotePrototype(simon): add types
  mapLabels: (any) => any,
  mapLabelsTest: (any) => any,

  preferenceContainer: PreferenceContainerType,
|};

// function nonNillOrDef<T>(value: ?T, defaultValue: T): T {
//   return value === null || value === undefined ? defaultValue : value;
// }

export class MenuContainer extends Container<LocalState> {
  state: LocalState;
  props: LocalProps;

  categoryCache: ResourceCache<TopCategoryItem[]>;
  footerCache: ResourceCache<FooterProps[]>;

  constructor(props: LocalProps) {
    super();

    const { mapLabels, mapLabelsTest, fetchEnv, cache } = props;

    this.categoryCache = createResourceCache({
      url: resources.listMenu.url,
      fetchEnv,
      cache,
      mapData: (list) => list.map((_) => mapMenu(_, { mapLabels, mapLabelsTest })),
      getEmptyResource: () => {
        return ([]: any);
      },
    });

    this.footerCache = createResourceCache({
      url: resources.listFooter.url,
      fetchEnv,
      cache,
      mapData: (list) => list,
      getEmptyResource: () => {
        return ([]: any);
      },
    });

    this.state = {
      menuStatus: defaultMenuStatus,
      prevMenuStatus: defaultMenuStatus,

      // menu items
      categories: [],
      categoriesFetched: false,
      footer: [],
      footerFetched: false,
      categoryInfos: new Map(),
      categoriesNormalized: false,
    };

    this.props = props;
  }

  //
  // Mutations
  //

  // NoteReview(simon): sync only open/close change

  async closeMenu() {
    return this._setStateAndSync({ menuStatus: 'closed' });
  }

  async openMenu() {
    return this._setStateAndSync({ menuStatus: 'open' });
  }

  async toggleExpanded() {
    return this._setStateAndSync({ menuStatus: this.isExpanded() ? 'closed' : 'open' });
  }

  async setExpanded(isExpanded: boolean) {
    return this._setStateAndSync({ menuStatus: isExpanded ? 'open' : 'closed' });
  }

  async toggleItemsExpanded() {
    const updated = this.state.menuStatus === 'open' ? 'openAll' : 'open';
    return this._setStateAndSync({ menuStatus: updated });
  }

  // Temporary state

  forceMenuStatus(v: MenuVariantEnum) {
    const { menuStatus } = this.state;
    this.setState({ menuStatus: v, prevMenuStatus: menuStatus });
  }

  restoreMenuStatus() {
    const { prevMenuStatus } = this.state;
    this.setState({ menuStatus: prevMenuStatus });
  }

  //
  // Async tasks
  //

  async loadMenuStatus() {
    const menuStatus = await this._fetchMenuStatus();
    await this.setState({ menuStatus });
  }

  //
  // Selectors
  //

  isMenuStatus(v: MenuVariantEnum): boolean {
    return this.state.menuStatus === v;
  }

  // is expanded/collapsed (width)
  isExpanded(): boolean {
    return this.isMenuStatus('openAll') || this.isMenuStatus('open');
  }

  // items expanded/collapsed
  areItemsExpanded(): boolean {
    return this.isMenuStatus('openAll');
  }

  //
  // Private helpers
  //

  _isOpen() {
    return;
  }

  async _saveMenuStatus(menuStatus: MenuVariantEnum): Promise<void> {
    const { preferenceContainer } = this.props;
    await preferenceContainer.updateSession({ menuStatus });
  }

  async _fetchMenuStatus(): Promise<MenuVariantEnum> {
    const { preferenceContainer } = this.props;
    const sessionData = await preferenceContainer.fetchSession();
    return sessionData.menuStatus;
  }

  async _setStateAndSync(state: $Shape<LocalState>) {
    await this.setState(state);
    await this._saveMenuStatus(state.menuStatus);
  }

  //
  // Menu items
  //

  async fetchCategories(): Promise<TopCategoryItem[]> {
    const categories = await this.categoryCache.getResource();
    this.setState({ categories, categoriesFetched: true });
    return categories;
  }

  async fetchFooter(): Promise<TopCategoryItem[]> {
    const footer = await this.footerCache.getResource();
    this.setState({ footer, footerFetched: true });
    return footer;
  }

  async normalizeCategories() {
    const { categoriesFetched, categoriesNormalized } = this.state;
    if (categoriesNormalized) {
      return;
    }
    if (!categoriesFetched) {
      await this.fetchCategories();
    }

    const categoryInfos = new Map();

    const normalize = (cat) => {
      categoryInfos.set(cat.id, { id: cat.id, title: cat.title });
      if (cat.subs) {
        cat.subs.forEach(normalize);
      }
    };

    const { categories } = this.state;
    categories.forEach(normalize);

    this.setState({ categoryInfos });
  }

  async loadMenuItems(): Promise<void> {
    await this.fetchCategories();
  }

  async loadFooterItems(): Promise<void> {
    await this.fetchFooter();
  }

  selectCategoriesLoaded(): boolean {
    return this.state.categoriesFetched;
  }

  selectFooterLoaded(): boolean {
    return this.state.footerFetched;
  }

  selectMenuItems() {
    return this.state.categories;
  }

  selectFooterItems() {
    return this.state.footer;
  }
}

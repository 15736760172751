// @flow

import type { ProductLabelsEnum } from '../appState/types';
import type { I18nType } from '../types/npm';

export const cartElementClassName = 'whyshop-cart';

export const defaultMenuStatus = 'open';
export const defaultListLayout = 'row';
export const PaginatedProductsCount = 36;

const labelColors = {
  new: '#6aa842',
  sale:
    'linear-gradient(90deg, rgba(226,205,132,1) 0%, rgba(165,147,86,1) 28%, rgba(226,205,132,1) 100%);',
  discount:
    'linear-gradient(90deg, rgba(211,211,211,1) 0%, rgba(146,146,146,1) 28%, rgba(211,211,211,1) 100%);',
  second_quality: '#000000',
};

export const defaultSessionState = {
  menuStatus: defaultMenuStatus,
  listLayout: defaultListLayout,
};

export const zIndexes = {
  AppBar: 1100,
  popupWindow: 1300,
  animatedCart: 1400,
};

// product vs category clear API

export function getProductLabelColor(label: ProductLabelsEnum) {
  return labelColors[label];
}

export function getProductLabelName(label: ProductLabelsEnum, i18n: I18nType) {
  return {
    new: i18n.t`New`,
    second_quality: i18n.t`Second quality`,
    sale: i18n.t`Sale`,
    discount: i18n.t`Discount`,
  }[label];
}

export const getCategoryLabelColor = getProductLabelColor;
export const getCategoryLabelName = getProductLabelName;

export const allLanguageCodes = ['en', 'cs', 'sk', 'de', 'fr', 'hu', 'pl'];

// @flow

import idx from 'idx';
import type { FieldConfig } from '../forms';

/**
 * @return Props of native HTML5 validation that can be used in inputProps (material-ui) or spread to
 * native input element.
 */
export function toInputProps(
  config: FieldConfig
): {|
  maxLength: ?number,
  minLength: ?number,
  max: ?number,
  min: ?number,
  required: boolean,
  disabled: boolean,
|} {
  const required = config.required === false ? false : true;
  return {
    maxLength: (() => {
      const val = config.maxLength;
      if (typeof val === 'number') {
        return val;
      }
      return val && val.value;
    })(),
    minLength: (() => {
      const val = config.minLength;
      if (typeof val === 'number') {
        return val;
      }
      return val && val.value;
    })(),
    min: idx(config, (_) => _.min.value),
    max: idx(config, (_) => _.max.value),
    required,
    disabled: !!config.disabled,
  };
}

// @flow

// @todo: doesnt work anymore
export const sentryDSN = 'https://a64df4bdf370dd55cd84ea99a2ec6c26@o4507700621541376.ingest.de.sentry.io/4507700875034704';

//

type EnvironmentEnum = 'development' | 'production';

function coerceEnvironment(env): EnvironmentEnum {
  if (env === 'development') {
    return env;
  }
  return 'production';
}

export const environment = coerceEnvironment(process.env.NODE_ENV);

// @flow

import * as React from 'react';
import styled from '@emotion/styled';
import { styledTheme, useTheme } from '../app/theme';
import type { ThemeType } from '../types/app';

import Collapse from '@material-ui/core/Collapse';
import * as baseUI from '../baseUI';
import type { TopMenuItem } from '../appState/types';
import IconButton from '@material-ui/core/IconButton';
import ArrowDown from '@material-ui/icons/ExpandMore';
import ArrowUp from '@material-ui/icons/ExpandLess';
import { withStyles } from '@material-ui/core';
import BasicPopover from '../appUI/BasicPopover';
import MenuItemPopover from './MenuItemPopover';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import { TextLink, AlfaboxTextLink } from '../app/link';
import categoryPlaceholderUrl from '../icons/CategoryPlaceholder.svg';
import { InvisibleButton } from '../styled/reset';
import { MenuItemLabels } from '../rudorfer/MenuItemLabels';
import { getProductLabelColor } from '../app/config';
import { SPECIAL_ITEMS_INDEX } from './AppMenu';

import MenuSubItem from './MenuSubItem';

const AppMenuIcon = styled.div`
  img {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

const ExpandButton = withStyles({
  root: {
    padding: 5,
  },
})((props: { classes: *, onClose: * }) => <IconButton {...props} />);

const OurIconButton = styled.button`
  ${InvisibleButton};
  outline: none;
  padding-right: 18px;
  padding-top: 4px;
`;

const Title = styled.div`
  font-size: ${(props) => props.fontSize || '0.9em'};
  font-weight: ${(props) => props.fontWeight || 'bold'};
  display: inline-block;
  color: ${(props) => props.fontColor || 'inherit'};
  backgroung-color: grey;
`;

const Item = styled.div`
  cursor: pointer;
  background-color: ${(props) =>
    props.hasBackground ? styledTheme.color('primaryBg')(props) : 'white'};
  &:hover {
    background-color: #f6f6f8;
  }
`;

const SubItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
  padding: 4px 2px 4px 12px;
  text-decoration: none;
  &:hover {
    background-color: #f6f6f8;
  }
`;

const SubItemTitle = styled.span`
  font-weight: ${(props) => (props.isHighlighted ? 'bold' : 'inherit')};
  background: ${(props) => props.bgcolor};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
`;

type LocalProps = {|
  item: TopMenuItem,
  menuClosed: boolean,
  hasPopover: boolean,
  onShowPopover: Function,
  onHidePopover: Function,
  onRedirect: Function,
  isOpen: boolean,
  onExpandItem: Function,

  onIconClick: (TopMenuItem) => void,

  // style
  isHighlighted: boolean,
  theme: ThemeType,
  index: number,
  isAlfabox?: boolean,
  isSmall?: boolean,
  isDealer?: boolean,
|};
type LocalState = {| touched: boolean, hasPopover: boolean |};

class MenuItem extends React.Component<LocalProps, LocalState> {
  state = { hasPopover: false, touched: false };

  itemRef = null;
  iconRef = null;

  onExpand = () => {
    this.props.onExpandItem();
  };

  onShowPopover = () => {
    if (!this.state.touched) {
      this.props.onShowPopover(this.props.item.id);
    }
    this.setState({ touched: false });
  };

  onHidePopover = () => {
    if (!this.state.touched) {
      this.props.onHidePopover();
    }
    this.setState({ hasPopover: false, touched: false });
  };

  getSubItemType = (idx: number) => {
    switch (idx) {
      case 0:
        return 'discount';
      case 1:
        return 'sale';
      case 2:
        return 'second_quality';
      default:
        return '';
    }
  };

  renderRudorferItem = () => {
    const { item, hasPopover, onIconClick, menuClosed, isOpen, isHighlighted, index } = this.props;
    const subs = item.subs;
    const hasSubs = !!subs.length;
    const newItem = this.props.isDealer ? SPECIAL_ITEMS_INDEX : SPECIAL_ITEMS_INDEX + 1;
    const type = index === newItem ? 'new' : '';
    const mainColor = (type && getProductLabelColor(type)) || this.props.theme.colors.primary;

    const iconUrl = item.iconUrl || categoryPlaceholderUrl;

    const checkEquality = (url: string) => {
      if (typeof window !== 'undefined') {
        return window.location.pathname === url;
      }
      return false;
    };

    return (
      <>
        <Item hasBackground={hasPopover || (isOpen && hasSubs)}>
          <ClickAwayListener onClickAway={() => this.state.touched && this.onHidePopover}>
            <div
              ref={(ref) => (this.itemRef = ref)}
              onMouseLeave={this.onHidePopover}
              onMouseEnter={this.onShowPopover}
              onTouchStart={() => this.setState({ touched: true })}
              onClick={this.onExpand}
              style={{
                backgroundColor: checkEquality(item.categoryUrl) ? '#f6f6f8' : '',
              }}
            >
              <baseUI.FlexRow
                alignItems="center"
                justifyContent="space-between"
                color={mainColor}
                padding="10px 5px"
              >
                <baseUI.FlexRow alignItems="center" padding="0px 10px">
                  <OurIconButton
                    title={hasSubs ? '' : item.title}
                    onClick={(e) => {
                      e.stopPropagation();
                      onIconClick(item);
                    }}
                    ref={(ref) => (this.iconRef = ref)}
                    onTouchStart={() => {
                      if (menuClosed) {
                        this.setState(({ hasPopover }) => ({
                          hasPopover: !hasPopover,
                          touched: true,
                        }));
                      }
                    }}
                  >
                    {item.icon ? (
                      item.icon
                    ) : (
                      <AppMenuIcon>
                        <img src={iconUrl} alt="" />
                      </AppMenuIcon>
                    )}
                  </OurIconButton>
                  <div
                    onClick={(e) => {
                      this.props.onRedirect();
                      if (!this.props.isOpen) {
                        this.props.onExpandItem();
                      }
                      e.stopPropagation();
                    }}
                  >
                    <TextLink to={item.categoryUrl}>
                      <Title
                        fontColor={
                          index === SPECIAL_ITEMS_INDEX && !this.props.isDealer
                            ? this.props.theme.colors.errorText
                            : mainColor
                        }
                        data-cy="menu-item"
                      >
                        {item.title}
                      </Title>
                    </TextLink>
                  </div>
                </baseUI.FlexRow>
                <MenuItemLabels labels_test={item.labels_test} />
                {hasSubs && (
                  <ExpandButton color="inherit">
                    {isOpen ? <ArrowUp /> : <ArrowDown />}
                  </ExpandButton>
                )}
              </baseUI.FlexRow>
              {hasSubs && (
                <BasicPopover
                  isVisible={this.state.hasPopover || hasPopover}
                  anchorEl={this.props.menuClosed ? this.iconRef : this.itemRef}
                  popoverId={item.id}
                  placement="right-start"
                >
                  <MenuItemPopover
                    items={item.subs}
                    categoryUrl={item.categoryUrl}
                    title={this.props.menuClosed ? item.title : ''}
                  />
                </BasicPopover>
              )}
            </div>
          </ClickAwayListener>
        </Item>
        <Collapse in={hasSubs && isOpen} mountOnEnter unmountOnExit>
          <baseUI.Block backgroundColor="white" padding="10px">
            {subs.map((subItem, idx) => {
              const id = subItem.id;
              const type = index === SPECIAL_ITEMS_INDEX ? this.getSubItemType(idx) : '';
              const areUrlsEqual = checkEquality(subItem.categoryUrl);
              return (
                <TextLink key={id} to={subItem.categoryUrl}>
                  <SubItem
                    onClick={this.props.onRedirect}
                    isAlfabox={false}
                    style={{
                      backgroundColor: areUrlsEqual ? '#f6f6f8' : '',
                      borderRadius: areUrlsEqual ? '5px' : '',
                    }}
                  >
                    <SubItemTitle
                      bgcolor={(type && getProductLabelColor(type)) || mainColor}
                      isHighlighted={isHighlighted}
                    >
                      {subItem.title}
                    </SubItemTitle>
                    <MenuItemLabels labels_test={subItem.labels_test} />
                  </SubItem>
                </TextLink>
              );
            })}
          </baseUI.Block>
        </Collapse>
      </>
    );
  };

  renderAlfaboxItem = () => {
    const { item, hasPopover, isOpen } = this.props;
    const subs = item.subs;
    const hasSubs = !!subs.length;
    const mainColor = '#000';

    if (!item.title) {
      return null;
    }

    const alfaboxItemStyle = {
      backgroundColor: '#e6e6e6',
    };

    return (
      <Item hasBackground={hasPopover || (isOpen && hasSubs)} style={alfaboxItemStyle}>
        <ClickAwayListener onClickAway={() => this.state.touched}>
          <div
            ref={(ref) => (this.itemRef = ref)}
            onTouchStart={() => this.setState({ touched: true })}
            onClick={this.onExpand}
          >
            <baseUI.FlexRow
              alignItems="center"
              justifyContent="space-between"
              color={mainColor}
              padding="10px 10px 0 1.9rem"
              onClick={(e) => {
                this.props.onRedirect();
                if (!this.props.isOpen) {
                  this.props.onExpandItem();
                }
                e.stopPropagation();
              }}
            >
              <AlfaboxTextLink to={item.categoryUrl}>
                <Title
                  fontSize="1.3rem"
                  fontWeight="normal"
                  fontColor={hasSubs && isOpen ? styledTheme.color('secondary') : ''}
                >
                  {item.title}
                </Title>
              </AlfaboxTextLink>
            </baseUI.FlexRow>
          </div>
        </ClickAwayListener>
        <Collapse in={hasSubs && isOpen} mountOnEnter unmountOnExit>
          <baseUI.Block backgroundColor={'#e6e6e6'}>
            {subs.map((subItem, idx) => {
              const id = subItem.id;
              return (
                <MenuSubItem
                  key={idx}
                  id={id}
                  mainColor={mainColor}
                  subItem={subItem}
                  onRedirect={this.props.onRedirect}
                />
              );
            })}
          </baseUI.Block>
        </Collapse>
      </Item>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.props.isAlfabox ? this.renderAlfaboxItem() : this.renderRudorferItem()}
      </React.Fragment>
    );
  }
}

export default (props: $Diff<LocalProps, { theme: * }>) => {
  const theme = useTheme();
  return <MenuItem {...props} theme={theme} />;
};

// @flow
import * as React from 'react';

import { useInlineResult } from '../reactUtils/inlineResult';
import LoadingInline from '../appUI/LoadingInline';
import { useFetchEnv } from '../app/use';
import { fetchJSON } from '@whys/fetch/lib/json';

import { useTheme, styledTheme } from '../app/theme';

import { email } from '../forms/preset';
import { useAppForm, FormContext } from '../forms';
import { RHFValidatedTextField } from '../formInputs/rhf-inputs';
import { useLingui } from '../app/lingui';
import { themeMUI, useLanguageContainer, useWebAnalyticsContainer } from '../app/state';

import * as baseUI from '../baseUI';
import * as appUI from '../appUI';
import { PrimaryButton } from '../materialUI';
import { Trans } from '@lingui/react';

import styled from '@emotion/styled';
import { useMediaQuery } from '@material-ui/core';
import { useFacebookPixelEvents } from '../pkg.facebookPixel/hooks';

const NewsletterTitle = styled.h2`
  & > span {
    color: ${styledTheme.color('errorText')};
  }
`;

const inputConfigs = {
  email,
};

function FullCenteredBlock(props) {
  return <baseUI.FlexColumn alignItems="center" justifyContent="center" {...props} />;
}

type NewsletterSubscribeErrors = 'unknown' | 'SubscriberAlreadyRegistered';

export const NewsLetterForm = (props: *) => {
  const theme = useTheme();
  const i18n = useLingui();
  const env = useFetchEnv();
  const languageContainer = useLanguageContainer();
  const appForm = useAppForm();
  const isExtraSmall = useMediaQuery(themeMUI.breakpoints.down('xs'));
  const isSmall = useMediaQuery(themeMUI.breakpoints.down('sm'));
  const webAnalytics = useWebAnalyticsContainer();
  const facebookPixelEvents = useFacebookPixelEvents();

  const [newsletterFormEl, resolveResult] = useInlineResult<NewsletterSubscribeErrors>({
    renderOk: ({ size }) => (
      <FullCenteredBlock {...size} fontWeight="bold" color={theme.color('okText')}>
        <div>
          <Trans>You've been successfully subscribed, thank you!</Trans>
        </div>
      </FullCenteredBlock>
    ),
    renderError: ({ size, onRetry, isErrorType }) => {
      const isAlreadyRegistred = isErrorType('SubscriberAlreadyRegistered');
      const errorMessage = (() => {
        if (isAlreadyRegistred) {
          return i18n.t`You already signed up with this email address before, and you’re currently subscribed.`;
        }
        return i18n.t`You couldn't be subscribed.`;
      })();
      return (
        <FullCenteredBlock
          {...size}
          fontWeight="bold"
          color={isAlreadyRegistred ? '' : theme.color('errorText')}
        >
          <div>
            <appUI.ThemedText display="block" textAlign="center">
              {errorMessage}
            </appUI.ThemedText>
            <appUI.HorizontalSpace />
            {!isAlreadyRegistred && (
              <PrimaryButton onClick={onRetry}>
                <Trans>Retry</Trans>
              </PrimaryButton>
            )}
          </div>
        </FullCenteredBlock>
      );
    },
    renderLoading: ({ size }) => (
      <FullCenteredBlock {...size}>
        <LoadingInline />
      </FullCenteredBlock>
    ),
    render: ({ ref }) => {
      const onSubmit = (values) => {
        resolveResult(async () => {
          const result = await fetchJSON({
            url: '/api/subscriptions/newsletter/',
            method: 'POST',
            data: {
              email: values.email,
              language_code: languageContainer.selectActiveLanguage(),
            },
            env,
          });
          if (result.status === 'ok') {
            webAnalytics.microNewsletter();
            facebookPixelEvents.subscribeToNewsletter();

            return { ok: true };
          }
          if (result.status === 'error') {
            const { data } = result;
            const errorType = (() => {
              if (data && data.error.code === 'SubscriberAlreadyRegistered') {
                return 'SubscriberAlreadyRegistered';
              }
              return 'unknown';
            })();
            return { ok: false, errorType };
          }
          return { ok: false, errorType: 'unknown' };
        });
      };

      const { handleSubmit } = appForm;

      return (
        <FormContext {...appForm}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            ref={ref}
            style={{ display: 'inline', margin: 0, padding: isExtraSmall ? '10px 0' : '0' }}
          >
            <baseUI.FlexRow flexWrap={isExtraSmall ? 'wrap' : ''} alignItems="center">
              <RHFValidatedTextField
                id="newsletter-form"
                fullWidth={false}
                name="email"
                config={inputConfigs.email(i18n, {
                  label: i18n.t`Your e-mail address`,
                  required: true,
                })}
                style={{ minWidth: '250px' }}
              />
              <appUI.HorizontalSpace />
              <baseUI.Block minWidth={190}>
                <PrimaryButton type="submit" fullWidth style={{ padding: '8px 16px' }}>
                  <Trans>Sign up to newsletter</Trans>
                </PrimaryButton>
              </baseUI.Block>
            </baseUI.FlexRow>
          </form>
        </FormContext>
      );
    },
  });

  return (
    <baseUI.FlexColumn width="100%" alignItems="center" backgroundColor={theme.color('greyBg')}>
      <baseUI.FlexRow
        flexWrap={isSmall ? 'wrap' : ''}
        width="70%"
        justifyContent="space-between"
        alignItems="center"
      >
        <NewsletterTitle>
          <Trans>
            Subscribe to our newsletter to receive information about our{' '}
            <span>new products and promotions</span>!
          </Trans>
        </NewsletterTitle>
        <appUI.HorizontalSpace size="large" />
        {newsletterFormEl}
      </baseUI.FlexRow>
    </baseUI.FlexColumn>
  );
};

import styled from '@emotion/styled';
import { styledTheme } from '../app/theme';

export const AlfaboxGalleryWrapper = styled.div`
  & {
    .image-gallery-slides {
      border: none;
    }

    .image-gallery-image {
      max-height: 100%;
      & > img {
        max-height: 540px;
        width: auto;
        margin: 0 auto;
        display: block;
      }
    }

    .image-gallery-left-nav,
    .image-gallery-right-nav {
      display: flex;
      height: 50px;
      width: 50px;
      padding: 0px;
      justify-content: center;
      background-color: transparent;
      cursor: pointer;
      align-items: center;
      transition: all ease-in 0.1s;
    }

    :hover .image-gallery-left-nav,
    :hover .image-gallery-right-nav {
      visibility: visible;
    }

    .image-gallery-left-nav {
      margin-left: 5px;
    }

    .image-gallery-right-nav {
      margin-right: 5px;
    }

    .image-gallery-left-nav:hover::before,
    .image-gallery-right-nav:hover::before {
      color: ${styledTheme.color('secondaryDark')};
    }

    .image-gallery-left-nav::before,
    .image-gallery-right-nav::before {
      color: ${styledTheme.color('secondaryDark')};
      text-shadow: none;
      font-size: 3rem;
    }

    .image-gallery-thumbnail {
      transition: border ease 0.5s;
      border: none;
    }

    .image-gallery-thumbnail.active {
      border: 1px solid #ffa9a1;
    }

    @media only screen and (max-width: 600px) {
      .image-gallery-left-nav,
      .image-gallery-right-nav {
        height: 20px;
        width: 20px;
      }

      .image-gallery-left-nav::before,
      .image-gallery-right-nav::before {
        font-size: 1.7rem;
      }
    }
  }
`;

export const RudorferGalleryWrapper = styled.div`
  --thumbnail-height: 120px;
  & {
    @media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
      @media {
        /*
      Define here the CSS styles applied only to Safari browsers
      (any version and any device)
     */
        display: flex;
        align-self: flex-start;
        max-height: 50%;
      }
    }

    .image-gallery {
      width: 100%;
    }
    .image-gallery-left-nav,
    .image-gallery-right-nav {
      font-size: 3em;
      height: 100%;
      padding: 50px 30px;
    }

    .image-gallery-content.fullscreen .image-gallery-left-nav,
    .image-gallery-content.fullscreen .image-gallery-right-nav {
      padding: 50px 100px;
    }

    .image-gallery-left-nav:hover,
    .image-gallery-right-nav:hover {
      background-color: #e7e7e75c;
    }
    .image-gallery-left-nav:hover::before,
    .image-gallery-right-nav:hover::before {
      color: #332b77;
    }

    .image-gallery-left-nav::before,
    .image-gallery-right-nav::before {
      color: #c0bdbd;
      text-shadow: none;
    }

    .image-gallery-thumbnail.active {
      border: 3px solid #50488c;
    }

    .image-gallery-swipe {
      border-radius: 7px;
      border: 1px solid #e6e6e6;
    }

    .image-gallery-content.fullscreen {
      background: white;
      height: 100%;
    }

    .image-gallery-content.fullscreen .image-gallery-slide {
      background: white;
    }

    .image-gallery-content.fullscreen .image-gallery-slide img {
      height: calc(100vh - var(--thumbnail-height));
      max-height: initial;
    }

    .image-gallery-thumbnails-wrapper.bottom {
      height: var(--thumbnail-height);
    }

    .image-gallery-content.fullscreen .image-gallery-fullscreen-button {
      right: 10px;
    }

    .image-gallery-content.fullscreen .image-gallery-right-nav {
      right: 15px;
    }

    .image-gallery-content.fullscreen .image-gallery-left-nav {
      left: 15px;
    }

    .image-gallery-image {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      max-height: 100%;
      max-width: 100%;
    }

    .image-gallery-slides {
      height: 400px;
      width: 400px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image-gallery-content.fullscreen .image-gallery-slides {
      border: none;
      height: calc(100vh - var(--thumbnail-height));
      width: auto;
    }

    .image-gallery-thumbnail {
      border: 1px solid #e6e6e6;
      margin-bottom: 5px;
      margin-right: 5px;
    }

    .image-gallery-slide img {
      max-width: 100%;
    }

    .image-gallery-content.fullscreen .image-gallery-slide img {
      width: auto;
    }
  }
`;

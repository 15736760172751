// @flow

import * as React from 'react';
import type { ProductParams } from '../appState/types';
import { FlexColumn, FlexRow, Block, Grid } from '../baseUI';
import { HorizontalSpace } from '../appUI';
import { Trans } from '@lingui/react';
import styled from '@emotion/styled';

const Left = styled.div`
  text-align: left;
`;

export function ProductCardParams(props: {
  params: ProductParams,
  textAlign?: string,
  showDimensions?: boolean,
}) {
  const { textAlign = 'left' } = props;
  const { params, showDimensions = true } = props;
  const { measureUnit } = params;
  return (
    <Grid gridTemplateColumns="1fr 1fr" gridGap="0.2em 1em">
      {params.dimensions && showDimensions && (
        <React.Fragment>
          <Block textAlign={textAlign}>
            <Trans>Dimensions (mm):</Trans>
          </Block>
          <Left>{params.dimensions}</Left>
        </React.Fragment>
      )}
      {params.usage && (
        <React.Fragment>
          <Block textAlign={textAlign}>
            <Trans>Usage:</Trans>
          </Block>
          <Left data-cy="product-usage">{params.usage}</Left>
        </React.Fragment>
      )}
      <Block textAlign={textAlign}>
        <Trans>Print option:</Trans>
      </Block>
      <Left>{params.printOption}</Left>
      <Block textAlign={textAlign}>
        <Trans>Package:</Trans>
      </Block>
      <Left>
        {params.packageSize} <Trans>pcs</Trans>
      </Left>
    </Grid>
  );
}

// @flow

export function coerceNum(val: any, defaultVal: number): number {
  const asNumber = Number(val);
  if (Number.isNaN(asNumber)) {
    return defaultVal;
  }
  return asNumber;
}

export function isValidDate(d: Date): boolean {
  // > d2
  // Invalid Date
  // > d2.getTime()
  // NaN
  // >
  return Number.isNaN(d.getTime());
}

// @flow

import { useState, useEffect, useRef } from 'react';

export function useObjectState<T: {}>(initialState: T | (() => T)): [T, ($Shape<T>) => void] {
  const [state, setState] = useState(initialState);
  const setObject = (partial) => {
    setState({ ...state, ...partial });
  };
  return [state, setObject];
}

export function useHasMounted(): boolean {
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
  }, []);
  return didMount;
}

export function useDidMount(fn: () => void) {
  return useEffect(fn, []);
}

export function useOnUnmount(cb: () => void) {
  return useEffect(() => {
    return cb;
  }, []);
}

// https://usehooks.com/usePrevious/
export function usePrevious<T>(value: T): T {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef(value);

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useEffectOnValueChange<T>(value: T, fn: () => void) {
  const ref = useRef(value);
  useEffect(() => {
    if (ref.current !== value) {
      // Note: we don't need to pass it, because the value will be up-to-date in scope
      fn();
    }
    // update
    ref.current = value;
  }, [value]);
}

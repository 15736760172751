// @flow

import styled from '@emotion/styled';
import * as React from 'react';

import { Link, NavLink } from 'react-router-dom';

import { styledTheme } from '../app/theme';
import { useGlobalContainer } from '../app/use';
import type { StaticPageNames, SystemPageNames } from '../appState/types';

import type { LocationShape } from '../types/npm';
import { withStyles } from '@material-ui/core';

export const AlfaboxTextLink = withStyles((theme) => ({
  root: {
    textDecoration: 'none',
    color: '#000',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  active: {
    color: theme.palette.secondary.main,
  },
}))((props: { classes: *, to: string }) => {
  const { classes, ...passProps } = props;
  return <NavLink className={classes.root} activeClassName={classes.active} {...passProps} />;
});

export const StyledTextLink = styled(Link)`
  text-decoration: ${({ golden }) => (golden ? 'underline' : 'none')};
  color: ${({ golden }) =>
    golden ? styledTheme.color('linkDefault') : styledTheme.color('primary')};

  &:hover {
    color: ${({ golden }) =>
      golden ? styledTheme.color('linkHover') : styledTheme.color('secondary')};
  }
`;

const StyledBlockLink = styled(Link)`
  display: inline-block;
`;

export const ExternalLink = StyledTextLink.withComponent('a');

export function TextLink(props: {
  to: string | LocationShape,
  style?: Object,
  golden?: boolean,
  children: any,
}) {
  const { golden = false } = props;
  return <StyledTextLink {...props} golden={golden} />;
}

export function BlockLink(props: {| to: string, children: any |}) {
  const { to } = props;
  return <StyledBlockLink to={to}>{props.children}</StyledBlockLink>;
}

export function StaticPageLink(props: {| name: StaticPageNames, golden?: boolean |}) {
  const { golden = false } = props;
  const globalContainer = useGlobalContainer();
  const staticPage = globalContainer.selectStaticPageInfo(props.name);
  return (
    <TextLink golden={golden} to={staticPage.url}>
      {staticPage.name}
    </TextLink>
  );
}

export function SystemPageBlockLink(props: {| name: SystemPageNames, children: React.Node |}) {
  const globalContainer = useGlobalContainer();
  const url = globalContainer.selectSystemPageUrl(props.name);
  return <BlockLink to={url}>{props.children}</BlockLink>;
}

export function SystemPageLink(props: {|
  name: SystemPageNames,
  children: React.Node,
  golden?: boolean,
|}) {
  const { golden = false } = props;
  const globalContainer = useGlobalContainer();
  const url = globalContainer.selectSystemPageUrl(props.name);
  return (
    <TextLink golden={golden} to={url}>
      {props.children}
    </TextLink>
  );
}

export function SystemHashLink(props: {|
  name: SystemPageNames,
  hash: string,
  children: React.Node,
|}) {
  const globalContainer = useGlobalContainer();
  const url = globalContainer.selectSystemPageUrl(props.name);
  return <TextLink to={`${url}#${props.hash}`}>{props.children}</TextLink>;
}

export function useStaticPage(name: StaticPageNames) {
  const globalContainer = useGlobalContainer();
  const staticPage = globalContainer.selectStaticPageInfo(name);
  return staticPage;
}

export const BlankLink = (props: {
  to: string | LocationShape,
  golden?: boolean,
  children: React.Node,
}) => {
  const { children, golden = false, to } = props;
  return (
    <ExternalLink href={to} golden={golden} target="_blank" rel="noopener noreferrer">
      {children}
    </ExternalLink>
  );
};

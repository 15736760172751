// @flow

import * as React from 'react';
import { Helmet } from 'react-helmet';

import type { PageConfig } from '@whys/app';
import { createPage } from '@whys/app/lib/pages';
// UI
import LoadingInline from '../appUI/LoadingInline';
import { useGlobalContainer } from './use';

import { useRouter } from './router';
import { useInitialContainer } from './state';

function resolveMeta(
  metaFromProps = {}
): {| title: string, description: string, keywords: string |} {
  if (!metaFromProps) {
    return { title: '', description: '', keywords: '' };
  }
  return {
    title: metaFromProps.title || '',
    description: metaFromProps.description || '',
    keywords: metaFromProps.keywords || '',
  };
}

export type PageWithMeta = {|
  meta?: {| title?: string, description?: string, keywords?: string |},
|};

function withPageMeta(PageComponent) {
  function PageWithTitle(props: Object) {
    const meta = resolveMeta(props.meta);
    const { location } = useRouter();
    const homeUrl = useGlobalContainer().selectHomeUrl();
    const fbDomainContent = useInitialContainer().selectInitialState().fbDomainVerification;

    return (
      <React.Fragment>
        <Helmet key={meta.title}>
          {location.pathname !== homeUrl && <title>{meta.title}</title>}
          <meta name="description" content={meta.description} />
          <meta name="keywords" content={meta.keywords} />
          <meta name="facebook-domain-verification" content={fbDomainContent} />
        </Helmet>
        <PageComponent {...props} />
      </React.Fragment>
    );
  }
  // Note: should hoist all statics, but getInitialProps is enought probably for now
  // $FlowFixMe (Cannot get PageComponent.getInitialProps because property getInitialProps is missing in React.AbstractComponentStatics)
  PageWithTitle.getInitialProps = PageComponent.getInitialProps;

  return PageWithTitle;
}

const defaultConfig = {
  Loader: LoadingInline,
  getInitialProps: async (ctx): Promise<any> => {
    // $FlowFixMe 1) we must return non-empty object so @whys/app renders without loader 2) page props not correct
    return { __render: true };
  },
};

export function createSeoPage<PageProps: { ...PageWithMeta }>(config: PageConfig<PageProps>) {
  const resolvedConfig = {
    ...defaultConfig,
    ...config,
  };
  return withPageMeta(createPage(resolvedConfig));
}

//
// Creates page with no specific
//

export type SystemPageConfig<PageProps> = $Shape<PageConfig<PageProps>>;

export function createSystemPage<PageProps: {}>(config: SystemPageConfig<PageProps>) {
  const resolvedConfig = {
    ...defaultConfig,
    ...config,
  };
  return createPage<PageProps>(resolvedConfig);
}

// @flow

import * as React from 'react';

import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ChevronLeft';
import ArrowForward from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/core/styles';

import SwipeableViews from 'react-swipeable-views';
import { virtualize, bindKeyboard } from 'react-swipeable-views-utils';

import * as baseUI from '../baseUI';
import { FlexRow } from '../baseUI';
import * as appUI from '../appUI';
import { __internalThemeDoNotUse } from '../app/theme';
import styled from '@emotion/styled';

const EnhancedSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

export const DotButton = withStyles(({ palette }) => {
  const main = __internalThemeDoNotUse.colors.primary;
  const secondary = __internalThemeDoNotUse.colors.secondary;
  return {
    root: {
      // determines actual size
      padding: 8,
      margin: 2,
    },
    colorPrimary: {
      background: main,
      border: `1px solid`,

      '&&:hover': {
        background: main,
      },
      '&&:focus': {
        background: main,
      },
    },
    colorSecondary: {
      background: 'none',
      border: '1px solid #d4d4d4',
      color: '#d4d4d4',

      '&&:hover': {
        background: secondary,
      },
      '&&:active': {
        background: secondary,
      },
      '&&:focus': {
        background: secondary,
      },
    },
  };
})((props) => {
  return <IconButton {...props} />;
});

export const ArrowButton = withStyles(({ palette }) => {
  return {
    root: {
      border: '1px solid',
      color: '#d4d4d4',
      padding: 3,

      '&&:hover': {
        color: palette.primary.main,
        background: 'none',
      },
    },
    focusVisible: {
      color: palette.primary.main,
    },
  };
})((props) => {
  const { classes, ...passProps } = props;
  return (
    <IconButton
      className={classes.root}
      focusVisibleClassName={classes.focusVisible}
      {...passProps}
    />
  );
});

const SwipeableViewsWrapper = styled.div`
  width: calc(100% - 65px);
  & .react-swipeable-view-container {
    min-height: 215px;
  }
`;

type LocalProps<TSlideItem> = {|
  items: Array<any>,
  perSlideCount: number,
  renderSlide: (items: Array<any>, idx: number) => React.Node,

  // with defaults
  variant: 'small' | 'normal',

  // self-resolved
  slideCount: number,
  doAnimateHeight?: boolean,
|};

type LocalState = {|
  activeIdx: number,
|};

class HorizontalSlider<T: *> extends React.Component<LocalProps<T>, LocalState> {
  x = 0;

  state = { activeIdx: 0 };

  static defaultProps = { variant: 'normal' };

  onNext = () => {
    const maxIdx = this.props.slideCount - 1;
    const activeIdx = this.state.activeIdx + 1;
    this.setState({ activeIdx: activeIdx <= maxIdx ? activeIdx : 0 });
  };

  onPrev = () => {
    const maxIdx = this.props.slideCount - 1;
    const activeIdx = this.state.activeIdx - 1;
    this.setState({ activeIdx: activeIdx >= 0 ? activeIdx : maxIdx });
  };

  onSlideTo = (activeIdx: number) => () => {
    this.setState({ activeIdx });
  };

  renderDots() {
    const { activeIdx } = this.state;
    const { slideCount } = this.props;

    // do not render unless > 1
    if (slideCount <= 1) {
      return null;
    }

    let num = 0;
    const dots = [];
    while (num < slideCount) {
      const isActive = activeIdx === num;
      dots.push(
        <DotButton
          key={num}
          onClick={this.onSlideTo(num)}
          color={isActive ? 'primary' : 'secondary'}
        />
      );
      num += 1;
    }

    return (
      <baseUI.FlexRow alignItems="center" justifyContent="center" flexWrap="wrap" margin="0 10px">
        {dots}
      </baseUI.FlexRow>
    );
  }

  onRenderSlide = (opts: {| index: number, key: string |}) => {
    const { index, key } = opts;
    const { perSlideCount } = this.props;
    const offest = Math.abs(index) * perSlideCount;
    const itemsOnSlide = this.props.items.slice(offest, offest + perSlideCount);
    return (
      <FlexRow justifyContent="center" key={key}>
        {this.props.renderSlide(itemsOnSlide, index) || index}
      </FlexRow>
    );
  };

  onChangeIndex = (activeIdx: number) => {
    this.setState({ activeIdx });
  };

  onMouseDown = (e: *) => {
    // store mouse position when click starts
    this.x = e.screenX;
  };

  onClick = (e: *) => {
    // https://github.com/oliviertassinari/react-swipeable-views/issues/347#issuecomment-348312047
    // Prevent click on element when swiping using mouse
    const delta = Math.abs(e.screenX - this.x);

    if (delta > 10) {
      // If mouse moved more than threshold, ignore the click event
      e.preventDefault();
    }

    this.x = 0;
  };

  render() {
    const { slideCount, variant, doAnimateHeight } = this.props;
    const hasMoreSlides = slideCount > 1;
    const isLarge = variant !== 'small';
    return (
      <baseUI.Block width="100%">
        <baseUI.FlexRow
          props={{ onClickCapture: this.onClick, onMouseDown: this.onMouseDown }}
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          {isLarge && hasMoreSlides && (
            <ArrowButton onClick={this.onPrev}>
              <ArrowBack />
            </ArrowButton>
          )}
          <baseUI.Block width={20} />
          <SwipeableViewsWrapper>
            <EnhancedSwipeableViews
              index={this.state.activeIdx}
              onChangeIndex={this.onChangeIndex}
              slideRenderer={this.onRenderSlide}
              slideCount={slideCount}
              animateHeight={doAnimateHeight}
              enableMouseEvents
            />
          </SwipeableViewsWrapper>
          <baseUI.Block width={20} />

          {isLarge && hasMoreSlides && (
            <ArrowButton onClick={this.onNext}>
              <ArrowForward />
            </ArrowButton>
          )}
        </baseUI.FlexRow>
        <appUI.VerticalSpace />
        <baseUI.FlexRow width="100%" justifyContent="center" alignItems="center">
          {!isLarge && hasMoreSlides && (
            <ArrowButton onClick={this.onPrev}>
              <ArrowBack />
            </ArrowButton>
          )}
          {this.renderDots()}
          {!isLarge && hasMoreSlides && (
            <ArrowButton onClick={this.onNext}>
              <ArrowForward />
            </ArrowButton>
          )}
        </baseUI.FlexRow>
      </baseUI.Block>
    );
  }
}

type APIProps = $Diff<React.ElementConfig<typeof HorizontalSlider>, { slideCount: * }>;

export default (props: APIProps) => {
  const slideCount = (() => {
    const { items, perSlideCount } = props;
    return Math.ceil(items.length / perSlideCount);
  })();

  return <HorizontalSlider slideCount={slideCount} {...props} />;
};

// @flow

function smoothScroll(options: {| top: number, left: number, behavior: 'smooth' | 'auto' |}) {
  const isSmoothScrollSupported =
    document.documentElement && 'scrollBehavior' in document.documentElement.style;
  if (isSmoothScrollSupported) {
    window.scrollTo(options);
  } else {
    window.scrollTo(options.left, options.top);
  }
}

export function scrollToTop() {
  smoothScroll({ top: 0, left: 0, behavior: 'smooth' });
}

export function smoothScrollTopToElement(el: HTMLElement, offset: number = 150) {
  const rect = el.getBoundingClientRect();
  const absoluteElTop = rect.top + window.pageYOffset - offset;
  smoothScroll({ top: absoluteElTop, left: 0, behavior: 'smooth' });
}

function _scrollMiddle(el: HTMLElement, smooth: boolean) {
  const rect = el.getBoundingClientRect();
  const elHeight = rect.height;
  const absoluteElTop = rect.top + window.pageYOffset;
  const middle = absoluteElTop - window.innerHeight / 2 + elHeight / 2;
  const options = { top: middle, left: 0, behavior: smooth ? 'smooth' : 'auto' };
  smoothScroll(options);
}

export function scrollMiddleToElement(el: HTMLElement) {
  _scrollMiddle(el, false);
}

export function smoothScrollMiddleToElement(el: HTMLElement) {
  _scrollMiddle(el, true);
}

// export function scrollToElement(
//   el: HTMLElement,
//   opts: {|
//     top: number,
//     left: number,
//   |}
// ) {
//   smoothScroll({ top: opts.top, left: opts.left, behavior: 'smooth' });
// }

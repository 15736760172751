// @flow

import React from 'react';

import { useAppContainer } from '../app/use';

import { ForbiddenErrorDialog } from '../notifications/ForbiddenErrorDialog';
import type { EventIds } from '../notifications/emitter';

function useSystemNotifications() {
  const [notifications, setNotifications] = React.useState([]);
  const appContainer = useAppContainer();

  React.useEffect(() => {
    const { eventEmitter } = appContainer.context;
    if (!eventEmitter) {
      return;
    }
    const token = eventEmitter.addListener('notifications', (notification) => {
      setNotifications([...notifications, notification]);
    });
    return () => {
      // unsubscribe from the event
      token.remove();
    };
  }, []);

  const newNotifications = React.useMemo(() => {
    return notifications.filter((_) => !_.read);
  }, [notifications]);

  function setReadId(id) {
    const idx = notifications.findIndex((_) => _.id === id);
    if (idx === -1) {
      return;
    }
    const item = notifications[idx];
    if (!item) {
      return;
    }
    setNotifications([
      ...notifications.slice(0, idx),
      { ...item, read: true },
      ...notifications.slice(idx + 1),
    ]);
  }

  return [newNotifications, setReadId];
}

function isNotificationId(a: EventIds, b: EventIds) {
  // just for flow!
  return a === b;
}

export function SystemNotifications() {
  const [newNotifications, setReadId] = useSystemNotifications();
  return (
    <React.Fragment>
      {newNotifications.map((item) => {
        if (isNotificationId(item.id, '403')) {
          const onClose = () => {
            setReadId(item.id);
          };
          const onReload = () => {
            window.location.reload();
          };
          return (
            <ForbiddenErrorDialog
              initiallyOpen
              key={item.id}
              onClose={onClose}
              onReload={onReload}
            />
          );
        }
        return null;
      })}
    </React.Fragment>
  );
}

import React from 'react';

import { themeMUI } from '../app/state';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: '5px',
    textTransform: 'capitalize',
    transition: 'all 0.5s ease',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  formControl: {
    visibility: 'hidden',
    height: 0,
    width: 0,
    transition: 'all 0.5s ease',
  },
}));

export default function AlfaboxSelect(props: {
  classes: *,
  inputProps: {
    id: string,
  },
  onChange: Function,
  value: string,
  options: Array<{| value: string, label: string |}>,
  label: string,
  selectProps: Object,
}) {
  const classes = useStyles(themeMUI);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Button className={classes.button} onClick={handleOpen}>
        {props.label}
      </Button>
      <FormControl className={classes.formControl}>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={props.value}
          onChange={props.onChange}
          inputProps={props.inputProps}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          {...props.selectProps}
        >
          {props.options.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

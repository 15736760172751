import React, { useState } from 'react';
import styled from '@emotion/styled';
import { styledTheme } from '../app/theme';

import Collapse from '@material-ui/core/Collapse';
import * as baseUI from '../baseUI';
import { useMediaQuery } from '@material-ui/core';
import { TextLink } from '../app/link';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { themeMUI } from '../app/state';
import { HorizontalSpace } from '../appUI';
import { useHistory } from 'react-router-dom';

const SubItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 0 0 15px;
`;

const SubItemOfSubItem = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0 0 5px;
`;

const SubItemTitle = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.fontSize || '0.9em'};
  font-weight: ${(props) => (props.isHighlighted ? 'bold' : 'inherit')};
  background: ${(props) => props.bgcolor};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  &:hover {
    color: ${({ isSmall }) => !isSmall && styledTheme.color('secondary')};
    background: ${({ isSmall }) => !isSmall && styledTheme.color('secondary')};
    -webkit-background-clip: ${({ isSmall }) => !isSmall && 'text'};
  }
`;

const ColorSecondary = styled.div`
  & > svg {
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
    color: ${styledTheme.color('secondary')};
    font-size: 2.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${(props) => (props.isSubOfSub ? '2rem' : '1rem')};
  }
`;

function MenuSubItem(props: {
  mainColor: string,
  subItem: MenuItem,
  id: number,
  onRedirect: Function,
}) {
  const { mainColor, subItem, id, onRedirect } = props;
  const [subItemIsOpen, setSubItemIsOpen] = useState(false);
  const [onLocation, setOnLocation] = useState(false);
  const isSmall = useMediaQuery(themeMUI.breakpoints.down('sm'));

  const history = useHistory();
  const { pathname } = history.location;

  React.useEffect(() => {
    setOnLocation(subItem.categoryUrl === pathname);
  }, [pathname]);

  if (!subItem.title) {
    return null;
  }

  return (
    <SubItem isSmall={isSmall} onClick={() => setSubItemIsOpen(!subItemIsOpen)}>
      <baseUI.FlexRow>
        <ColorSecondary visible={onLocation}>
          <ChevronRightIcon />
        </ColorSecondary>
        <HorizontalSpace size="large" />

        <SubItemTitle
          bgcolor={onLocation || subItemIsOpen ? styledTheme.color('secondary') : mainColor}
          isHighlighted={false}
          fontSize="1.3rem"
          onClick={onRedirect}
        >
          <TextLink
            key={id}
            to={subItem.categoryUrl}
            onClick={(e) => {
              onRedirect();
              e.stopPropagation();
            }}
          >
            {subItem.title}
          </TextLink>
        </SubItemTitle>
      </baseUI.FlexRow>
      {subItem.subsOfSub !== undefined && !!subItem.subsOfSub.length && (
        <Collapse in={subItemIsOpen} mountOnEnter unmountOnExit>
          <baseUI.Block padding="0">
            {subItem.subsOfSub.map((subItemOfSubItem, sidx) => {
              const sid = subItemOfSubItem.id;
              if (!subItemOfSubItem.title) {
                return null;
              }
              return (
                <SubItemOfSubItem key={sidx} isSmall={isSmall}>
                  <ColorSecondary visible={onLocation} isSubOfSub={true}>
                    <ChevronRightIcon />
                  </ColorSecondary>
                  <HorizontalSpace size="large" />
                  <HorizontalSpace />
                  <HorizontalSpace />

                  <SubItemTitle
                    bgcolor={onLocation ? styledTheme.color('secondary') : mainColor}
                    fontSize="1.3rem"
                  >
                    <TextLink
                      key={sid}
                      to={subItemOfSubItem.categoryUrl}
                      onClick={(e) => {
                        onRedirect();
                        e.stopPropagation();
                      }}
                    >
                      {subItemOfSubItem.title}
                    </TextLink>
                  </SubItemTitle>
                </SubItemOfSubItem>
              );
            })}
          </baseUI.Block>
        </Collapse>
      )}
    </SubItem>
  );
}

export default MenuSubItem;

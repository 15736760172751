// @flow

import * as React from 'react';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import styled from '@emotion/styled';
import useComponentSize from '@rehooks/component-size';

import Search from '../rudorfer/Search';

import { styledTheme, useTheme } from '../app/theme';
import AppMenu, { menuClosedWidth, menuOpenWidth } from '../rudorfer/AppMenu';
import { StaticHeader, StickyHeader } from '../rudorfer/AppHeader';
import { Block, FlexColumn, FlexRow } from '../baseUI';
import { useMediaQuery } from '../hooks/responsive';

// TEMPORARY!!!
//import {menuItems as exampleMenuItems} from '../exampleHelpers/factories';
import {
  themeMUI,
  useCartContainer,
  useFavoritesContainer,
  useGlobalContainer,
  useIsLogged,
  useLoginContainer,
  useMenuContainer,
  useWatchdogContainer,
  useWebAnalyticsContainer,
} from '../app/state';
import { useIsSystemPage, useRouter } from '../app/router';
import { NewsLetterForm } from '../rudorfer/NewsletterSignUp';
import { Advantages } from '../rudorfer/Advantages';
import CookiesPopup from '../rudorfer/CookiePopup';
// import NoSsr from '@material-ui/core/NoSsr';

export const MainBlock = styled.div`
  background: ${styledTheme.color('secondaryBg')};
  margin: auto;
  min-width: ${(props) => (props.isAlfabox ? '320px' : styledTheme.size('minPageWidth'))}px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const AppBody = styled.div`
  width: 100%;
  display: flex;
  padding: ${({ isAlfabox, isSmall, isHomePage }) =>
    isAlfabox && isSmall && isHomePage ? '0' : isAlfabox && !isSmall ? '0 10px 0 30px' : '0 30px'};
  box-sizing: border-box;
`;

export const AppFooter = styled.div`
  max-width: ${styledTheme.size('maxPageWidth')}px;
  margin: 0px auto;
  padding: ${(props) => (props.isAlfabox ? '20px 10px' : '20px 30px')};
  box-sizing: border-box;
  width: 100%;
`;

export const AppFooterLine = styled.hr`
  width: 100%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(227, 227, 227, 0.2),
    ${styledTheme.color('borderHairline')},
    rgba(227, 227, 227, 0.2)
  );
`;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }
  }, []);
  return size;
}

function useWindowHeight() {
  return useWindowSize()[1];
}

// function useWindowWidth() {
//   return useWindowSize()[0];
// }

function AppHeaders(props: {| stickyInitiallyVisible: boolean |}) {
  const [stickyVisible, setStickyVisible] = useState(props.stickyInitiallyVisible);

  const staticRef = useRef(null);
  const staticSize = useComponentSize(staticRef);
  const stickyRef = useRef(null);
  const stickySize = useComponentSize(stickyRef);
  const headerHeight = stickyVisible ? stickySize.height : staticSize.height;
  const windowHeight = useWindowHeight();

  const popupHeight = useMemo(() => {
    const maxHeightToWindow = windowHeight - headerHeight - 50;
    return Math.min(maxHeightToWindow, 630);
  }, [headerHeight, windowHeight]);

  const isLogged = useLoginContainer().isLogged();
  const cart = useCartContainer();
  const cartResource = cart.lazyCartDetail();
  useEffect(() => {
    const reloadCart = async () => {
      await cartResource.reload();
    };
    if (isLogged) reloadCart();
  }, []);

  const staticInitiallyVisible = !props.stickyInitiallyVisible;
  return (
    <React.Fragment>
      <StaticHeader
        ref={staticRef}
        visible={staticInitiallyVisible}
        popupHeight={popupHeight}
        searchEl={
          staticInitiallyVisible ? (
            <Search forcePopupClosed={stickyVisible} popupHeight={popupHeight} />
          ) : null
        }
      />
      <StickyHeader
        ref={stickyRef}
        popupHeight={popupHeight}
        searchEl={<Search forcePopupClosed={false} popupHeight={popupHeight} />}
        isVisible={stickyVisible}
        setVisible={setStickyVisible}
      />
    </React.Fragment>
  );
}

type LocalProps = {| body: React.Node, footer: React.Node |};

function GlobalLayout(props: LocalProps) {
  const favorites = useFavoritesContainer();
  const watchdog = useWatchdogContainer();
  const menuContainer = useMenuContainer();
  const globalContainer = useGlobalContainer();

  const watchedCount = watchdog.selectWatchdogsCount();
  const favoritesCount = favorites.selectFavoritesCount();

  const theme = useTheme();
  const router = useRouter();
  const location = useLocation();

  const loggedIn = useIsLogged();
  const isSmall = useMediaQuery(themeMUI.breakpoints.down('sm'));
  const isAlfabox = globalContainer.isSpecificProject('alfabox');
  const hideMenu = globalContainer.isSpecificSite('rudorfer.dealer') && !loggedIn;
  const areItemsExpanded = menuContainer.areItemsExpanded();

  // Alfabox menu doesn't have option for expanding
  // so always make it expanded
  const menuExpanded = isAlfabox && !isSmall ? true : menuContainer.isExpanded();
  const menuItemsLoaded = menuContainer.selectCategoriesLoaded();

  const openCookiesPopup = globalContainer.getOpenCookiesPopup();
  const opencookies = globalContainer.showCookiesPopup();
  const webAnalytics = useWebAnalyticsContainer();
  const [openPopup, setOpenPopup] = React.useState(opencookies);
  const urls = [
    '/zasady-korzystania-z-plikow-cookie/',
    '/zasady-pouzivani-souboru-cookie/',
    '/richtlinien-fur-die-cookie-verwendung/',
    '/a-sutik-hasznalatanak-alapelvei/',
    '/zasady-pouzivania-suborov-cookies/',
    '/principles-of-cookie-use/',
    '/politique-de-cookies/',
  ];

  const submitCookiesPopup = () => {
    globalContainer.acceptCookiesPopup();
    setOpenPopup(false);
    globalContainer.setOpenCookiesPopup(false);
    webAnalytics.microCookie();
  };

  const closeCookiesPopup = () => {
    setOpenPopup(false);
    globalContainer.setOpenCookiesPopup(false);
  };

  const getCookies = () => {
    const consent = globalContainer.getCookiesConsent() || {};
    return {
      cookie_consent_essential: true,
      cookie_consent_analytics: consent.cookie_consent_analytics === 'true',
      cookie_consent_remarketing: consent.cookie_consent_remarketing === 'true',
    };
  };

  const menuItems = useMemo(() => {
    const items = menuContainer.selectMenuItems();
    return isAlfabox ? items.filter((el) => !el.isSpecial) : items;
  }, [menuItemsLoaded]);

  useEffect(() => {
    if (isSmall) {
      menuContainer.closeMenu();
    }
  }, [isSmall]);

  useEffect(() => {
    if (openCookiesPopup) {
      setOpenPopup(openCookiesPopup);
    }
  }, [openCookiesPopup]);

  useEffect(() => {
    let show = true;
    urls.forEach((i) => {
      if (i === location.pathname) show = false;
    });
    show ? setOpenPopup(!opencookies) : setOpenPopup(false);
  }, [location.pathname]);

  const navigateToItem = (item) => {
    router.history.push(item.categoryUrl);
  };

  const menuWidth = menuExpanded ? menuOpenWidth : menuClosedWidth;
  return (
    <MainBlock isAlfabox={isAlfabox}>
      <AppHeaders stickyInitiallyVisible={isSmall} />
      {openPopup && (
        <CookiesPopup
          open={openPopup}
          onSubmit={submitCookiesPopup}
          onClose={closeCookiesPopup}
          initialState={getCookies}
        />
      )}
      <Block backgroundColor={theme.color('primaryBg')} minHeight={200}>
        <FlexRow
          width={theme.size('maxPageWidth')}
          maxWidth="100%"
          margin={isAlfabox ? '10px auto 40px auto' : '40px auto'}
        >
          <Block width={isAlfabox && !isSmall && '25%'}>
            {hideMenu ? null : (
              <AppMenu
                isExpanded={menuExpanded}
                toggleExpanded={() => menuContainer.toggleExpanded()}
                setExpanded={(val) => menuContainer.setExpanded(val)}
                navigateToItem={navigateToItem}
                areItemsExpanded={areItemsExpanded}
                toggleItemsExpanded={() => menuContainer.toggleItemsExpanded()}
                items={menuItems}
                itemsLoaded={menuItemsLoaded}
                watchedCount={watchedCount}
                favoritesCount={favoritesCount}
              />
            )}
          </Block>
          <FlexColumn
            width={isSmall ? '100%' : `calc(100% - ${hideMenu ? 0 : menuWidth}px)`}
            transition="width 250ms ease"
            boxSizing="border-box"
          >
            <AppBody
              isAlfabox={isAlfabox}
              isHomePage={useIsSystemPage('homepage')}
              isSmall={isSmall}
            >
              {props.body}
            </AppBody>
          </FlexColumn>
        </FlexRow>
      </Block>
      <Block>
        {!globalContainer.isSpecificSite('rudorfer.dealer') &&
          globalContainer.isRuleActive('show_newsletter_form') && (
            <React.Fragment>
              <NewsLetterForm />
            </React.Fragment>
          )}

        {globalContainer.isSpecificProject('jadtools') ||
          globalContainer.isSpecificProject('axima') ||
          globalContainer.isSpecificSite('rudorfer.dealer') || <Advantages />}
      </Block>
      <Block backgroundColor={!isAlfabox ? theme.color('secondaryBg') : theme.color('primaryBg')}>
        <AppFooter isAlfabox={isAlfabox}>{props.footer}</AppFooter>
      </Block>
    </MainBlock>
  );
}

export default GlobalLayout;

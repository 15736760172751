// @flow

import queryString from 'query-string';

export function readQueryParams<TQueryParams, TQueryParamsRaw: TQueryParams>(
  searchInput: string,
  opts: {|
    mapParams?: (TQueryParamsRaw) => TQueryParams,
    defaultParams: TQueryParams,
    arrayFormat?: 'bracket' | 'index' | 'comma' | 'none',
  |}
): TQueryParams {
  const queryParams = queryString.parse(searchInput, { arrayFormat: opts.arrayFormat });
  const resolved = {
    ...opts.defaultParams,
    ...queryParams,
  };
  const { mapParams } = opts;
  return mapParams ? mapParams(resolved) : resolved;
}

// @flow

import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import * as React from 'react';
import { useLingui, useWatchdogContainer } from '../app/state';
import AlarmIcon from '@material-ui/icons/Alarm';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import { Block, FlexColumn, FlexRow } from '../baseUI';
import { HorizontalSpace, ThemedText, VerticalSpace } from '../appUI';
import { Trans } from '@lingui/react';
import { PrimaryButton, SecondaryButton } from '../materialUI';

type LocalProps = {|
  productId: string,
  allowed: boolean,
  large?: boolean,
  longDesc?: boolean,
  styleRow?: Object,
|};

const WatchButton = (props: LocalProps) => {
  const { productId, allowed, large = false, longDesc = false, styleRow = {} } = props;

  const [showWatchDialog, setShowWatchDialog] = React.useState(false);
  const watchdog = useWatchdogContainer();
  const isWatched = watchdog.isWatched(productId);
  const i18n = useLingui();
  const watchRef: any = React.useRef(null);

  const addWatchdog = async () => {
    const result = await watchdog.addWatchdog(productId);
    if (result) {
      setShowWatchDialog(false);
    }
  };

  const deleteWatchdog = async () => {
    const result = await watchdog.deleteWatchdog(productId);
    if (result) {
      setShowWatchDialog(false);
    }
  };

  if (!allowed && !isWatched) return null;

  return (
    <div ref={watchRef}>
      <FlexRow alignItems="center" justifyContent="center" style={styleRow}>
        <IconButton
          title={
            isWatched
              ? i18n.t`Product already watched`
              : i18n.t`Send me an email when the product is back in stock.`
          }
          onClick={() => setShowWatchDialog(!showWatchDialog)}
        >
          {isWatched ? (
            <AlarmOnIcon color="primary" fontSize={large ? 'large' : 'default'} />
          ) : (
            <AlarmIcon color="primary" fontSize={large ? 'large' : 'default'} />
          )}
        </IconButton>
        <HorizontalSpace size="small" />
        <ThemedText display="inline-block">
          {longDesc ? (
            <Trans>Send me an email when the product is back in stock.</Trans>
          ) : (
            <Trans>Notify of availability</Trans>
          )}
        </ThemedText>
      </FlexRow>
      <Dialog
        open={showWatchDialog}
        onClose={() => setShowWatchDialog(false)}
        aria-labelledby="watchModalTitle"
        aria-describedby="watchModalContent"
      >
        <Block padding={20}>
          <DialogTitle id="watchModalTitle">
            <ThemedText size="large" color="primary">
              <Trans>Notify of availability</Trans>
            </ThemedText>
          </DialogTitle>
          <DialogContent id="watchModalContent">
            {isWatched && (
              <FlexColumn>
                <ThemedText size="bigger">
                  <Trans>The product is already watched.</Trans>
                </ThemedText>
                <VerticalSpace />
                <ThemedText size="bigger">
                  <Trans>Do you want to cancel monitoring?</Trans>
                </ThemedText>
                <VerticalSpace />
              </FlexColumn>
            )}
            <FlexRow>
              <SecondaryButton onClick={() => setShowWatchDialog(false)}>
                <Trans>Cancel</Trans>
              </SecondaryButton>
              <HorizontalSpace />
              <PrimaryButton
                type="submit"
                onClick={() => (isWatched ? deleteWatchdog() : addWatchdog())}
              >
                {isWatched ? <Trans>Yes</Trans> : <Trans>Send</Trans>}
              </PrimaryButton>
            </FlexRow>
          </DialogContent>
        </Block>
      </Dialog>
    </div>
  );
};

export default WatchButton;

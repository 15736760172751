// @flow

import React from 'react';
import { useContext, useState } from 'react';
import { Route } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import queryString from 'query-string';

// app
// import paths from '../app/paths';
import type { SystemPageNames } from '../appState/types';
import { useGlobalContainer } from '../app/state';

type ReactRouterProps = $Shape<ContextRouter>;

export const RouterContext = React.createContext<ReactRouterProps>({});

export function OurReactRouterProvider(props: { children: any }) {
  return (
    <Route>
      {(routeProps) => (
        <RouterContext.Provider value={routeProps}>{props.children}</RouterContext.Provider>
      )}
    </Route>
  );
}

export function useRouter() {
  return useContext(RouterContext);
}

export function useQueryParams<TQueryParams: {}>(opts: {|
  defaultParams: TQueryParams,
  mapParams?: (TQueryParams) => TQueryParams,
  arrayFormat?: 'bracket' | 'index' | 'comma' | 'none',
|}): [TQueryParams, (TQueryParams) => void] {
  const { location } = useRouter();
  const [localState, setLocalState] = useState(() => {
    const { defaultParams, mapParams } = opts;
    const resolved = {
      ...defaultParams,
      ...queryString.parse(location.search, { arrayFormat: opts.arrayFormat, decode: false }),
    };
    return mapParams ? mapParams(resolved) : resolved;
  });
  const setQueryParams = (params: TQueryParams) => {
    setLocalState(params);
    // pushState for page only
    let tempParams = {};
    // $FlowFixMe
    tempParams = params?.filterNames;
    for (const [key, value] of Object.entries(params)) {
      if (key !== 'filterNames') {
        tempParams = { ...tempParams, [key]: value };
      }
    }
    delete tempParams.attributes;
    // delete tempParams.items_count;
    // delete tempParams.page;
    const url = `${location.pathname}?${queryString.stringify(tempParams, {
      arrayFormat: opts.arrayFormat,
      encode: false,
    })}`;
    // Note: it uses the history API directly because it avoids the re-rendering page from scratch
    window.history.replaceState({}, '', url);
  };
  return [localState, setQueryParams];
}

type History = $PropertyType<ContextRouter, 'history'>;

export function useHistory(): History {
  const router = useContext(RouterContext);
  return router.history;
}

export function useIsSystemPage(name: SystemPageNames): boolean {
  const { location } = useRouter();
  const globalContainer = useGlobalContainer();
  return globalContainer.isUrlForSystemPage(location.pathname, name);
}

// @flow

import { EventEmitter } from 'fbemitter';

// typed events + its values

type TypedEmitter<TEvents> = {|
  emit: <K: $Keys<TEvents>>(K, $ElementType<TEvents, K>) => void,
  addListener: <K: $Keys<TEvents>>(
    K,
    ($ElementType<TEvents, K>) => any
  ) => {
    remove: () => void,
  },
|};

export type EventIds = '403';

type Events = {
  notifications: {|
    id: EventIds,
    read: boolean,
  |},
};

export type AppEventEmitter = TypedEmitter<Events>;

export function createEmitter(): AppEventEmitter {
  return new EventEmitter();
}

// @flow

import { Container } from '@whys/app/lib/state';
import { fetchJSON, getJSON } from '@whys/fetch/lib/json';

import type { FetchEnvType } from '../types/app';
import { loginStateLocalStorageKey } from '../app/constants';
import idx from 'idx';
import type { ProfileModel } from '../appState/types';

const resources = {
  userLogout: { url: '/api/auth/logout/', method: 'POST' },
  userLogin: { url: '/api/auth/login/', method: 'POST' },
};

type LocalProps = {|
  fetchEnv: FetchEnvType,
  initiallyLogged: boolean,
  // loginInfo: ?TLoginInfo,
|};

type LocalState = {|
  isLogged: boolean,
  // loginInfo: ?TLoginInfo,
  redirectUrl: string,
  _id: string,
  profile: $Shape<ProfileModel>
|};

export class LoginContainer extends Container<LocalState> {
  state: LocalState;
  props: LocalProps;

  constructor(props: LocalProps) {
    super();
    this.state = { isLogged: props.initiallyLogged, redirectUrl: '', _id: '', profile: {} };
    this.props = props;
  }

  getUserID = () => this.state._id;

  getProfileID = () => this.state.profile.id;

  async login(credentials: {| email: string, password: string |}): Promise<boolean> {
    const result = await fetchJSON({
      method: 'POST',
      url: resources.userLogin.url,
      env: this.props.fetchEnv,
      data: credentials,
    });
    const data = result.data || {};
    if (result.status === 'ok') {
      localStorage.setItem(loginStateLocalStorageKey, '1');
      this.setState({
        _id: String(idx(data, (_) => _.user.id) || ''),
        profile: idx(data, (_) => _.profile) || {},
      });
      return true;
    }
    this.setState({ redirectUrl: data.redirect_url || '' });
    return false;
  }

  async logout(): Promise<void> {
    const result = await fetchJSON({
      method: 'POST',
      url: resources.userLogout.url,
      env: this.props.fetchEnv,
    });
    if (result.status === 'ok') {
      // fetch without Env so it doesn't trigger notifications
      const response = await fetch(`/api/url${window.location.pathname}`);
      // if request ends with 403 code go to homepage
      if (response && response.status === 403) window.location.replace('/');
      // else stay
      else window.location.reload();
      localStorage.setItem(loginStateLocalStorageKey, '');
    }
  }

  async forceLogin() {
    // NotePrototype(simon): this needs to fully reload appContainer
    // await this.setState({ isLogged: true });

    window.location.reload();
  }

  async forceLogout() {
    // see forceLogin
    // await this.setState({ isLogged: false });

    window.location.reload();
  }

  isLogged() {
    return this.state.isLogged;
  }

  getRedirectUrl() {
    return this.state.redirectUrl;
  }
}

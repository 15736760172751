// @flow

import React from 'react';
import { useMemo } from 'react';

import { SelectControl } from '../materialUI';
//
import { useLanguageContainer } from '../app/state';
import { useHistory } from 'react-router-dom';

function useListMapping(values, mapFn) {
  return useMemo(() => values.map(mapFn), [values]);
}

export function LanguageSelect() {
  const langContainer = useLanguageContainer();
  const language = langContainer.selectActiveLanguage();
  const history = useHistory();
  const { pathname } = history.location;

  const languageOptions = useListMapping(langContainer.selectAvailableLanguages(), (_) => {
    return { label: _.title, value: _.code };
  });

  const onChange = async (e) => {
    const value = e.target.value;
    const fromUrl = pathname;
    const { redirectUrl } = await langContainer.changeLanguage(value, { fromUrl });
    if (redirectUrl) {
      // redirect to new url e.g. /en/about => /de/uber
      window.location.assign(redirectUrl);
    } else {
      // load the same page with new language (reload app for simplicity)
      window.location.reload();
    }
  };

  return languageOptions.length > 1 ? (
    <SelectControl
      inputProps={{ id: 'language-select' }}
      onChange={onChange}
      value={language}
      options={languageOptions}
      selectProps={{ disableUnderline: true }}
      formControlProps={{ margin: 'none' }}
    />
  ) : null;
}

// @flow

import * as React from 'react';

export function FormatPriceRange(props: {|
  value: [number, number],
  locale: string,
  currency: string,
  Component: string | React.ComponentType<any>,
|}) {
  const [min, max] = props.value;
  const minimumFractionDigits = 0;
  const maximumFractionDigits = 0;
  const minFormatted = new Intl.NumberFormat(props.locale, {
    style: 'currency',
    currency: props.currency,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(min);
  const maxFormatted = new Intl.NumberFormat(props.locale, {
    style: 'currency',
    currency: props.currency,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(max);
  const { Component } = props;
  return (
    <Component>
      {minFormatted} – {maxFormatted}
    </Component>
  );
}

FormatPriceRange.defaultProps = { Component: 'span' };

// @flow

import idx from 'idx';

import type {
  ProductItem,
  ProductDetail,
  ProductShape,
  ProductVariant,
  QuickSearchItem,
} from '../../appState/types';

import { coerceStr, coerceNum, coerceInt } from '../mapping';
import type { ImageJsonType, PageAttributes } from '../jsonTypes';

type BackendID = number;

export type MetaProductPayload = {|
  id: number,
  name: string,
  variants: BackendID[],
|};

// type ProductItemPayload<TSpecificParameters> = {|
//   id: string,
//   name: string,
//   code: string,

//   stock: number,
//   discount_price: number,
//   base_price: number,
//   discount_percentage: number,

//   sale: boolean,
//   favorite: boolean,

//   product: string,
//   labels?: {|
//     bestseller: boolean,
//     new: boolean,
//     discount: boolean,
//   |},
//   specific_params?: TSpecificParameters,
//   attributes?: number[],
//   main_image?: ImageJsonType,
//   images: ImageJsonType[],
// |};

// type LabelsType = { [string]: boolean };
type LabelsType = $FlowFixMe;

export type ListProductsItemPayload<TSpecificParameters> = {|
  id: BackendID,
  product: BackendID,

  name: string,
  code: string,

  stock: number,

  sale: boolean,
  favorite: boolean,

  main_image?: ImageJsonType,
  images: ImageJsonType[],

  page_attributes?: PageAttributes,

  specific_params?: TSpecificParameters,
  labels?: LabelsType,
  labels_test: Array<{| code: string, label: string, css_background: string |}>,
  attributes?: number[],
  sizes?: Array<{| label: ?string, variant_id: BackendID |}>,
  colors?: Array<{| label: ?string, variant_id: BackendID |}>,
  prices: {|
    discount_percentage: number,
    discount_price_net: number,
    base_price_net: number,
  |},
  allow_watchdog: boolean,
  short_description_html: string,
  description_html: string,
|};

export type ProductsDetailPayload<
  TSpecificParameters
> = ListProductsItemPayload<TSpecificParameters>;

export const resources = {
  productDetail: (id: string) => ({
    url: `/api/product/variant/${id}/`,
  }),
  multiProductDetails: (ids: string[]) => ({
    url: `/api/product/variant/?ids=${ids.join(',')}`,
  }),
  listFromVariant: (id: string, listName: string) => ({
    url: `/api/product/variant/${id}/list/${listName}/`,
  }),
  similarProducts: (id: string) => ({
    url: `/api/product/variant/${id}/list/similar/`,
  }),
  relatedProducts: (id: string) => ({
    url: `/api/product/variant/${id}/list/related/`,
  }),
  listProductsByIds: (ids: string[]) => ({
    url: `/api/product/variant/?ids=${ids.join(',')}`,
  }),
  listProductsInCategory: (id: string) => ({
    url: `/api/search/filter/${id}/`,
  }),
  sortOptions: { url: '/api/product/sorting/' },
};

function coerceImage(image: ImageJsonType) {
  return {
    original: image.full_size || image.big || '',
    thumbnail: image.medium || image.small || '',
  };
}

export function mapToProductVariant<TParamsPayload, TParams>(
  payload: ListProductsItemPayload<TParamsPayload>
): ProductVariant {
  return {
    id: String(payload.id),
    code: payload.code || '',
    imgSrc: idx(payload, (_) => _.main_image.small) || '',
    url: idx(payload, (_) => _.page_attributes.url) || '',
  };
}

type LabelsShape = { [string]: boolean };
type LabelsTestShape = Array<{|
  code: string,
  label: string,
  css_background: string,
|}>;

export function mapVariantToProductItem<TParamsPayload, TParams>(
  i: ListProductsItemPayload<TParamsPayload>,
  opts: {|
    mapParams: (?TParamsPayload) => TParams,
    mapLabels: (?LabelsShape) => LabelsType,
    mapLabelsTest: (LabelsTestShape) => Array<{|
      code: string,
      label: string,
      css_background: string,
    |}>,
  |}
): ProductItem {
  const id = String(i.id);
  const price = coerceNum(i.prices.discount_price_net, 0);

  return {
    id,
    productId: String(i.product),

    name: coerceStr(i.name),
    code: coerceStr(i.code),

    price,
    originalPrice: coerceNum(i.prices.base_price_net, price),
    discount: coerceInt(i.prices.discount_percentage, 0),

    // NoteWaitingForAPI
    minAvailableNum: 0,

    // minAvailableNum: coerceNum(i.stock, 0),

    availableNum: coerceNum(i.stock, 0),
    labels: opts.mapLabels(i.labels),
    labels_test: opts.mapLabelsTest(i.labels_test),
    isFav: i.favorite,

    // ??? extra for rudorder

    images: i.images ? i.images.filter(Boolean).map(coerceImage) : [],

    productUrl: idx(i, (_) => _.page_attributes.url) || '',

    // $FlowFixMe (Cannot return object literal because inexact TParams [1] is incompatible with exact ProductParams [2] in property params.)
    params: opts.mapParams(i.specific_params),
    allowWatchdog: i.allow_watchdog,
    shortDescriptionHtml: i.short_description_html,
    descriptionHtml: i.description_html,

    breadcrumbs: idx(i, (_) => _.page_attributes.breadcrumbs) || [],
  };
}

export function mapVariantToProductDetail<TParamsPayload, TParams>(
  item: ListProductsItemPayload<TParamsPayload>,
  opts: {|
    mapParams: (?TParamsPayload) => TParams,
    mapLabels: (?LabelsShape) => LabelsType,
    mapLabelsTest: (LabelsTestShape) => Array<{|
      code: string,
      label: string,
      css_background: string,
    |}>,
  |}
): ProductDetail {
  const variantId = String(item.id);
  return {
    ...mapVariantToProductItem(item, opts),
    variantId,
    sizes: (item.sizes || []).map((_) => {
      const variantId = String(_.variant_id);
      return { variantId, label: coerceStr(_.label), id: variantId };
    }),
    sizeId: variantId,
    colors: (item.colors || []).map((_) => {
      const variantId = String(_.variant_id);
      return {
        variantId,
        label: coerceStr(_.label),
        id: variantId,
      };
    }),
    colorId: variantId,
    prices: {
      // discount

      // discountGross: coerceNum(item.discount_price, 0),
      discountNet: coerceNum(item.prices.discount_price_net, 0),

      // base
      baseNet: coerceNum(item.prices.base_price_net, 0),
      // baseGross: coerceNum(0, 0),
      // meta
      discountPercentage: coerceNum(item.prices.discount_percentage, 0),
    },
  };
}

export type ProductMetaDetail = {|
  id: string,
  name: string,
  variantIds: string[],
|};

export function mapProductMeta(payload: MetaProductPayload): ProductMetaDetail {
  return {
    id: String(payload.id),
    name: payload.name,

    variantIds: payload.variants.map((id) => String(id)),
  };
}

export type { ProductItem, ProductDetail, ProductVariant, ProductShape, QuickSearchItem };

// @flow

import * as React from 'react';
import { Trans } from '@lingui/react';

import type { SentryLogger } from '../app/state';

type LocalProps = {| logger: SentryLogger, children: any |};

type LocalState = {| hasError: boolean |};

class AppErrorBoundary extends React.Component<LocalProps, LocalState> {
  constructor(props: LocalProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  onRetry = () => {
    window.location.reload();
  };

  componentDidCatch(error: Error, info: Object) {
    const { logger } = this.props;
    const extras = { ...info };
    logger.logError(error, { extras });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>
            <Trans>Something went wrong.</Trans>
          </h1>
          <button onClick={this.onRetry}>
            <Trans>Retry</Trans>
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default AppErrorBoundary;

// @flow

import * as React from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import type { ThemeType } from '../app/theme';
import { useTheme } from '../app/theme';
import { zIndexes } from '../app/config';

type LocalProps = {|
  isVisible: boolean,
  // isSmall: boolean,
  anchorEl: ?HTMLElement,
  popoverId: string,
  classes: Object,
  children: React.Node,
  onClose?: Function,
  placement?: string,
  useAnchorWidth?: boolean,

  theme: ThemeType,

  styles?: Object,
|};

function BasicPopover(props: LocalProps) {
  const {
    anchorEl,
    isVisible,
    popoverId,
    onClose,
    placement = 'bottom',
    classes,
    useAnchorWidth = false,
    styles = {},
  } = props;
  // const didInitialize = useTimeout(350);

  // around 350
  const maxWidth = props.theme.sizes.minPageWidth;

  const paperStyle = {
    maxWidth: '100%',
    width: (() => {
      // Ref has not been read yet
      if (anchorEl) {
        const anchorWidth = anchorEl.clientWidth;
        if (useAnchorWidth || anchorWidth >= maxWidth) {
          return anchorWidth;
        }
      }
      return 'auto';
    })(),
  };

  const onClickAway = (e) => {
    // if (!didInitialize) {
    //   e.stopPropagation();
    //   return;
    // }
    if (onClose && isVisible) {
      onClose();
    }
  };

  if (!anchorEl) {
    return null;
  }

  return (
    <Popper
      className={classes.popper}
      id={popoverId}
      open={Boolean(isVisible && anchorEl)}
      anchorEl={anchorEl}
      transition
      placement={placement}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onClickAway}>
          <Fade {...TransitionProps} timeout={50}>
            <Paper elevation={2} style={{ ...paperStyle, ...styles }}>
              {props.children}
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
}

const styles = (theme) => ({
  popper: {
    zIndex: zIndexes.popupWindow,
  },
});

export default withStyles(styles)((props) => {
  const theme = useTheme();
  return <BasicPopover theme={theme} {...props} />;
});

// @flow

// import idx from 'idx';

import { Container } from '@whys/app/lib/state';

import { getJSON } from '@whys/fetch/lib/json';
import { create as createResourceByIdCache } from '@whys/fetch/lib/resources/ResourceByIdCache';
// import type { CursorSuperCache } from '@whys/fetch/lib/pagination/CursorSuperCache';
// import { create as createCursorCache } from '@whys/fetch/lib/pagination/CursorCache';
// import { create as createCursorSuperCache } from '@whys/fetch/lib/pagination/CursorSuperCache';

import type { ResourceByIdCache } from '@whys/fetch';

import type { FetchEnvType, AppCacheType } from './types';

import type {
  ProductsDetailPayload,
  ProductItem,
  ProductDetail,
  ProductVariant,
} from './models/product';
import {
  resources,
  mapVariantToProductItem,
  mapVariantToProductDetail,
  mapToProductVariant,
} from './models/product';

// import { getNextUrl } from './requests';

import type { ProductSizeVariant, ProductColorVariant } from '../appState/types';

type LocalState = {||};

type LocalProps<TParamsPayload, TParams> = {|
  fetchEnv: FetchEnvType,
  appCache: AppCacheType,

  mapParams: (?TParamsPayload) => TParams,
  mapLabels: ($FlowFixMe) => $FlowFixMe,
  mapLabelsTest: ($FlowFixMe) => $FlowFixMe,
|};

export class ProductContainer<TParamsPayload, TParams> extends Container<LocalState> {
  state: LocalState;
  props: LocalProps<TParamsPayload, TParams>;

  productDetails: ResourceByIdCache<ProductDetail, null>;
  productVariantInfos: ResourceByIdCache<ProductVariant, null>;

  constructor(props: LocalProps<TParamsPayload, TParams>) {
    super();

    const { fetchEnv, appCache, mapParams, mapLabels, mapLabelsTest } = props;

    this.props = props;

    // NoteReview(simon): this should be lazy probably (declared no instantiated)

    this.productDetails = createResourceByIdCache<
      ProductsDetailPayload<TParamsPayload>,
      ProductDetail,
      null
    >({
      fetchEnv,
      cache: appCache.getOrCreateCache('ProductContainer.productDetails'),

      mapData: (data) => mapVariantToProductDetail(data, { mapParams, mapLabels, mapLabelsTest }),
      getUrlForId: (id: string) => resources.productDetail(id).url,
      getUrlForMultiIds: (ids: string[]) => resources.multiProductDetails(ids).url,
      getEmptyResource: () => {
        return null;
      },
    });

    this.productVariantInfos = createResourceByIdCache<
      ProductsDetailPayload<TParamsPayload>,
      ProductVariant,
      null
    >({
      fetchEnv,
      cache: appCache.getOrCreateCache('ProductContainer.productVariantInfos'),

      mapData: (data) => mapToProductVariant(data),
      getUrlForId: (id: string) => resources.productDetail(id).url,
      getUrlForMultiIds: (ids: string[]) => resources.multiProductDetails(ids).url,
      getEmptyResource: () => {
        return null;
      },
    });
  }

  async getProductDetail(productId: string): Promise<ProductDetail | null> {
    const productOrNull = await this.productDetails.getResource(productId);
    return productOrNull;
  }

  async getProductVariant(productId: string): Promise<ProductVariant | null> {
    const productOrNull = await this.productVariantInfos.getResource(productId);
    return productOrNull;
  }

  async resolveProductItemsByIds(ids: string[]): Promise<Array<ProductDetail>> {
    const productsMap = await this.productDetails.getResourcesMap(ids);
    return Array.from(productsMap.values());
  }

  async fetchVariantList(variant: { id: string }, listName: string): Promise<ProductItem[]> {
    const variantId = variant.id;
    const { fetchEnv } = this.props;
    const result = await getJSON(resources.listFromVariant(variantId, listName).url, fetchEnv);
    if (result.status === 'ok') {
      const { mapParams, mapLabels, mapLabelsTest } = this.props;
      return result.data.map((data) =>
        mapVariantToProductDetail(data, { mapParams, mapLabels, mapLabelsTest })
      );
    }
    return [];
  }

  async fetchSizeVariants(productItemId: string): Promise<ProductSizeVariant[]> {
    const productItem = await this.productDetails.getResource(productItemId);
    if (!productItem) {
      return [];
    }

    let result = [];

    const variantIds = productItem.sizes.map((_) => _.variantId);
    if (!variantIds.length) {
      return [];
    }

    let variantLabels = {};
    let variantsById = {};

    // NotePrototype(simon): cache
    const fetchUrl = resources.listProductsByIds(variantIds).url;

    const fetchResult = await getJSON(fetchUrl, this.props.fetchEnv);
    if (fetchResult.status === 'ok') {
      const { data } = fetchResult;
      const { mapParams, mapLabels, mapLabelsTest } = this.props;
      const variantsMapped = data.map((_) =>
        mapVariantToProductItem(_, { mapParams, mapLabels, mapLabelsTest })
      );

      for (const v of variantsMapped) {
        variantsById[v.id] = v;
      }
    }

    for (const sizeInfo of productItem.sizes) {
      const vId = sizeInfo.variantId;
      variantLabels[vId] = sizeInfo.label;
    }

    for (const vId of variantIds) {
      const label = variantLabels[vId];
      const variant = variantsById[vId];
      if (variant) {
        result.push({ variant, label, id: variant.id });
      }
    }
    return result;
  }

  async fetchColorVariants(productItemId: string): Promise<ProductColorVariant[]> {
    const productItem = await this.productDetails.getResource(productItemId);
    if (!productItem) {
      return [];
    }

    let result = [];

    const variantIds = productItem.colors.map((_) => _.variantId);
    if (!variantIds.length) {
      return [];
    }

    let variantLabels = {};
    let variantsById = {};

    // NotePrototype(simon): cache
    const fetchUrl = resources.listProductsByIds(variantIds).url;

    const fetchResult = await getJSON(fetchUrl, this.props.fetchEnv);
    if (fetchResult.status === 'ok') {
      const { data } = fetchResult;
      const { mapParams, mapLabels, mapLabelsTest } = this.props;
      const variantsMapped = data.map((_) =>
        mapVariantToProductItem(_, { mapParams, mapLabels, mapLabelsTest })
      );

      for (const v of variantsMapped) {
        variantsById[v.id] = v;
      }
    }

    for (const sizeInfo of productItem.colors) {
      const vId = sizeInfo.variantId;
      variantLabels[vId] = sizeInfo.label;
    }

    for (const vId of variantIds) {
      const label = variantLabels[vId];
      const variant = variantsById[vId];
      if (variant) {
        result.push({ variant, label, id: variant.id });
      }
    }
    return result;
  }
}

export type { ProductContainer as ProductContainerType };

// @flow

import * as React from 'react';

import * as baseUI from '../baseUI';
import * as appUI from '../appUI';

import ShippingImg from './images/advantages/icons/colored/Shipping.png';
import DiscountImg from './images/advantages/icons/colored/Discount.png';
import PrintImg from './images/advantages/icons/colored/Print.png';
import PercentImg from './images/advantages/icons/colored/Percent.png';
import WideRange from './images/advantages/icons/colored/WideRange.png';

import { useLingui } from '../app/lingui';
import { useGlobalContainer } from '../app/use';

import { TextLink } from '../app/link';
import { Trans } from '@lingui/react';
import { useMediaQuery } from '@material-ui/core';
import { themeMUI } from '../app/state';
import { useRouter } from '../app/router';

export const Advantages = (props: *) => {
  const i18n = useLingui();
  const globalContainer = useGlobalContainer();
  const isDEorAT =
    globalContainer.selectSiteNameId() === 'rudorfer.de' ||
    globalContainer.selectSiteNameId() === 'rudorfer.at';
  const isDEorFR =
    globalContainer.selectSiteNameId() === 'rudorfer.de' ||
    globalContainer.selectSiteNameId() === 'rudorfer.fr';
  const isNLorBE =
    globalContainer.selectSiteNameId() === 'rudorfer.nl' ||
    globalContainer.selectSiteNameId() === 'rudorfer.be';

  const isHU = globalContainer.selectSiteNameId() === 'rudorfer.hu';
  const isFR = globalContainer.selectSiteNameId() === 'rudorfer.fr';
  const isEU = globalContainer.selectSiteNameId() === 'rudorfer.eu';

  const isExtraSmall = useMediaQuery(themeMUI.breakpoints.down('xs'));

  const { location } = useRouter();
  const hiddenAdvantagesPath = globalContainer.selectStaticPageUrl('about-us');

  const renderBlock = (url, image, title, text, original = null) => {
    return (
      <baseUI.FlexColumn
        width="100%"
        maxWidth="200px"
        padding="0 25px"
        alignItems="center"
        marginTop={30}
      >
        <baseUI.Block height={70}>
          <img src={image} alt="" />
        </baseUI.Block>
        <appUI.VerticalSpace />
        <appUI.ThemedText textAlign="center" textTransform="upperCase" fontWeight="bold">
          {title}
        </appUI.ThemedText>
        <appUI.VerticalSpace />
        <appUI.ThemedText size="bigger" textAlign="center">
          {text}
        </appUI.ThemedText>
        {original ? (
          <React.Fragment>
            <appUI.VerticalSpace />
            <appUI.ThemedText size="bigger" textAlign="center">
              {original}
            </appUI.ThemedText>
          </React.Fragment>
        ) : null}
        <appUI.VerticalSpace />
        <TextLink style={{ textDecoration: 'underline' }} to={url} golden>
          <Trans>See more</Trans>
        </TextLink>
      </baseUI.FlexColumn>
    );
  };

  if (location.pathname === hiddenAdvantagesPath) return null;

  const getSiteName = (siteName) => {
    switch (siteName) {
      case 'rudorfer.eu':
        return 'rudorferpack.eu';
      case 'rudorfer.at':
        return 'rudorfer.co.at';
      case 'rudorfer.de':
        return 'rudorfer.com.de';
      case 'rudorfer.dealer':
        return 'rudorferpack.com';
      default:
        return siteName;
    }
  };

  return (
    <baseUI.FlexColumn padding="0" width="100%" color="primary" backgroundColor="#fff">
      <baseUI.FlexColumn padding="20px" alignItems="center" backgroundColor="#fff">
        <appUI.ThemedText fontSize={26} textTransform="upperCase" textAlign="center">
          <Trans>
            Advantages of shopping in online store {getSiteName(globalContainer.selectSiteNameId())}
          </Trans>
        </appUI.ThemedText>
        <baseUI.FlexRow flexWrap="wrap" width="100%" justifyContent="space-around">
          {renderBlock(
            globalContainer.selectStaticPageUrl('conditions-of-delivery'),
            ShippingImg,
            i18n.t`Free shipping`,
            <Trans>
              Order for at least{' '}
              <b>
                <appUI.FormatPriceBold value={globalContainer.selectOrderInfo().freeShippingFrom} />{' '}
                without VAT
              </b>{' '}
              and the shipping is free.
            </Trans>
          )}
          {renderBlock(
            globalContainer.selectStaticPageUrl('special-offers'),
            PercentImg,
            i18n.t`Regular discounts`,
            isDEorAT ? (
              <Trans>
                Shop cheaper. At our store you will <b>always find something in discount</b>.
                Subscribe to our newsletter or SMS list to stay up to date.
              </Trans>
            ) : (
              <Trans>Shop cheaper. At our store you will always find something in discount.</Trans>
            )
          )}
          {renderBlock(
            isEU
              ? globalContainer.selectStaticPageUrl('products')
              : globalContainer.selectStaticPageUrl('discounts-and-discount-codes'),
            isEU ? WideRange : DiscountImg,
            isDEorFR || isNLorBE
              ? i18n.t`Early payment discount`
              : isEU
              ? i18n.t`Wide range of products`
              : i18n.t`Quantity discount`,
            isDEorAT ? (
              <Trans>
                We grant a <b>3% discount</b> if you pay your invoice within 5 days of receiving the
                goods.
              </Trans>
            ) : isFR || isNLorBE ? (
              <Trans>
                We grant a <b>2% discount</b> if you pay your invoice within 14 days of receiving
                the goods.
              </Trans>
            ) : isHU ? (
              <Trans>
                <b>Save even 12% </b> from total order price.
              </Trans>
            ) : isEU ? (
              <Trans>You can find everything you need at us.</Trans>
            ) : (
              <Trans>
                <b>Save up to 10 % </b> from the total amount of your order.
              </Trans>
            )
          )}
          {renderBlock(
            globalContainer.selectStaticPageUrl('print-options'),
            PrintImg,
            i18n.t`Print`,
            <React.Fragment>
              {isDEorAT ? (
                <Trans>
                  We print goods <b>directly on-site</b>.
                </Trans>
              ) : (
                <Trans>We do the print ourselves.</Trans>
              )}
              <br />
              <Trans>Fast and cheap.</Trans> <br /> <Trans>From design to final product.</Trans>
            </React.Fragment>
          )}
        </baseUI.FlexRow>
      </baseUI.FlexColumn>
      <baseUI.Block
        backgroundColor="#fff"
        padding={10}
        paddingRight={isExtraSmall ? 10 : 50}
        marginLeft="auto"
      >
        <TextLink
          style={{ textDecoration: 'underline' }}
          to={globalContainer.selectStaticPageUrl('about-us')}
          golden
        >
          <Trans>See more advantages of our store</Trans>
        </TextLink>
      </baseUI.Block>
    </baseUI.FlexColumn>
  );
};
